import * as React from 'react'

import {
    fetchAccount,
    requiresLogin,
} from '@capture/client/components/Common/RequireLoggedIn'

import { useLocation } from 'react-router-dom'
import { HOME_SITE_ADDRESS } from '@capture/client/config/constants'
import {
    trackClientStatsInternal,
    trackPageChange,
} from '@capture/client/analytics/eventTracking'
import { CarouselAutoNavigator } from '@capture/client/components/CarouselView/CarouselAutoNavigator'
import { CastPill } from '@capture/client/components/CarouselView/CastPill'
import { ErrorBoundary } from '@capture/client/components/Common/ErrorBoundary'
import { StatusNotificationPlacement } from '@capture/client/components/Common/StatusNotificationPlacement'
import { UserActionDialogPlacement } from '@capture/client/components/Common/UserActionDialogPlacement'
import DownloadStatusPlacement from '@capture/client/components/Downloader/DownloadStatusPlacement'
import { ModalPlacement } from '@capture/client/components/ModalPlacement/ModalPlacement'
import { OutOfStoragePopupPlacement } from '@capture/client/components/OutOfStoragePopup/OutOfStoragePopupPlacement'
import { UploadStatusPlacement } from '@capture/client/components/Uploader'
import { RoutePath } from '@capture/client/routing/routePath'
import { TooltipProvider } from '@capture/capture-components'
import { QAPlacement } from '../components/Common/QAPlacement/QAPlacement'

// doing the router's work manually?
const HideInRoute: React.FunctionComponent<{
    forbiddenRoutes: RegExp[]
    children?: React.ReactNode
}> = (props) => {
    if (
        props.forbiddenRoutes.some(
            (route) => window.location.pathname.match(route) !== null,
        )
    ) {
        return null
    } else {
        return <>{props.children}</>
    }
}

const App: React.FunctionComponent<{ children?: React.ReactNode }> = (
    props,
) => {
    const location = useLocation()
    const timeOfLastForegroundEvent = 0
    const coolDownPeriod = 60 * 60 // 1 hour

    React.useEffect(() => {
        trackPageChange(location.pathname, location.hash)
        trackClientStatsInternal(timeOfLastForegroundEvent, coolDownPeriod)
    }, [location])
    return (
        <ErrorBoundary>
            <TooltipProvider>
                <div style={{ height: '100%' }}>
                    <QAPlacement />
                    <ModalPlacement />
                    {props.children}
                    <UploadStatusPlacement />
                    <DownloadStatusPlacement />
                    <StatusNotificationPlacement />
                    <OutOfStoragePopupPlacement />
                    <UserActionDialogPlacement />
                    <HideInRoute
                        forbiddenRoutes={[
                            new RegExp(
                                RoutePath.getAlbumCarouselPath('([^/]+)', '.*'),
                            ),
                            new RegExp(
                                RoutePath.getTimelineCarouselPath('([^/]+)'),
                            ),
                        ]}>
                        <CastPill />
                    </HideInRoute>
                    <CarouselAutoNavigator />
                </div>
            </TooltipProvider>
        </ErrorBoundary>
    )
}

export const LoggedInApp = requiresLogin({
    loginFailedURLProvider: () => HOME_SITE_ADDRESS,
})(App)

// TODO: CAPWEB-3196 Unify a way to know if a user is from inside app
export const LoggedInFromApp = requiresLogin(
    {
        loginFailedURLProvider: () => HOME_SITE_ADDRESS,
    },
    true,
)(App)

export const MaybeLoggedInApp = fetchAccount(App)
