import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getElementSize } from '~/utilities/gridElementSizeCalculator'
import { withoutTheUndefined } from '~/utilities/arrayUtils'
import type { BasicViewFile } from '~/state/files/selectors'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { setCurrentVisibleRanges } from '~/API/syncers/TimelineChunkSyncer'
import { initTimeline } from '~/API/job'
import type { ExtendedJobFile } from '~/state/files/reducer'
import {
    FilesWereDeselected,
    FilesWereSelected,
    SelectionFromTimelineCanceled,
} from '~/state/selectedFiles/actions'
import { MaxSelectionCountReached } from '~/state/timeline/actions'
import {
    getSelectedTimelineFiles,
    getTimelineGroupsWithSelectionStatusWithoutFilter,
    getTimelineScrollerGroupsWithoutFilter,
    haveTimelineMonths,
    isTimelineInSelectMode,
} from '~/state/timeline/selectors'
import {
    getImageGroupStyle,
    getViewportWidth,
    isMobileMode,
} from '~/state/viewMode/selectors'
import { PageWrapper } from '../Common/PageWrapper'
import { PhotoSelectionNavbar } from '../PhotoSelection/PhotoSelectionNavbar'
import type {
    ScrollerGroup,
    ScrollerGroupItem,
} from '../TimelineScroller/FastScrollerContent'
import { TimelineScrollerPlacement } from '../TimelineScroller/TimelineScrollerPlacement'
import { ImageGroupList } from './ImageGroupList'
import { MonthGroupTitle } from './MonthGroupTitle'
import { SelectFromTimelineEmptyState } from './SelectFromEmptyTimeline'

const PhotosWrapper = styled.div`
    display: flex;
    margin-top: 24px;
`

type Props = {
    nextButtonLabel?: string
    headerTitle?: string
    doHandleFiles: (files: ExtendedJobFile[]) => void
    doHandleLocalUpload?: (files: File[]) => void
    selectionMax?: number
    showBtnTextAlways?: boolean
}

export const makeTimelineGroupHeader = (header: string) => (
    <MonthGroupTitle rawHeader={header} />
)

export const SelectFromTimelinePage = (props: Props) => {
    const [isFastScrolling, setIsFastScrolling] = useState(false)
    const [monthsToSelect, setMonthsToSelect] = useState<string[]>([])

    const selectedFiles = useSelector(getSelectedTimelineFiles)
    const isMobile = useSelector(isMobileMode)
    const imagesGroupedSelector = useSelector(
        getTimelineGroupsWithSelectionStatusWithoutFilter,
    )
    // This is in order to prevent selector rendering infinitely when monthsToSelect is updated
    const [imagesGrouped, setImagesGrouped] = useState(imagesGroupedSelector)
    useEffect(() => {
        setImagesGrouped(imagesGroupedSelector)
    }, [imagesGroupedSelector])
    const scrollerGroups = useSelector(getTimelineScrollerGroupsWithoutFilter)
    const imageGroupStyle = useSelector(getImageGroupStyle)
    const viewportWidth = useSelector(getViewportWidth)
    const isInSelectMode = useSelector(isTimelineInSelectMode)
    const isTimelineReady = useSelector(haveTimelineMonths)

    const dispatch = useDispatch()
    const doDeselectFiles = useCallback(
        (files: FileID[]) => dispatch(FilesWereDeselected(files)),
        [dispatch],
    )
    const doSelectFiles = useCallback(
        (files: FileID[]) => dispatch(FilesWereSelected(files)),
        [dispatch],
    )
    const fetchTimeline = () => initTimeline(dispatch)
    const onMaxSelectionCountReached = () =>
        dispatch(MaxSelectionCountReached())

    const handleClickNext = async () => {
        if (selectedFiles.length > (props.selectionMax || Infinity)) {
            onMaxSelectionCountReached()
        } else if (selectedFiles.length > 0) {
            props.doHandleFiles(selectedFiles)
        }
    }

    const handleClickBack = () => dispatch(SelectionFromTimelineCanceled())

    const handleScrollerYearSelected = ({
        groupKey,
        groupSelectionStatus,
    }: ScrollerGroup) => {
        const images = imagesGrouped
            .filter((val) => val.groupKey.split('-')[0] === groupKey)
            .reduce(
                (pre, cur) => pre.concat(cur.images),
                Array<BasicViewFile | undefined>(),
            )
        if (images) {
            // (check for undefined images, and load months if there are)
            const fileIDs = withoutTheUndefined(images).map((f) => f.fileID)
            if (fileIDs.length < images.length) {
                const year = parseInt(groupKey, 10)
                const monthsInYear = Array.from({ length: 12 }, (_, i) => i + 1)
                const itemKeysInYear = monthsInYear.map(
                    (month) => `${groupKey}-${String(month).padStart(2, '0')}`,
                )

                setCurrentVisibleRanges(
                    monthsInYear.map((i) => {
                        return {
                            month: i,
                            year,
                            startDay: 1,
                            endDay: 31,
                        }
                    }),
                )
                setMonthsToSelect([...monthsToSelect, ...itemKeysInYear])
            } else {
                // files loaded, select month's files
                groupSelectionStatus === 'all-selected'
                    ? doDeselectFiles(fileIDs)
                    : doSelectFiles(fileIDs)
            }

            trackEvent(
                'TimelinePage',
                groupSelectionStatus === 'all-selected'
                    ? 'ScrollerYearDeselected'
                    : 'ScrollerYearSelected',
            )
            trackEventInternal(
                `timeline_fastscroller_${
                    groupSelectionStatus === 'all-selected'
                        ? 'year_deselected'
                        : 'year_selected'
                }`,
            )
        }
    }

    const handleScrollerMonthSelected = ({
        itemKey,
        groupSelectionStatus,
    }: ScrollerGroupItem) => {
        const group = imagesGrouped.find((g) => g.groupKey === itemKey)
        if (group) {
            // (check for undefined images, and load month if there are)
            const fileIDs = withoutTheUndefined(group.images).map(
                (f) => f.fileID,
            )
            if (fileIDs.length < group.images.length) {
                // some files not loaded, load month
                const [year, month] = itemKey
                    .split('-')
                    .map((dateString) => parseInt(dateString, 10))

                setCurrentVisibleRanges([
                    {
                        month,
                        year,
                        startDay: 1,
                        endDay: 31,
                    },
                ])
                setMonthsToSelect([...monthsToSelect, itemKey])
            } else {
                // files loaded, select month's files
                groupSelectionStatus === 'all-selected'
                    ? doDeselectFiles(fileIDs)
                    : doSelectFiles(fileIDs)
            }

            trackEvent(
                'TimelinePage',
                groupSelectionStatus === 'all-selected'
                    ? 'ScrollerMonthDeselected'
                    : 'ScrollerMonthSelected',
            )
            trackEventInternal(
                `timeline_fastscroller_${
                    groupSelectionStatus === 'all-selected'
                        ? 'month_deselected'
                        : 'month_selected'
                }`,
            )
        }
    }

    const handleScroll = (_e: Event) => {
        const elementHeight = getElementSize(imageGroupStyle).height // single row height
        const rowCountThreshold = 7.5 // num of rows to trigger
        const startScrollPos =
            document.body.scrollTop || document.documentElement.scrollTop

        setTimeout(() => {
            const nextScrollPos =
                document.body.scrollTop || document.documentElement.scrollTop
            const diff = Math.abs(startScrollPos - nextScrollPos)
            const triggerThreshold = elementHeight * rowCountThreshold
            if (diff > triggerThreshold) {
                // scrolled through more than 'rowCountThreshold' rows
                setIsFastScrolling(true)
            } else {
                setIsFastScrolling(false)
            }
        }, 250) // within 0.25 seconds (=> 30 rows/s)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (!isTimelineReady) {
            fetchTimeline()
        }
    }, [])

    useEffect(() => {
        if (monthsToSelect.length > 0 && isInSelectMode) {
            let monthsLeft = monthsToSelect
            monthsToSelect.forEach((monthKey) => {
                const group = imagesGrouped.find((g) => g.groupKey === monthKey)
                if (group) {
                    const definedFiles = withoutTheUndefined(group.images)

                    if (definedFiles.length === group.images.length) {
                        monthsLeft = monthsLeft.filter(
                            (monthLeft) => monthLeft !== monthKey,
                        ) // month is loaded, remove from todo
                        doSelectFiles(definedFiles.map((i) => i.fileID))
                    }
                }
            })
            setMonthsToSelect(monthsLeft)
        }
    }, [doSelectFiles, isInSelectMode, monthsToSelect, imagesGrouped])

    const onSelectBtnClicked = (item: ScrollerGroupItem | ScrollerGroup) => {
        return 'itemKey' in item
            ? handleScrollerMonthSelected(item)
            : handleScrollerYearSelected(item)
    }

    const navBar = (
        <PhotoSelectionNavbar
            nextButtonLabel={props.nextButtonLabel}
            headerTitle={props.headerTitle}
            onNextBtnClick={handleClickNext}
            onBackBtnClick={handleClickBack}
            selectedCount={selectedFiles.length}
            selectionMax={props.selectionMax}
            isMobileMode={isMobile}
            showBtnTextAlways={props.showBtnTextAlways}
        />
    )

    // implementation of TimelineScrollerPlacement is a copy
    // of the one in <TimelinePage>
    if (isTimelineReady && imagesGrouped.length === 0) {
        return (
            <PageWrapper navBar={navBar}>
                <PhotosWrapper>
                    <SelectFromTimelineEmptyState
                        fileHandler={props.doHandleLocalUpload}
                    />
                </PhotosWrapper>
            </PageWrapper>
        )
    }

    return (
        <PageWrapper navBar={navBar}>
            <PhotosWrapper>
                <ImageGroupList
                    isInSelectMode={true}
                    imagesGrouped={imagesGrouped}
                    groupStyle={imageGroupStyle}
                    transformHeader={makeTimelineGroupHeader}
                />
                <TimelineScrollerPlacement
                    scrollerGroups={scrollerGroups}
                    imageGroupStyle={imageGroupStyle}
                    isMobileMode={isMobile}
                    viewportWidth={viewportWidth}
                    isInSelectMode={isInSelectMode}
                    onSelectBtnClicked={onSelectBtnClicked}
                    isFastScrolling={isFastScrolling}
                    showFastScrollHint={
                        // full month or more than 10 images selected
                        imagesGrouped.some(
                            (group) => group.selectionStatus === 'all-selected',
                        ) || selectedFiles.length >= 10
                    }
                />
            </PhotosWrapper>
        </PageWrapper>
    )
}
