import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface RestoreAllCompletedPromptProps {
    handleConfirm: () => void
}

export const RestoreAllCompletedPrompt = ({
    handleConfirm,
}: RestoreAllCompletedPromptProps) => {
    return (
        <Prompt.Content
            data-cy="restore_all_completed_prompt"
            title={_('photos_trash__restore_all_completed_title')}>
            <Typography.Paragraph>
                {_('photos_trash__restore_all_completed_body')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton
                    data-cy="restore_all_completed_prompt__confirmBtn"
                    variant="primary"
                    onPress={handleConfirm}>
                    {_('got_it')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
