import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import type { StateWithCurrentUser } from '~/state/currentUser/reducer'
import { getUnusedStorage } from '~/state/currentUser/selectors'
import type { StateWithViewMode } from '../viewMode/reducer'
import { getViewportHeight } from '../viewMode/selectors'
import { getTimelineJobID } from '../timeline/selectors'
import type { FileInformation, StateWithUploader } from './reducer'
import { FileUploadStatus, UploaderOnlineStatus } from './reducer'

// Selectors relative to FileInformation (not global state)
const isPicture = (file: FileInformation) =>
    getFileTargetFromName(file.name) === FileTarget.Pictures
/* const isDocument = (file: FileInformation) => getFileTargetFromName(file.name) === FileTarget.Documents; */
/* const isMovie = (file: FileInformation) => getFileTargetFromName(file.name) === FileTarget.Movies; */

export const isUploadStatusPending = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Pending
export const isUploadStatusEnqueued = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Enqueued
export const isUploadStatusSucceeded = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Succeeded
export const isUploadStatusRejected = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Rejected
export const isUploadStatusCancelled = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Cancelled
export const isUploadStatusUploading = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.Uploading
export const isUploadStatusBackendSucceeded = (fileInfo: FileInformation) =>
    fileInfo.status === FileUploadStatus.BackendSucceeded

// Global state-selectors
export const isStatusBoxExpanded = (state: StateWithUploader) =>
    state.uploader.isStatusBoxExpanded
export const isStatusBoxVisible = (state: StateWithUploader) =>
    state.uploader.isStatusBoxVisible

const selectFilesById = (state: StateWithUploader) => state.uploader.filesByID
const selectCurrentlyUploadingFiles = (state: StateWithUploader) =>
    state.uploader.currentlyUploadingFiles

export const getFileInformationByUploaderId = (
    state: StateWithUploader,
    id: number,
): FileInformation | undefined => state.uploader.filesByID[id]

export const getBackendSucceededFileIDByFileUUID = (
    state: StateWithUploader,
    uuid: string,
): number | undefined =>
    Object.values(state.uploader.filesByID).find(
        (file) =>
            file.fileUUID === uuid &&
            file.status === FileUploadStatus.BackendSucceeded,
    )?.id

export const getAllFiles = createSelector(
    selectFilesById,
    (filesByID): FileInformation[] =>
        Object.keys(filesByID).map((id) => filesByID[Number.parseInt(id)]),
)
export const getAllCurrentFiles = createSelector(
    selectFilesById,
    selectCurrentlyUploadingFiles,
    (
        allFiles: { [index: number]: FileInformation },
        currentFileIDs: number[],
    ): FileInformation[] => currentFileIDs.map((id) => allFiles[id]),
)

// Factory-method for building filter-selectors
const allCurrentFilesFiltered = (
    filters: (file: FileInformation) => boolean,
): Selector<StateWithUploader, FileInformation[]> => {
    return createSelector(getAllCurrentFiles, (files) => files.filter(filters))
}

export const getPendingFiles = allCurrentFilesFiltered(isUploadStatusPending)
export const getEnquedFiles = allCurrentFilesFiltered(isUploadStatusEnqueued)
export const getUploadingFiles = allCurrentFilesFiltered(
    isUploadStatusUploading,
)
export const getCancelledFiles = allCurrentFilesFiltered(
    isUploadStatusCancelled,
)
export const getSucceededFiles = allCurrentFilesFiltered(
    isUploadStatusSucceeded,
)
export const getRejectedFiles = allCurrentFilesFiltered(isUploadStatusRejected)

const getPendingQuotaFiles = allCurrentFilesFiltered(
    (file) =>
        file.status === FileUploadStatus.Uploading ||
        file.status === FileUploadStatus.Pending,
)
export const getTotalPendingQuotaFileSize = createSelector(
    getPendingQuotaFiles,
    (pendingFiles): number => {
        return pendingFiles.reduce((sum, file) => sum + file.size, 0)
    },
)

export const getNotUploadedFiles = (state: StateWithUploader) =>
    getCancelledFiles(state).concat(getRejectedFiles(state))

export const getCurrentlyUploadingFile = (state: StateWithUploader) =>
    getUploadingFiles(state)[0]

export const getCurrentOrNextUploadingFile = createSelector(
    getCurrentlyUploadingFile,
    getEnquedFiles,
    (uploadingFile, enqFiles): FileInformation | undefined => {
        return uploadingFile || enqFiles[0]
    },
)

export const getFinishedThumbs = createSelector(
    getSucceededFiles,
    (succFiles): FileInformation[] => {
        const photos = succFiles.filter(isPicture)
        return photos.length > 0 ? photos.slice(0, 4) : succFiles.slice(0, 1)
    },
)

const isOnlineStatus = (
    state: StateWithUploader,
    status: UploaderOnlineStatus,
) => state.uploader.onlineStatus === status
export const isOnline = (state: StateWithUploader) =>
    isOnlineStatus(state, UploaderOnlineStatus.Online)
export const isOffline = (state: StateWithUploader) =>
    isOnlineStatus(state, UploaderOnlineStatus.Offline)
export const isRetrying = (state: StateWithUploader) =>
    isOnlineStatus(state, UploaderOnlineStatus.Retrying)

export const isFiltered = (state: StateWithUploader) =>
    state.uploader.isFiltered
export const isPaused = (state: StateWithUploader) => state.uploader.isPaused
export const isStopPrompted = (state: StateWithUploader) =>
    state.uploader.isPendingStop

export const getCurrentlyDoneRatio = (state: StateWithUploader) => {
    const current = getCurrentlyUploadingFile(state)
    const all = getAllCurrentFiles(state).length
    const waiting =
        getPendingFiles(state).length +
        getEnquedFiles(state).length +
        (current ? 1 - current.uploadedPercent : 0)
    return all === 0 ? 1 : 1 - waiting / all
}
export const isUploaderDone = (state: StateWithUploader) =>
    getCurrentlyDoneRatio(state) === 1

export const isCurrentlyUploading = (state: StateWithUploader) =>
    getUploadingFiles(state).length > 0

export const isOutOfStorage = (state: StateWithUploader) =>
    state.uploader.isOutOfStorage

export const isFileCancelable = (file: FileInformation) =>
    file.status === FileUploadStatus.Enqueued ||
    file.status === FileUploadStatus.Uploading

export const getVisibleFiles = createSelector(
    isFiltered,
    getAllCurrentFiles,
    getNotUploadedFiles,
    (isFiltered, allFiles, filesNotUploaded): FileInformation[] => {
        if (isFiltered) {
            return filesNotUploaded
        }
        return allFiles
    },
)
export const getUploadStatusHeight = createSelector(
    isStatusBoxExpanded,
    getViewportHeight,
    getVisibleFiles,
    (isExpanded, viewHeight, visibleFiles): number => {
        const cardHeight = 136

        if (isExpanded) {
            const entryHeight = 48
            const bottomListElm = 64
            const topListElm = 54
            const listPadding = 8

            return Math.min(
                viewHeight * 0.75,
                visibleFiles.length * entryHeight +
                    listPadding +
                    topListElm +
                    bottomListElm,
            )
        }
        return cardHeight
    },
)

const spaceBelowUploader = 8
export const getUploadStatusTopPosition = (
    state: StateWithUploader & StateWithViewMode,
) => getUploadStatusHeight(state) + spaceBelowUploader

export const getAvailableStorage = (
    state: StateWithCurrentUser & StateWithUploader,
) => getUnusedStorage(state) - getTotalPendingQuotaFileSize(state)

export const isUploadedOnlyToTimeline = createSelector(
    getTimelineJobID,
    getAllCurrentFiles,
    (timelineID, allCurrentFiles) => {
        return allCurrentFiles.every((f) => f.targetJob === timelineID)
    },
)

export const getNextPossibleEnqueuedFile = createSelector(
    getEnquedFiles,
    isOutOfStorage,
    getTimelineJobID,
    (files, isOutOfStorage, timelineID) => {
        const nextFile = files.find(
            (f) => !isOutOfStorage || f.targetJob !== timelineID,
        )
        return nextFile
    },
)

export const getIsUploadQueueFrozen = createSelector(
    getEnquedFiles,
    getNextPossibleEnqueuedFile,
    isOffline,
    (enqueuedFiles, nextFile, isOffline) => {
        return isOffline || (enqueuedFiles.length > 0 && !nextFile)
    },
)
