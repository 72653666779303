/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKAccountDeletionRequestResult,
    CAPBAKConsentValuesGetParams,
    CAPBAKConsentValuesPostParams,
    CAPBAKConsentValuesPostPayload,
    CAPBAKConsentsResponse,
    CAPBAKDataProtectionRequestResult,
    CAPBAKDataProtectionUpdateRequestResult,
    CAPBAKHTTPValidationError,
    CAPBAKRequestAccountDeletionPostParams,
    CAPBAKRequestDataAccessAvailabilityGetParams,
    CAPBAKRequestDataAccessGetParams,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class DataProtection<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Update consent values stored in the local consent system
     *
     * @tags data_protection
     * @name ConsentValuesPost
     * @summary Update Data Protection Consents
     * @request POST:/data_protection/consent_values
     */
    consentValuesPost = (
        query: CAPBAKConsentValuesPostParams,
        data: CAPBAKConsentValuesPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKDataProtectionUpdateRequestResult,
            CAPBAKHTTPValidationError
        >({
            path: `/data_protection/consent_values`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description Consent values stored in the local consent system
     *
     * @tags data_protection
     * @name ConsentValuesGet
     * @summary Get Data Protection Consents
     * @request GET:/data_protection/consent_values
     */
    consentValuesGet = (
        query: CAPBAKConsentValuesGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKConsentsResponse, CAPBAKHTTPValidationError>({
            path: `/data_protection/consent_values`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description An endpoint returning all the metadata stored in connection with the user
     *
     * @tags data_protection
     * @name RequestDataAccessGet
     * @summary Request Data Access
     * @request GET:/data_protection/request_data_access
     */
    requestDataAccessGet = (
        query: CAPBAKRequestDataAccessGetParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/data_protection/request_data_access`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This is an endpoint to check if we are able to ask for a data request
     *
     * @tags data_protection
     * @name RequestDataAccessAvailabilityGet
     * @summary Allow New Data Access Request
     * @request GET:/data_protection/request_data_access_availability
     */
    requestDataAccessAvailabilityGet = (
        query: CAPBAKRequestDataAccessAvailabilityGetParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKDataProtectionRequestResult,
            CAPBAKHTTPValidationError | void
        >({
            path: `/data_protection/request_data_access_availability`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Request account deletion. The user will be locked out of Capture and all auths will be disabled. The actual user account deletion will be an async process.
     *
     * @tags data_protection
     * @name RequestAccountDeletionPost
     * @summary Request Account Deletion
     * @request POST:/data_protection/request_account_deletion
     */
    requestAccountDeletionPost = (
        query: CAPBAKRequestAccountDeletionPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKAccountDeletionRequestResult,
            CAPBAKHTTPValidationError | void
        >({
            path: `/data_protection/request_account_deletion`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
