import { useEffect, useMemo, useState } from 'react'
import type { CAPBAKTrashcanAlbumItem } from '@capture/client-api/src/schemas/data-contracts'
import { getAuthToken, getStoredServiceDict } from '~/API/externals'
import { getServiceProvider } from '~/API/HostProvider'
import { ThumbService } from '~/API/services/ThumbService'
import { TRASH_LIFE_EXPECTANCY } from '~/state/trash/selectors'
import { getTimeSince } from '~/utilities/dateOperations'
import { getStringByAmount, _ } from '~/assets/localization/util'

type DeletedAlbum = {
    id: string
    coverUrl?: URLstring
    name: string
    remainingDays: string
    subtitle: string
}

export const useDeletedAlbums = () => {
    const [deletedAlbums, setDeletedAlbums] = useState<
        DeletedAlbum[] | undefined
    >(undefined)
    const [error, setError] = useState<Error | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const transform = (album: CAPBAKTrashcanAlbumItem): DeletedAlbum => {
        const subtitle = getStringByAmount(
            album.media_count,
            `${album.media_count} ${_('file')}`,
            `${album.media_count} ${_('files')}`,
        )

        const daysLeft =
            TRASH_LIFE_EXPECTANCY -
            getTimeSince(
                new Date(album.dtime * 1000),
                new Date(Date.now()),
                'day',
            )

        const remainingDays = getStringByAmount(
            daysLeft,
            _('one_day'),
            _('num_days__format').replace('%d', String(daysLeft)),
        )

        return {
            id: album.id,
            coverUrl: album.cover_id && getThumbUrl(album.id, album.cover_id),
            name: album.name,
            remainingDays,
            subtitle,
        }
    }

    const getThumbUrl = (albumId: string, coverId: string): URLstring => {
        // TODO: CAPWEB-3123 remove workaround
        // hosts always exists behind a logged in page
        const hosts = getStoredServiceDict()!
        const t = new ThumbService(hosts.thumbHost, getAuthToken())
        return t.getTrashedThumbUrl(albumId, coverId, {
            area: 512,
        })
    }

    // Fetch deleted albums from backend
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            setError(undefined)
            setDeletedAlbums(undefined)
            try {
                const service =
                    getServiceProvider().getAppServiceForLoggedInUserDefaults()
                const data = await service.getDeletedAlbums()
                if (shouldUpdate) {
                    // we remove albums with no media to avoid error from backend
                    const transformedData = data
                        .filter((album) => album.media_count > 0)
                        .map(transform)
                    setDeletedAlbums(transformedData)
                }
            } catch (error) {
                if (shouldUpdate) {
                    setDeletedAlbums(undefined)
                    setError(error as Error)
                    console.error(error)
                }
            } finally {
                setIsLoading(false)
            }
        }

        let shouldUpdate = true
        fetchData()
        return () => {
            shouldUpdate = false
        }
    }, [])

    const deleteAlbums = async (albumIds: JobID[]) => {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.emptyTrashCanAlbums(albumIds)
        setDeletedAlbums(
            deletedAlbums!.filter(
                (album) =>
                    !response.success
                        .map((message) => message.uuid)
                        .includes(album.id),
            ),
        )
    }

    const restoreAlbums = async (albumIds: JobID[]) => {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.restoreAlbums(albumIds)
        setDeletedAlbums(
            deletedAlbums!.filter(
                (album) =>
                    !response.success
                        .map((message) => message.uuid)
                        .includes(album.id),
            ),
        )
    }

    const deletedAlbumIds = useMemo(
        () => deletedAlbums?.map((album) => album.id),
        [deletedAlbums],
    )

    return {
        deletedAlbums,
        deletedAlbumIds,
        error,
        isLoading,
        deleteAlbums,
        restoreAlbums,
    }
}
