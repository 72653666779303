import type { Dispatch } from '@reduxjs/toolkit'

import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import type { FileOptionContext } from '~/components/FilesOptions/FilesOptionsPlacement'
import { PRODUCT_NAME, SIZE_DOWNLOAD_LIMIT } from '~/config/constants'
import { BulkOfActions } from '~/state/common/actions'
import { StartPaginatedDownloadFlow } from '~/state/downloader/actions'
import { FilesOptionsTriggered } from '~/state/FilesOptions/actions'
import {
    FilesDownloadFailed,
    FilesDownloadSuccess,
    JobDownloadFailed,
    JobDownloadSuccess,
    type FilesDownloadType,
    type JobDownloadType,
} from '~/state/job/actions'
import {
    LongRunningTaskFinished,
    LongRunningTaskStarted,
} from '~/state/statusNotifications/actions'
import { paginateFiles, type FileInfoForPagination } from '~/utilities/download'
import { getTotalSize, type FileInfoForSize } from '~/utilities/fileSize'
import { isHEICFile } from '~/utilities/fileTarget'
import { fetchRemainingDeletedFiles } from '~/API/job'
import { type DeletedFile } from '~/@types/backend-types'
import { getServiceProvider } from './HostProvider'

const getTotalSizeFromFileInfos = (fileInfos: FileInfoForSize[]): number =>
    getTotalSize(fileInfos)

const getJobDownloadPaginationInfo = async (
    jobID: JobID,
): Promise<{ size: number; fileInfos: FileInfoForPagination[] }> => {
    const service = await getServiceProvider().getAppServiceForJob(jobID)
    // TODO(CAPWEB-3109): Add caching for file info later.
    const { files } = await service.getFiles(jobID, {
        recursive: true,
    })

    return {
        size: getTotalSizeFromFileInfos(files),
        fileInfos: files.map((f) => ({
            fileID: f.id,
            size: f.size,
            ctime: f.ctime ?? undefined,
            mtime: f.mtime,
            path: f.path,
        })),
    }
}

type DownloadParams<
    T extends FilesDownloadType | JobDownloadType,
    ExtraP = object,
> = {
    type: T
    jobID: JobID
    zipFileName?: string
} & ExtraP

type DownloadFilesParams =
    | DownloadParams<'download', { fileIDs: FileID[]; hasHEIC: boolean }>
    | DownloadParams<
          Exclude<FilesDownloadType, 'download' | 'download_deleted'>,
          { fileIDs: FileID[] }
      >
export const downloadFiles = async (
    dispatch: Dispatch,
    params: DownloadFilesParams,
) => {
    const { type, jobID, fileIDs, zipFileName } = params
    const hasHEIC = type === 'download' ? params.hasHEIC : undefined

    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.downloadFilesFromJob(
            type,
            jobID,
            fileIDs,
            hasHEIC,
            zipFileName,
        )
        dispatch(
            FilesDownloadSuccess({
                type,
                jobID,
                files: fileIDs,
            }),
        )
    } catch (e) {
        dispatch(
            FilesDownloadFailed({
                type,
                jobID,
                files: fileIDs,
            }),
        )
    }
}

type DownloadJobParams =
    | DownloadParams<'download', { hasHEIC: boolean }>
    | DownloadParams<Exclude<JobDownloadType, 'download'>>
export const downloadJob = async (
    dispatch: Dispatch,
    params: DownloadJobParams,
) => {
    const { type, jobID, zipFileName } = params
    const hasHEIC = type === 'download' ? params.hasHEIC : undefined

    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.downloadJobAsArchive(type, jobID, hasHEIC, zipFileName)
        dispatch(JobDownloadSuccess({ type, jobID }))
    } catch (e) {
        dispatch(JobDownloadFailed({ type, jobID }))
    }
}

type DownloadDeletedFilesParams = {
    jobID: JobID
    fileIDs: FileID[]
    zipFileName: string
}
export const downloadDeletedFiles = async (
    dispatch: Dispatch,
    { jobID, fileIDs, zipFileName }: DownloadDeletedFilesParams,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.downloadFilesFromTrashAsArchive(
            jobID,
            fileIDs,
            zipFileName,
        )
        dispatch(
            FilesDownloadSuccess({
                type: 'download_deleted',
                jobID,
                files: fileIDs,
            }),
        )
    } catch (e) {
        dispatch(
            FilesDownloadFailed({
                type: 'download_deleted',
                jobID,
                files: fileIDs,
            }),
        )
    }
}

export const downloadAllTrashFiles = async (
    dispatch: Dispatch,
    options: {
        timelineID: JobID
        limit: number
        totalItemCount: number
        fetchedTrashFileInfos: FileInfoForPagination[]
        zipFileName: string
    },
) => {
    const {
        timelineID,
        totalItemCount,
        fetchedTrashFileInfos,
        limit,
        zipFileName,
    } = options
    const remainingFiles = await fetchRemainingDeletedFiles(
        dispatch,
        fetchedTrashFileInfos.length,
        totalItemCount,
        limit,
    )
    const deletedFileToFileInfo = ({
        id,
        size,
        path,
        mtime,
    }: DeletedFile): FileInfoForPagination => ({
        fileID: id,
        size,
        path,
        mtime,
    })
    const allTrashFileInfos = fetchedTrashFileInfos.concat(
        remainingFiles.map(deletedFileToFileInfo),
    )

    await paginatedDownloadDeletedAllFiles(dispatch, {
        jobID: timelineID,
        fileInfos: allTrashFileInfos,
        zipFileName: zipFileName,
    })
}

export const downloadDeletedAlbum = async (
    dispatch: Dispatch,
    jobID: JobID,
    zipFileName: string,
) => {
    // empty array indicates to backend we want to download all files in the job
    downloadDeletedFiles(dispatch, { jobID, fileIDs: [], zipFileName })
}

type DownloadSingleFileParams = DownloadParams<
    'download' | 'export',
    {
        fileID: FileID
        path: string
        context: string
    }
>
export const downloadSingleFile = async (
    dispatch: Dispatch,
    { jobID, fileID, path, type, context }: DownloadSingleFileParams,
) => {
    try {
        trackEventInternal('single_file_download_started', { type })
        await downloadFiles(dispatch, {
            jobID,
            fileIDs: [fileID],
            type,
            hasHEIC: isHEICFile(path),
        })
        trackEventInternal(
            `${context.toLowerCase()}_single_file_download_finished`,
            {
                type,
            },
        )
    } catch (error) {
        trackEventInternal(`${context.toLowerCase()}_file_download_error`, {
            type,
        })
    }
}

type MaybePaginatedDownloadFilesParams = DownloadParams<
    Exclude<FilesDownloadType, 'download_deleted'>,
    {
        fileInfos: FileInfoForPagination[]
        context?: FileOptionContext
    }
>
export const maybePaginatedDownloadFiles = async (
    dispatch: Dispatch,
    {
        type,
        jobID,
        fileInfos,
        context = 'Unknown',
        zipFileName = PRODUCT_NAME,
    }: MaybePaginatedDownloadFilesParams,
) => {
    if (fileInfos.length === 0) {
        return
    }

    dispatch(LongRunningTaskStarted('preparingDownload'))

    const size = getTotalSizeFromFileInfos(fileInfos)

    // Track an analytics event indicating that a file download has been initiated.
    trackEventInternal(
        `${context.toLowerCase()}_download_files_flow_initiated`,
        {
            origin: context,
            count: fileInfos.length,
            sizeTotal: size,
            type,
        },
    )

    if (size > SIZE_DOWNLOAD_LIMIT) {
        dispatch(
            BulkOfActions([
                LongRunningTaskFinished('preparingDownload'),
                FilesOptionsTriggered({
                    type: 'download_huge_selection',
                    downloadType: type,
                    jobID,
                    fileInfos,
                    size,
                    context,
                    zipFileName,
                }),
            ]),
        )
        return
    }

    dispatch(LongRunningTaskFinished('preparingDownload'))

    await downloadFiles(dispatch, {
        type,
        jobID,
        fileIDs: fileInfos.map(({ fileID }) => fileID),
        hasHEIC: fileInfos.some(({ path }) => isHEICFile(path)),
    })

    // If a context is specified, track an analytics event indicating that the download has completed.
    context && trackEvent(context, 'MultiFileDownload')
    // Track an analytics event indicating that a file download has started.
    trackEventInternal(
        `${context.toLowerCase()}_download_files_started_directly`,
        {
            count: fileInfos.length,
            sizeTotal: size,
            type,
        },
    )
}

type MaybePaginatedDownloadDeletedFilesParams = {
    jobID: JobID
    fileInfos: FileInfoForPagination[]
    zipFileName: string
    context?: FileOptionContext
}
export const maybePaginatedDownloadDeletedFiles = async (
    dispatch: Dispatch,
    {
        jobID,
        fileInfos,
        zipFileName,
        context = 'Unknown',
    }: MaybePaginatedDownloadDeletedFilesParams,
) => {
    if (fileInfos.length === 0) {
        return
    }

    dispatch(LongRunningTaskStarted('preparingDownload'))

    const size = getTotalSizeFromFileInfos(fileInfos)

    if (size > SIZE_DOWNLOAD_LIMIT) {
        dispatch(
            BulkOfActions([
                LongRunningTaskFinished('preparingDownload'),
                FilesOptionsTriggered({
                    type: 'download_huge_selection',
                    downloadType: 'download_deleted',
                    jobID,
                    fileInfos,
                    size,
                    context,
                    zipFileName,
                }),
            ]),
        )
        return
    }

    dispatch(LongRunningTaskFinished('preparingDownload'))
    await downloadDeletedFiles(dispatch, {
        jobID,
        fileIDs: fileInfos.map(({ fileID }) => fileID),
        zipFileName,
    })
}

type paginatedDownloadDeletedAllFilesParams = {
    jobID: JobID
    fileInfos: FileInfoForPagination[]
    zipFileName: string
}
export const paginatedDownloadDeletedAllFiles = async (
    dispatch: Dispatch,
    { jobID, fileInfos, zipFileName }: paginatedDownloadDeletedAllFilesParams,
) => {
    if (fileInfos.length === 0) {
        return
    }

    dispatch(LongRunningTaskStarted('preparingDownload'))

    const size = getTotalSizeFromFileInfos(fileInfos)

    if (size > SIZE_DOWNLOAD_LIMIT) {
        const pages = paginateFiles(fileInfos, SIZE_DOWNLOAD_LIMIT, zipFileName)

        dispatch(
            BulkOfActions([
                LongRunningTaskFinished('preparingDownload'),
                StartPaginatedDownloadFlow({
                    downloadType: 'download_deleted',
                    jobID,
                    count: fileInfos.length,
                    size: size,
                    pages: pages,
                }),
            ]),
        )
        return
    }

    dispatch(LongRunningTaskFinished('preparingDownload'))
    await downloadDeletedFiles(dispatch, {
        jobID,
        fileIDs: fileInfos.map(({ fileID }) => fileID),
        zipFileName,
    })
}

type MaybePaginatedDownloadJobParams =
    | DownloadParams<
          'download',
          {
              hasHEIC: boolean
              context?: FileOptionContext
              size?: number
          }
      >
    | DownloadParams<
          Exclude<JobDownloadType, 'download'>,
          {
              context?: FileOptionContext
              size?: number
          }
      >
export const maybePaginatedDownloadJob = async (
    dispatch: Dispatch,
    params: MaybePaginatedDownloadJobParams,
) => {
    const {
        type,
        jobID,
        context = 'Unknown',
        zipFileName = PRODUCT_NAME,
        size,
    } = params

    dispatch(LongRunningTaskStarted('preparingDownload'))
    trackEventInternal(
        `${context.toLowerCase()}_'takeout_job_as_archive_initiated`,
        {
            origin: context,
        },
    )

    if (size === undefined || size > SIZE_DOWNLOAD_LIMIT) {
        const { size: totalSize, fileInfos } =
            await getJobDownloadPaginationInfo(jobID)

        if (totalSize > SIZE_DOWNLOAD_LIMIT) {
            dispatch(
                BulkOfActions([
                    LongRunningTaskFinished('preparingDownload'),
                    FilesOptionsTriggered({
                        type: 'download_huge_selection',
                        downloadType: type,
                        jobID,
                        fileInfos,
                        size: totalSize,
                        context,
                        zipFileName,
                    }),
                ]),
            )
            return
        }
    }

    dispatch(LongRunningTaskFinished('preparingDownload'))

    if (params.type === 'download') {
        await downloadJob(dispatch, {
            type,
            jobID,
            zipFileName,
            hasHEIC: params.hasHEIC,
        })
    } else {
        await downloadJob(dispatch, { type: params.type, jobID, zipFileName })
    }
}
