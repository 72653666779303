import { createSelector } from 'reselect'
import type { DeletedFile } from '~/@types/backend-types'
import { ThumbService } from '~/API/services/ThumbService'
import { getGroupSelectionStatus } from '~/components/TimelineScroller/makeScrollerGroups'
import { groupArray, withoutTheBools } from '~/utilities/arrayUtils'
import { getTimeSince } from '~/utilities/dateOperations'
import type { FileInfoForPagination } from '~/utilities/download'
import { getTotalSize } from '~/utilities/fileSize'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { computeImageGroupHeight } from '~/utilities/imageGroupStyle'
import { TRASH_FETCH_LIMIT } from '~/config/constants'
import { getAuthTokenForJob } from '../currentUser/selectors'
import type { BasicViewFile, FileWithGrouping } from '../files/selectors'
import { getThumbHost } from '../hosts/selectors'
import { getSelectedFileIDs } from '../selectedFiles/selectors'
import type { ImageGroup } from '../timeline/selectors'
import { getImageGroupStyle } from '../viewMode/selectors'
import type { StateWithTrash } from './reducer'

const getTrashPaginations = (state: StateWithTrash) => state.trash.paginations
const getTrashFileIDsByPagination = createSelector(
    getTrashPaginations,
    (paginations) => {
        const sortedOffsets = Object.keys(paginations)
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
            .map((offset) => parseInt(offset, 10))
        return sortedOffsets.map((offset) => paginations[offset]).flat()
    },
)
const getTrashFileList = (state: StateWithTrash) => state.trash.fileList
export const getAllFetchedTrashFiles = createSelector(
    getTrashFileIDsByPagination,
    getTrashFileList,
    (fileIDs, trashFileList) => {
        return fileIDs.map((id) => trashFileList[id]).filter((f) => f !== null)
    },
)
export const getAllTrashFileIDsForRestore = createSelector(
    getAllFetchedTrashFiles,
    (files) => files.map((f) => f.id),
)
const getProcessingFiles = (state: StateWithTrash) =>
    state.trash.processingFiles
export const isLoadingTrash = (state: StateWithTrash): boolean =>
    state.trash.fetchState === 'pending'
export const shouldTrashBeFetched = (state: StateWithTrash): boolean =>
    state.trash.fetchState === 'not_started'
export const getTrashFileLatestOffset = createSelector(
    getTrashPaginations,
    (paginations) => {
        const offsets = Object.keys(paginations).map((offset) =>
            parseInt(offset, 10),
        )
        if (offsets.length === 0) {
            return 0
        }

        return Math.max(...offsets)
    },
)
export const getTrashTotalItemCount = (state: StateWithTrash) =>
    state.trash.totalItemCount
export const hasMoreTrashFilesToFetch = createSelector(
    getTrashTotalItemCount,
    getTrashFileLatestOffset,
    (totalItemCount, latestOffset) => {
        return (
            totalItemCount !== undefined &&
            latestOffset + TRASH_FETCH_LIMIT < totalItemCount
        )
    },
)

export const TRASH_LIFE_EXPECTANCY = 90

export type TrashFile = FileWithGrouping<DeletedFile> & {
    daysRemaining: number
}
export const getFetchedTrashFiles: (state: StateWithTrash) => TrashFile[] =
    createSelector(
        getAllFetchedTrashFiles,
        getProcessingFiles,
        (allFiles: DeletedFile[], processingFiles: FileID[]) => {
            const trashFiles: TrashFile[] = []

            for (const file of allFiles) {
                if (processingFiles.includes(file.id)) {
                    continue
                }

                trashFiles.push({
                    ...file,
                    daysRemaining:
                        TRASH_LIFE_EXPECTANCY -
                        getTimeSince(
                            new Date(file.dtime * 1000),
                            new Date(Date.now()),
                            'day',
                        ),
                })
            }

            return trashFiles
        },
    )

type ThumbForTrashFilesSelector = Selector<
    StateOfSelector<typeof getThumbHost> &
        StateOfSelector<typeof getAuthTokenForJob> &
        StateOfSelector<typeof getAllFetchedTrashFiles>,
    Record<FileID, string>
>
const makeThumbForTrashFilesSelector = (
    size: number,
): ThumbForTrashFilesSelector =>
    createSelector(
        getThumbHost,
        getAuthTokenForJob,
        getAllFetchedTrashFiles,
        (
            host: string | null,
            auth: string,
            files: DeletedFile[],
        ): Record<FileID, string> => {
            const thumbURLDictionary: Record<FileID, string> = {}
            if (host) {
                const service = new ThumbService(host, auth)

                files.forEach((file: DeletedFile) => {
                    const { id, job, path } = file
                    if (getFileTargetFromName(path) !== FileTarget.Documents) {
                        thumbURLDictionary[id] = service.getThumbUrl(
                            job,
                            id,
                            size,
                        )
                    }
                })
            }

            return thumbURLDictionary
        },
    )

const getSelectedTrashFiles = createSelector(
    getSelectedFileIDs,
    getFetchedTrashFiles,
    (selectedFileIDs, deletedFiles): TrashFile[] => {
        return deletedFiles.filter((f) => selectedFileIDs.includes(f.id))
    },
)

const trashFileToFileInfo = ({
    id,
    size,
    path,
    mtime,
}: TrashFile): FileInfoForPagination => ({
    fileID: id,
    size,
    path,
    mtime,
})

export const getSelectedTrashFileIDs = createSelector(
    getSelectedTrashFiles,
    (files) => files.map((f) => f.id),
)

export const hasSelectedTrashFiles = createSelector(
    getSelectedTrashFiles,
    (files) => files.length !== 0,
)

export const getSelectedTrashFileInfos = createSelector(
    getSelectedTrashFiles,
    (files): FileInfoForPagination[] => files.map(trashFileToFileInfo),
)
export const getAllFetchedTrashFileInfos = createSelector(
    getFetchedTrashFiles,
    (files): FileInfoForPagination[] => files.map(trashFileToFileInfo),
)
export const getSelectedTrashFilesTotalSize = createSelector(
    getSelectedTrashFiles,
    getTotalSize,
)

export type TrashViewFile = TrashFile & {
    thumbnail: string
}

export const getTrashViewFiles = createSelector(
    getFetchedTrashFiles,
    makeThumbForTrashFilesSelector(256),
    (files, thumbs): TrashViewFile[] => {
        return files.map((f) => ({
            ...f,
            thumbnail: thumbs[f.id],
        }))
    },
)

const trashFileToBasicViewFile = (f: TrashViewFile): BasicViewFile => ({
    fileID: f.id,
    jobID: f.job,
    mtime: f.mtime,
    dtime: f.dtime,
    path: f.path,
    type: getFileTargetFromName(f.path),
    width: f.width,
    height: f.height,
    thumbURLSmall: f.thumbnail,
    thumbURLMedium: f.thumbnail,
    thumbURLLarge: f.thumbnail,
    group:
        f.group_type && f.group_id
            ? {
                  type: f.group_type,
                  id: f.group_id,
                  isMaster: f.master === '1',
              }
            : undefined,
})

export enum TrashGroupKey {
    DeletedSoon = 'removed_soon',
    PermanetlyDeletedSoon = 'permanently_deleted_soon',
}

export const makeTrashImageGroup = (
    files: TrashViewFile[],
    groupStyle: ImageGroupStyle,
): ImageGroup[] => {
    const images = groupArray(files, (file) =>
        file.daysRemaining <= 5
            ? TrashGroupKey.DeletedSoon
            : TrashGroupKey.PermanetlyDeletedSoon,
    )
    if (files.length !== 0) {
        if (images[TrashGroupKey.DeletedSoon])
            images[TrashGroupKey.DeletedSoon].reverse() // display latest deleted files first
        if (images[TrashGroupKey.PermanetlyDeletedSoon])
            images[TrashGroupKey.PermanetlyDeletedSoon].reverse()
    }

    let accumulateHeight = 0
    const groupHeaders =
        files[0] && files[0].daysRemaining <= 5
            ? [TrashGroupKey.DeletedSoon, TrashGroupKey.PermanetlyDeletedSoon]
            : [TrashGroupKey.PermanetlyDeletedSoon, TrashGroupKey.DeletedSoon]

    const temp =
        files[0] && files[0].daysRemaining <= 5
            ? groupHeaders
            : [...groupHeaders].reverse()

    return withoutTheBools(
        // account for both ascending and descending ordering from backend (so files to fetch appear in top group)
        temp.reverse().map((key) => {
            if (images[key] === undefined || images[key].length === 0) {
                return false
            }
            const position = accumulateHeight
            const height = computeImageGroupHeight(
                images[key].length,
                groupStyle,
            )
            accumulateHeight += height

            return {
                groupKey: key,
                images: images[key].map(trashFileToBasicViewFile),
                height,
                position,
            }
        }),
    )
}

export const getTrashImageGroups = createSelector(
    getTrashViewFiles,
    getImageGroupStyle,
    makeTrashImageGroup,
)

export const getTrashGroupWithSelection = createSelector(
    getTrashImageGroups,
    getSelectedTrashFileIDs,
    (trashGroups, fileIDs): ImageGroup[] => {
        return trashGroups.map((tgroup) => ({
            ...tgroup,
            selectionStatus: getGroupSelectionStatus(
                tgroup.images.filter(
                    (f) => f && fileIDs.indexOf(f.fileID) !== -1,
                ).length,
                tgroup.images.length,
            ),
        }))
    },
)

export const getFetchRemainingTrashFilesProgress = (state: StateWithTrash) =>
    state.trash.fetchRemainingTrashFilesProgress
export const getRestoreAllProgress = (state: StateWithTrash) =>
    state.trash.restoreAllProgress
