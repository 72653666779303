import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface RestoreAllIncompletePromptProps {
    failedFilesCount: number
    handleConfirm: () => void
}

export const RestoreAllIncompletePrompt = ({
    failedFilesCount,
    handleConfirm,
}: RestoreAllIncompletePromptProps) => {
    const bodyText =
        failedFilesCount === 1
            ? _('photos_trash__restore_all_incomplete_single_file_body')
            : _(
                  'photos_trash__restore_all_incomplete_multiple_files_body',
              ).replace('%count%', failedFilesCount.toString())

    return (
        <Prompt.Content
            icon={<Prompt.WarningIcon />}
            title={_('photos_trash__restore_all_incomplete_title')}>
            <Typography.Paragraph>{bodyText}</Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton variant="primary" onPress={handleConfirm}>
                    {_('got_it')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
