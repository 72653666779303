/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKAddGrantPostParams,
    CAPBAKBodyCreatePrivateShareTestPrivateSharePost,
    CAPBAKCollection,
    CAPBAKCollectionType,
    CAPBAKCreateDummySignedJwtGetParams,
    CAPBAKCustomerServiceToolAuthPostPayload,
    CAPBAKCustomerServiceToolAuthResp,
    CAPBAKDeleteFilesResp,
    CAPBAKDeleteStripeDataPostParams,
    CAPBAKDeleteTemplateResp,
    CAPBAKDeleteUserWithoutTelenorIdComsPostParams,
    CAPBAKDigitalLegacyComsPostParams,
    CAPBAKDummyPoliceJwtResp,
    CAPBAKEraseStoreSubDeleteParams,
    CAPBAKFullScreenCardPostParams,
    CAPBAKHTTPValidationError,
    CAPBAKLocalePostParams,
    CAPBAKLocaleResp,
    CAPBAKLoginIdentifierGetParams,
    CAPBAKLoginIdentifierResp,
    CAPBAKMessageResponse,
    CAPBAKPrivateShareResp,
    CAPBAKResetTempFakeStipeCountryPostParams,
    CAPBAKSaveTemplateResp,
    CAPBAKSearchRolloutPostByUsernameParams,
    CAPBAKSendCollectionPushNotificationPostParams,
    CAPBAKSendCollectionPushNotificationPostPayload,
    CAPBAKSendCsamResp,
    CAPBAKSendPushNotificationPostParams,
    CAPBAKSendPushNotificationPostPayload,
    CAPBAKSunsetComsPostParams,
    CAPBAKSunsetDeletePostParams,
    CAPBAKThrowawayAccountResp,
    CAPBAKThrowawayUserPostParams,
    CAPBAKThrowawayUserPostPayload,
    CAPBAKThrowawayUserTemplateDeleteParams,
    CAPBAKThrowawayUserTemplatePostByJobUuidParams,
    CAPBAKUndoDeletionRequestResp,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Test<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call allows the clients to send in the locale value. The backend performs the check and fix locale operation. The client returns the value after check and fix locale operation has been completed.
     *
     * @tags testing
     * @name LocalePost
     * @summary Test Locale
     * @request POST:/test/locale
     */
    localePost = (query: CAPBAKLocalePostParams, params: RequestParams = {}) =>
        this.request<CAPBAKLocaleResp, CAPBAKHTTPValidationError>({
            path: `/test/locale`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description This test function deletes the original file in the cache + thumbnails size 256-80 and -1280 For video it does not remove the encoded video from cache
     *
     * @tags testing
     * @name CacheRemovePostByJobUuidAndFileUuid
     * @summary Test Cache Remove
     * @request POST:/test/cache-remove/{job_uuid}/{file_uuid}
     */
    cacheRemovePostByJobUuidAndFileUuid = (
        jobUuid: string,
        fileUuid: string,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/cache-remove/${jobUuid}/${fileUuid}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * @description TODO: Add information The meaning of this call is to check if a thumbnail is in cache and storage. But now we only test if the thumbnail is in storage.
     *
     * @tags testing
     * @name VerifyThumbsPostByJobUuidAndFileUuid
     * @summary Verify Thumbs
     * @request POST:/test/verify-thumbs/{job_uuid}/{file_uuid}
     */
    verifyThumbsPostByJobUuidAndFileUuid = (
        jobUuid: string,
        fileUuid: string,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/verify-thumbs/${jobUuid}/${fileUuid}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name VerifyThumbsCachePostByJobUuidAndFileUuid
     * @summary Verify Thumbs Cache
     * @request POST:/test/verify-thumbs-cache/{job_uuid}/{file_uuid}
     */
    verifyThumbsCachePostByJobUuidAndFileUuid = (
        jobUuid: string,
        fileUuid: string,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/verify-thumbs-cache/${jobUuid}/${fileUuid}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name VerifyThumbsStoragePostByJobUuidAndFileUuid
     * @summary Verify Thumbs Storage
     * @request POST:/test/verify-thumbs-storage/{job_uuid}/{file_uuid}
     */
    verifyThumbsStoragePostByJobUuidAndFileUuid = (
        jobUuid: string,
        fileUuid: string,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/verify-thumbs-storage/${jobUuid}/${fileUuid}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name JobDeleteAllFilesPost
     * @summary Test Delete All Files
     * @request POST:/test/job/{job_uuid}/delete-all-files
     */
    jobDeleteAllFilesPost = (jobUuid: string, params: RequestParams = {}) =>
        this.request<CAPBAKDeleteFilesResp, CAPBAKHTTPValidationError>({
            path: `/test/job/${jobUuid}/delete-all-files`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name ThrowawayUserPost
     * @summary Make Throwaway User
     * @request POST:/test/throwaway_user
     */
    throwawayUserPost = (
        query: CAPBAKThrowawayUserPostParams,
        data: CAPBAKThrowawayUserPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKThrowawayAccountResp, CAPBAKHTTPValidationError>({
            path: `/test/throwaway_user`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name ThrowawayUserTemplatePostByJobUuid
     * @summary Save Throwaway Template
     * @request POST:/test/throwaway_user_template/{job_uuid}
     */
    throwawayUserTemplatePostByJobUuid = (
        { jobUuid, ...query }: CAPBAKThrowawayUserTemplatePostByJobUuidParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKSaveTemplateResp, CAPBAKHTTPValidationError>({
            path: `/test/throwaway_user_template/${jobUuid}`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name ThrowawayUserTemplateDelete
     * @summary Delete Throwaway Template
     * @request DELETE:/test/throwaway_user_template
     */
    throwawayUserTemplateDelete = (
        query: CAPBAKThrowawayUserTemplateDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKDeleteTemplateResp, CAPBAKHTTPValidationError>({
            path: `/test/throwaway_user_template`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SearchRolloutPostByUsername
     * @summary Rollout User To Search
     * @request POST:/test/search_rollout/{username}
     */
    searchRolloutPostByUsername = (
        { username, ...query }: CAPBAKSearchRolloutPostByUsernameParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/search_rollout/${username}`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name CustomerServiceToolAuthPost
     * @summary Get Customer Service Tool Auth
     * @request POST:/test/customer_service_tool_auth
     */
    customerServiceToolAuthPost = (
        data: CAPBAKCustomerServiceToolAuthPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKCustomerServiceToolAuthResp,
            CAPBAKHTTPValidationError
        >({
            path: `/test/customer_service_tool_auth`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description Allow developers to send push notification to device via the backend
     *
     * @tags testing
     * @name SendPushNotificationPost
     * @summary Send Push Notification
     * @request POST:/test/send_push_notification
     */
    sendPushNotificationPost = (
        query: CAPBAKSendPushNotificationPostParams,
        data: CAPBAKSendPushNotificationPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/send_push_notification`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * @description Allow developers to send push notification to device for different collections via the backend
     *
     * @tags testing
     * @name SendCollectionPushNotificationPost
     * @summary Send Collection Push
     * @request POST:/test/send_collection_push_notification
     */
    sendCollectionPushNotificationPost = (
        query: CAPBAKSendCollectionPushNotificationPostParams,
        data: CAPBAKSendCollectionPushNotificationPostPayload,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/send_collection_push_notification`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name EraseStoreSubDelete
     * @summary Erase Store Sub
     * @request DELETE:/test/erase_store_sub
     */
    eraseStoreSubDelete = (
        query: CAPBAKEraseStoreSubDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/erase_store_sub`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name RemoveNonDefaultGrantsDelete
     * @summary Remove Non Default Grants
     * @request DELETE:/test/remove_non_default_grants
     */
    removeNonDefaultGrantsDelete = (params: RequestParams = {}) =>
        this.request<any[] | null, any>({
            path: `/test/remove_non_default_grants`,
            method: 'DELETE',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name AddGrantPost
     * @summary Add Grant
     * @request POST:/test/add_grant
     */
    addGrantPost = (
        query: CAPBAKAddGrantPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/add_grant`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name LoginIdentifierGet
     * @summary Get Login Identifier
     * @request GET:/test/login_identifier
     */
    loginIdentifierGet = (
        query: CAPBAKLoginIdentifierGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKLoginIdentifierResp, CAPBAKHTTPValidationError>({
            path: `/test/login_identifier`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name PrivateSharePost
     * @summary Create Private Share
     * @request POST:/test/private_share
     */
    privateSharePost = (
        data: CAPBAKBodyCreatePrivateShareTestPrivateSharePost,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKPrivateShareResp, CAPBAKHTTPValidationError>({
            path: `/test/private_share`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name UndoRequestedDeletePostByUsername
     * @summary Undo Requested Delete
     * @request POST:/test/undo_requested_delete/{username}
     */
    undoRequestedDeletePostByUsername = (
        username: string,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKUndoDeletionRequestResp, CAPBAKHTTPValidationError>({
            path: `/test/undo_requested_delete/${username}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name CreateDummySignedJwtGet
     * @summary Create Dummy Signed Jwt
     * @request GET:/test/create_dummy_signed_jwt
     */
    createDummySignedJwtGet = (
        query: CAPBAKCreateDummySignedJwtGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKDummyPoliceJwtResp, CAPBAKHTTPValidationError>({
            path: `/test/create_dummy_signed_jwt`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SendCsamReportPost
     * @summary Send Csam Report
     * @request POST:/test/send_csam_report
     */
    sendCsamReportPost = (params: RequestParams = {}) =>
        this.request<CAPBAKSendCsamResp, any>({
            path: `/test/send_csam_report`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name DeleteStripeDataPost
     * @summary Delete Stripe Data
     * @request POST:/test/delete_stripe_data
     */
    deleteStripeDataPost = (
        query: CAPBAKDeleteStripeDataPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/delete_stripe_data`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name FullScreenCardPost
     * @summary Add Full Screen Card
     * @request POST:/test/full_screen_card
     */
    fullScreenCardPost = (
        query: CAPBAKFullScreenCardPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/full_screen_card`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name JobsMemoryCollectionsPostByJobUuidAndCollectionType
     * @summary Create Memory Collection
     * @request POST:/test/jobs/{job_uuid}/memory_collections/{collection_type}
     */
    jobsMemoryCollectionsPostByJobUuidAndCollectionType = (
        jobUuid: string,
        collectionType: CAPBAKCollectionType,
        params: RequestParams = {},
    ) =>
        this.request<any, CAPBAKHTTPValidationError>({
            path: `/test/jobs/${jobUuid}/memory_collections/${collectionType}`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name MemoryCollectionsDetailByCollectionUuid
     * @summary Get Memory Collection
     * @request GET:/test/memory_collections/{collection_uuid}
     */
    memoryCollectionsDetailByCollectionUuid = (
        collectionUuid: string,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKCollection, CAPBAKHTTPValidationError>({
            path: `/test/memory_collections/${collectionUuid}`,
            method: 'GET',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SunsetComsPost
     * @summary Sunset Coms
     * @request POST:/test/sunset_coms
     */
    sunsetComsPost = (
        query: CAPBAKSunsetComsPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/sunset_coms`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SunsetDeletePost
     * @summary Sunset Delete User Post
     * @request POST:/test/sunset_delete
     */
    sunsetDeletePost = (
        query: CAPBAKSunsetDeletePostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/sunset_delete`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name DeleteUserWithoutTelenorIdComsPost
     * @summary Delete User Without Telenor Id Coms
     * @request POST:/test/delete_user_without_telenor_id_coms
     */
    deleteUserWithoutTelenorIdComsPost = (
        query: CAPBAKDeleteUserWithoutTelenorIdComsPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/delete_user_without_telenor_id_coms`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SetReadOnlyGrantPost
     * @summary Set Read Only Grant
     * @request POST:/test/set_read_only_grant
     */
    setReadOnlyGrantPost = (params: RequestParams = {}) =>
        this.request<CAPBAKMessageResponse, any>({
            path: `/test/set_read_only_grant`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name SetFreemiumGrantPost
     * @summary Set Freemium Grant
     * @request POST:/test/set_freemium_grant
     */
    setFreemiumGrantPost = (params: RequestParams = {}) =>
        this.request<CAPBAKMessageResponse, any>({
            path: `/test/set_freemium_grant`,
            method: 'POST',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name ResetTempFakeStipeCountryPost
     * @summary Reset Temp Fake Stipe Country
     * @request POST:/test/reset_temp_fake_stipe_country
     */
    resetTempFakeStipeCountryPost = (
        query: CAPBAKResetTempFakeStipeCountryPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/reset_temp_fake_stipe_country`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name RemoveUserDelete
     * @summary Remove User
     * @request DELETE:/test/remove_user
     */
    removeUserDelete = (params: RequestParams = {}) =>
        this.request<CAPBAKMessageResponse, any>({
            path: `/test/remove_user`,
            method: 'DELETE',
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags testing
     * @name DigitalLegacyComsPost
     * @summary Digital Legacy Coms
     * @request POST:/test/digital_legacy_coms
     */
    digitalLegacyComsPost = (
        query: CAPBAKDigitalLegacyComsPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/test/digital_legacy_coms`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
