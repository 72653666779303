export class RecordUtil<TKey extends string, TVal> {
    public static from<TKey extends string, TVal>(
        dict: Record<TKey, TVal>,
    ): RecordUtil<TKey, TVal> {
        return new RecordUtil(dict)
    }

    private _keys: TKey[]
    public constructor(private dict: Record<TKey, TVal>) {
        this._keys = Object.keys(this.dict) as TKey[]
    }

    public getValue = (): Record<TKey, TVal> => this.dict

    public asArray(): Array<{ key: TKey; value: TVal }> {
        return this._keys.map((key) => ({ key, value: this.dict[key] }))
    }

    /**
     * The filter-method returns a new RecordUtil without the elements for which the filter-method returns false.
     *
     * If the filter-method is a type-predicate which narrows the type of the key from the existing keys, the resulting
     * util-instance will use this key-typing. In all other cases the key-type will be widened to string, essentially
     * returning a Dictionary (since no guarantees can be made for which keys will be present in the result)
     *
     * Simultanious type-narrowing of both the key and the value type is not possible with the type-predicates
     *   (can be simulated by `.filter`ing the keys and then `.map`ing the values)
     *
     * @param method Filter method
     * @returns RecordUtil
     */
    public filter<TNewKey extends TKey>(
        method: (val: TVal, key: TKey) => key is TNewKey,
    ): RecordUtil<TNewKey, TVal>
    public filter<TNewVal extends TVal>(
        method: (val: TVal, key: TKey) => val is TNewVal,
    ): RecordUtil<string, TNewVal>
    public filter(
        method: (val: TVal, key: TKey) => boolean,
    ): RecordUtil<string, TVal>
    public filter<TNewKey extends TKey, TNewVal extends TVal>(
        method: (val: TVal, key: TKey) => boolean,
    ) {
        const newRecord: Partial<Record<TNewKey, TNewVal>> = {}
        this._keys.forEach((key) => {
            const value = this.dict[key]
            if (method(value, key)) {
                newRecord[key as TNewKey] = value as TNewVal
            }
        })
        return new RecordUtil(newRecord as Record<TNewKey, TNewVal>)
    }

    public forEach(method: (val: TVal, key: TKey) => void) {
        this._keys.forEach((k) => method(this.dict[k], k))
    }

    public map<TNewVal>(
        method: (val: TVal, key: TKey) => TNewVal,
    ): RecordUtil<TKey, TNewVal> {
        const result: Partial<Record<TKey, TNewVal>> = {}
        this._keys.forEach((key) => {
            result[key] = method(this.dict[key], key)
        })
        return new RecordUtil<TKey, TNewVal>(result as Record<TKey, TNewVal>)
    }

    public reduce = <U>(
        method: (prev: U, current: TVal, key: TKey) => U,
        init: U,
    ): U =>
        this._keys.reduce(
            (prev, key) => method(prev, this.dict[key], key),
            init,
        )
}

export const Dictionary_asArray = <T>(
    dict: DictionaryOf<T>,
): Array<{ key: string; value: T }> => {
    return RecordUtil.from(dict).asArray()
}

export const Dictionary_forEach = <T>(
    dict: DictionaryOf<T>,
    method: (val: T, key: string) => void,
): void => {
    RecordUtil.from(dict).forEach(method)
}

export const Dictionary_map = <T, U>(
    dict: DictionaryOf<T>,
    method: (val: T, key: string) => U,
): DictionaryOf<U> => {
    return RecordUtil.from(dict).map(method).getValue()
}

export const Dictionary_filter = <T>(
    dict: DictionaryOf<T>,
    method: (val: T, key: string) => boolean,
): DictionaryOf<T> => {
    return RecordUtil.from(dict).filter(method).getValue()
}

export const Dictionary_reduce = <T, U>(
    dict: DictionaryOf<T>,
    method: (acc: U, val: T, key: string) => U,
    init: U,
): U => {
    return RecordUtil.from(dict).reduce(method, init)
}

/**
 * Transforms the values of an object using a provided transformation function.
 *
 * @param obj - The object whose values are to be transformed.
 * @param transform - A function that takes a value and its key, and returns a new value.
 * @returns A new object with the same keys as the input object, but with transformed values.
 */
export function mapObject<T extends object, U>(
    obj: T,
    transform: (value: T[keyof T], key: keyof T) => U,
): { [K in keyof T]: U } {
    return Object.keys(obj).reduce(
        (acc, key) => {
            const typedKey = key as keyof T
            acc[typedKey] = transform(obj[typedKey], typedKey)
            return acc
        },
        {} as { [K in keyof T]: U },
    )
}
