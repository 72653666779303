import { useCallback, useEffect, useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAndRedirectToOpenWeb } from '@capture/client/API/login'
import { _ } from '@capture/client/assets/localization/util'
import { Typography, Prompt } from '@capture/capture-components'
import { getServiceProvider } from '@capture/client/API/HostProvider'
import type { AppService } from '@capture/client/API/services/AppService'

enum Status {
    NOT_STARTED = 'NOT_STARTED',
    PENDING_SERVER = 'PENDING_SERVER',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

const useAppServerCallback = (
    action: (service: AppService) => void,
): [() => void, Status] => {
    const [requestStatus, setRequested] = useState<Status>(Status.NOT_STARTED)

    const doRequest = async () => {
        setRequested(Status.PENDING_SERVER)
        try {
            const service =
                getServiceProvider().getAppServiceForLoggedInUserDefaults()
            action(service)
            setRequested(Status.SUCCEEDED)
        } catch (e) {
            setRequested(Status.FAILED)
        }
    }
    return [doRequest, requestStatus]
}

const _AccessDataPrompt = () => {
    const [requestData, requestStatus] = useAppServerCallback((s) =>
        s.downloadDataProtectionRequestedDataAccess(),
    )
    const [canFetch, setCanFetch] = useState<boolean | null>(null)

    useEffect(() => {
        async function checkRequestDataAccessAvailability() {
            const service =
                getServiceProvider().getAppServiceForLoggedInUserDefaults()

            try {
                await service.getRequestDataAccessAvailability()
                setCanFetch(true)
            } catch (e) {
                setCanFetch(false)
            }
        }
        checkRequestDataAccessAvailability()
    }, [])

    if (canFetch === false) {
        return (
            <Prompt.Content
                title={_('privacy_request_a_copy_of_data')}
                data-cy="privacyOverlay_requestData">
                <Typography.Paragraph>
                    {_(
                        'metadata_recently_downloaded_please_wait__format',
                    ).replace('%d', '24')}
                </Typography.Paragraph>
                <Prompt.ButtonsSection>
                    <Prompt.ActionButton variant="secondary" key="close">
                        {_('close')}
                    </Prompt.ActionButton>
                </Prompt.ButtonsSection>
            </Prompt.Content>
        )
    }

    return (
        <Prompt.Content
            data-cy="privacyOverlay_requestData"
            title={_('privacy_request_a_copy_of_data')}>
            <Typography.Paragraph>
                {_('per_gdpr_right_to_request')}
            </Typography.Paragraph>
            <Typography.Paragraph>
                {_('if_you_continue_metadata_download__format').replace(
                    '%d',
                    '24',
                )}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                {requestStatus === Status.SUCCEEDED
                    ? [
                          <Prompt.ActionButton variant="secondary" key="close">
                              {_('close')}
                          </Prompt.ActionButton>,
                      ]
                    : [
                          <Prompt.ActionButton key="cancel" variant="secondary">
                              {_('cancel')}
                          </Prompt.ActionButton>,
                          <Prompt.ActionButton
                              closeOnSubmit={false}
                              variant="primary"
                              key="submit"
                              onPress={requestData}
                              isDisabled={!canFetch}
                              data-cy="privacyOverlay_requestDataBtn">
                              {_('privacy_request_a_copy_of_data')}
                          </Prompt.ActionButton>,
                      ]}
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
export const AccessDataPrompt = memo(_AccessDataPrompt)

export const _DeleteAccountPrompt = () => {
    const [requestDeletion, requestStatus] = useAppServerCallback((s) =>
        s.sendDataProtectionRequestForAccountDeletion(),
    )
    const dispatch = useDispatch()
    const logoutToOpenWeb = useCallback(
        () => logoutAndRedirectToOpenWeb(dispatch),
        [dispatch],
    )

    if (requestStatus === Status.SUCCEEDED) {
        return (
            <Prompt.Content
                title={_('delete_your_account')}
                data-cy="privacyOverlay_confirmDeletion">
                <Typography.Paragraph>
                    <Typography.Bold>
                        {_('delete_your_account__success1')}
                    </Typography.Bold>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Bold>
                        {_('delete_your_account__success2__format').replace(
                            '%d',
                            '30',
                        )}
                    </Typography.Bold>
                </Typography.Paragraph>
                <Prompt.ButtonsSection>
                    <Prompt.ActionButton
                        variant="primary"
                        key="close"
                        onPress={logoutToOpenWeb}
                        data-cy="privacyOverlay_deletionSuccessfulCloseBtn">
                        {_('close')}
                    </Prompt.ActionButton>
                </Prompt.ButtonsSection>
            </Prompt.Content>
        )
    }

    if (requestStatus === Status.FAILED) {
        return (
            <Prompt.Content
                data-cy="privacyOverlay_confirmDeletion"
                title={_('delete_your_account')}>
                <Typography.Paragraph>
                    {_('delete_your_account__failed1')}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {_('generic_explanation_for_why_it_may_failed')}
                </Typography.Paragraph>
                <Prompt.ButtonsSection>
                    <Prompt.ActionButton variant="secondary" key="close">
                        {_('close')}
                    </Prompt.ActionButton>
                    <Prompt.ActionButton
                        onPress={requestDeletion}
                        closeOnSubmit={false}
                        variant="primary"
                        key="retry">
                        {_('retry')}
                    </Prompt.ActionButton>
                </Prompt.ButtonsSection>
            </Prompt.Content>
        )
    }

    return (
        <Prompt.Content
            data-cy="privacyOverlay_confirmDeletion"
            title={_('delete_your_account')}>
            <Typography.Paragraph>
                {_('delete_your_account__info4')}
            </Typography.Paragraph>
            <Typography.Paragraph>
                <Typography.Bold>
                    {_('delete_your_account__info5')}
                </Typography.Bold>
            </Typography.Paragraph>
            <Typography.Paragraph>
                {_('delete_your_account__info6')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton
                    variant="secondary"
                    isDisabled={requestStatus === Status.PENDING_SERVER}
                    data-cy="privacyOverlay_cancelDeletionBtn"
                    key="cancel">
                    {_('cancel')}
                </Prompt.ActionButton>
                <Prompt.ActionButton
                    variant="destructive"
                    key="delete"
                    closeOnSubmit={false}
                    onPress={requestDeletion}
                    data-cy="privacyOverlay_confirmDeletionBtn"
                    isDisabled={requestStatus === Status.PENDING_SERVER}>
                    {_('delete_account')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}

export const DeleteAccountPrompt = memo(_DeleteAccountPrompt)
