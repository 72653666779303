import { CAPBAKPrivacyMode } from '@capture/client-api/src/schemas/data-contracts'
import type { NavigateFunction } from 'react-router-dom'
import type { CreateJobProperties } from '~/@types/backend-types'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import { Album, Albums } from '~/routing/pages'
import {
    AlbumSortOrderSaveFailed,
    AlbumSortOrderSaved,
    AlbumWasCreated,
    AutoGeneratedAlbumCreated,
} from '~/state/album/actions'
import type { AlbumViewMode } from '~/state/album/selectors'
import type {
    AlbumSortOrder,
    ConfigurableAlbumDetails,
} from '~/state/albumList/reducer'
import type { Dispatch } from '~/state/common/actions'
import { guaranteeAUser } from '~/state/currentUser'
import {
    AlbumUnsubscriptionConfirmed,
    AlbumUnsubscriptionFailed,
    UserSubscribedToAlbum,
    UserUnsubscribedFromAlbum,
} from '~/state/currentUser/actions'
import * as FilesActions from '~/state/files/actions'
import type { FileWithGrouping } from '~/state/files/selectors'
import {
    AlbumViewModeFetched,
    FileWasSetAsCoverPhoto,
    FilesCopiedToAlbumFailed,
    SetFileAsCoverPhotoFailed,
} from '~/state/job/actions'
import * as JobInfoActions from '~/state/jobInfo/actions'
import {
    ReactionAdded,
    ReactionDeleted,
    Reactions,
} from '~/state/reaction/actions'
import {
    LongRunningTaskFinished,
    LongRunningTaskStarted,
} from '~/state/statusNotifications/actions'
import { isCurrentLocation } from '~/utilities/navigation'
import {
    localStorageGet,
    localStorageRemove,
    localStorageSet,
} from '~/utilities/webStorage'
import { refreshAlbumCount } from './currentUser'
import { getServiceProvider } from './HostProvider'
import { copyMultipleFilesToJob } from './job'

const maybeResumeCommenting = async (dispatch: Dispatch) => {
    const stored = localStorageGet('pendingComment')
    if (stored) {
        localStorageRemove('pendingComment')
        const c = JSON.parse(stored)
        return postComment(dispatch, c.albumID, c.fileID, c.comment)
    }
}

const maybeResumeLove = async (dispatch: Dispatch) => {
    const stored = localStorageGet('pendingLove')
    if (stored) {
        localStorageRemove('pendingLove')
        const r = JSON.parse(stored)
        return loveAlbumFile(dispatch, r.albumID, r.fileID)
    }
}

export const maybeResumeAction = (dispatch: Dispatch) => {
    maybeResumeCommenting(dispatch)
    maybeResumeLove(dispatch)
}

export async function postComment(
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
    comment: string,
) {
    dispatch(FilesActions.FileCommentSubmitted({ fileID, comment }))

    // Store the pending comment in localStorage while fetching user (to allow re-inserting the text after redirects)
    localStorageSet(
        'pendingComment',
        JSON.stringify({ albumID, fileID, comment }),
    )
    try {
        const userID = await guaranteeAUser() // Will redirect to login if needed
        localStorageRemove('pendingComment') // Clear as we managed to get a user.

        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        try {
            const response = await service.addComment(albumID, fileID, comment)
            const payload = {
                fileID,
                comment,
                commentUUID: response.comment_uuid,
                timestamp: Math.floor(Date.now() / 1000),
                userUUID: userID,
            }
            dispatch(FilesActions.FileCommentSubmitSuccessful(fileID))
            dispatch(FilesActions.FileWasCommented(payload))
        } catch (e) {
            dispatch(FilesActions.FileCommentError(fileID))
            throw new Error()
        }
    } catch (e) {
        dispatch(FilesActions.FileCommentAborted(fileID))
        localStorageRemove('pendingComment') // Clear as we were not redirected.
        throw new Error('Could not post comment')
    }
}

export async function deleteComment(
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
    commentID: CommentID,
) {
    dispatch(FilesActions.CommentDeletionStarted({ commentID }))
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.deleteComment(albumID, fileID, commentID)
        dispatch(FilesActions.CommentWasDeleted(commentID))
    } catch (e) {
        dispatch(FilesActions.DeleteCommentFailed({ commentID }))
    }
}

export async function editComment(
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
    commentID: CommentID,
    commentText: string,
) {
    dispatch(
        FilesActions.EditedCommentSubmitted({ commentID, text: commentText }),
    )
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.editComment(albumID, fileID, commentID, commentText)
        dispatch(
            FilesActions.EditCommentSuccessful({
                commentID,
                timestamp: Math.floor(new Date().getTime() / 1000),
            }),
        )
    } catch (e) {
        dispatch(FilesActions.EditCommentFailed({ commentID }))
    }
}

export const checkVideoTranscode = async (
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
): Promise<void> => {
    dispatch(FilesActions.VideoTranscodeStarted(fileID))
    const delay = new Promise<void>((resolve) => {
        window.setTimeout(resolve, 1000)
    })
    try {
        const service =
            await getServiceProvider().getVideoServiceForJob(albumID)

        // If the backend have started transcoding, the `checkVideo` hangs until the video is ready.
        const longWait = window.setTimeout(() => {
            dispatch(FilesActions.VideoTranscodeQueued(fileID))
        }, 2000)
        const response = await service.checkVideo(albumID, fileID)
        window.clearTimeout(longWait)

        // Still queued, refetch status recursively after waiting
        if (response.result === 'queued') {
            dispatch(FilesActions.VideoTranscodeQueued(fileID))
            await delay
            return checkVideoTranscode(dispatch, albumID, fileID)
        }

        if (response.result === 'ready') {
            dispatch(FilesActions.VideoTranscodeReady(fileID))
        } else {
            dispatch(FilesActions.VideoTranscodeError(fileID))
        }
    } catch (e) {
        dispatch(FilesActions.VideoTranscodeError(fileID))
    }
}

export const createAlbum = async (
    dispatch: Dispatch,
    name = '',
    allowSharing = false,
): Promise<JobID> => {
    const service = getServiceProvider().getAppServiceForLoggedInUserDefaults()
    const albumInfo = await service.createJob({
        type: 'story',
        allow_sharing: allowSharing,
        name,
    })
    dispatch(AlbumWasCreated({ jobID: albumInfo.id, shared: allowSharing }))
    await Promise.all([
        setAlbumSortOrder(dispatch, albumInfo.id, 'NEWEST_TAKEN'),
        storeUserDefinedViewMode(albumInfo.id, 'grid'),
    ])
    refreshAlbumCount(dispatch)
    return albumInfo.id
}

const albumDetailsToCreateJobProperties = ({
    title,
    isShared,
    allow_comments,
    allow_uploads,
}: ConfigurableAlbumDetails): CreateJobProperties => ({
    name: title,
    public: isShared,
    allow_comments: allow_comments,
    allow_uploads: allow_uploads,
})

export const createConfiguredAlbum = async (
    dispatch: Dispatch,
    details: ConfigurableAlbumDetails,
) => {
    const service = getServiceProvider().getAppServiceForLoggedInUserDefaults()
    const albumInfo = await service.createJob({
        type: 'story',
        ...albumDetailsToCreateJobProperties(details),
    })
    await Promise.all([
        setAlbumSortOrder(dispatch, albumInfo.id, 'NEWEST_TAKEN'),
        storeUserDefinedViewMode(albumInfo.id, 'grid'),
    ])
    dispatch(AlbumWasCreated({ jobID: albumInfo.id, shared: details.isShared }))
    return albumInfo.id
}

export const createConfiguredAlbumWithFiles = async (
    dispatch: Dispatch,
    details: ConfigurableAlbumDetails,
    files: FileWithGrouping[],
) => {
    const albumID = await createConfiguredAlbum(dispatch, details)
    dispatch(LongRunningTaskStarted('filesAreBeingCopied'))

    const { failed } = await copyMultipleFilesToJob(dispatch, albumID, files)
    if (failed.length > 0) {
        dispatch(FilesCopiedToAlbumFailed({ jobID: albumID, files: failed }))
    }
    dispatch(LongRunningTaskFinished('filesAreBeingCopied'))

    return albumID
}
// TODO: merge into one way of handling?
export const createAlbumWithFiles = async (
    dispatch: Dispatch,
    name: string,
    files: FileWithGrouping[],
    allowSharing = false,
): Promise<JobID> => {
    const albumID = await createAlbum(dispatch, name, allowSharing)
    dispatch(LongRunningTaskStarted('filesAreBeingCopied'))

    copyMultipleFilesToJob(dispatch, albumID, files).then((value) => {
        dispatch(LongRunningTaskFinished('filesAreBeingCopied'))
        if (value.failed.length > 0) {
            dispatch(
                FilesCopiedToAlbumFailed({
                    jobID: albumID,
                    files: value.failed,
                }),
            )
        }
    })
    return albumID
}

export const createAutoGeneratedAlbum = async (
    dispatch: Dispatch,
    name: string,
    tempID: string,
): Promise<JobID> => {
    const jobID = await createAlbum(dispatch, name)
    dispatch(AutoGeneratedAlbumCreated({ jobID, tempID, name }))
    trackEvent('Uploader', 'AlbumWasAutoCreated')
    trackEventInternal('album_uploader_album_was_auto_created')
    return jobID
}

export const deleteAlbum = async (
    dispatch: Dispatch,
    albumID: JobID,
    navigate?: NavigateFunction,
): Promise<void> => {
    dispatch(JobInfoActions.JobDeletionStarted(albumID))
    const service = await getServiceProvider().getAppServiceForJob(albumID)
    try {
        await service.deleteJob(albumID)
        dispatch(JobInfoActions.JobWasDeleted(albumID))
        if (isCurrentLocation(Album(albumID)) && navigate) {
            navigate(Albums.url)
        }
    } catch (e) {
        dispatch(JobInfoActions.JobDeletionFailed(albumID))
    }
    refreshAlbumCount(dispatch)
}

export const subscribeToAlbum = async (
    dispatch: Dispatch,
    albumID: JobID,
): Promise<void> => {
    const service = await getServiceProvider().getAppServiceForJob(albumID)
    await service.subscribeToJob(albumID)
    dispatch(UserSubscribedToAlbum(albumID))
    refreshAlbumCount(dispatch)
}

export const unsubscribeFromAlbum = async (
    dispatch: Dispatch,
    albumID: JobID,
): Promise<void> => {
    dispatch(AlbumUnsubscriptionConfirmed(albumID))
    const service = await getServiceProvider().getAppServiceForJob(albumID)
    try {
        await service.unsubscribeFromJob(albumID)
        dispatch(UserUnsubscribedFromAlbum(albumID))
        refreshAlbumCount(dispatch)
    } catch (e) {
        dispatch(AlbumUnsubscriptionFailed(albumID))
    }
}

export const setAlbumCoverPhoto = async (
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setCoverPhoto(albumID, fileID)
        dispatch(FileWasSetAsCoverPhoto({ jobID: albumID, fileID }))
    } catch (e) {
        dispatch(SetFileAsCoverPhotoFailed({ jobID: albumID, fileID }))
    }
}

export const setAlbumTitle = async (
    dispatch: Dispatch,
    albumID: JobID,
    title: string,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setJobName(albumID, title)

        dispatch(
            JobInfoActions.ChangeJobPropertySucceeded({
                job: albumID,
                property: 'title',
                value: title,
            }),
        )
    } catch (e) {
        dispatch(
            JobInfoActions.ChangeJobPropertyFailed({
                job: albumID,
                property: 'title',
            }),
        )
    }
}

export const setAlbumUploadsPermission = async (
    dispatch: Dispatch,
    albumID: JobID,
    enable: boolean,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setPermissionsforJob(albumID, {
            allow_uploads: enable ? 1 : 0,
        })

        dispatch(
            JobInfoActions.ChangeJobPropertySucceeded({
                job: albumID,
                property: 'allow_uploads',
                value: enable,
            }),
        )
    } catch (e) {
        dispatch(
            JobInfoActions.ChangeJobPropertyFailed({
                job: albumID,
                property: 'allow_uploads',
            }),
        )
    }
}

export const setAlbumCommentsPermission = async (
    dispatch: Dispatch,
    albumID: JobID,
    enable: boolean,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setPermissionsforJob(albumID, {
            allow_comments: enable ? 1 : 0,
        })

        dispatch(
            JobInfoActions.ChangeJobPropertySucceeded({
                job: albumID,
                property: 'allow_comments',
                value: enable,
            }),
        )
    } catch (e) {
        dispatch(
            JobInfoActions.ChangeJobPropertyFailed({
                job: albumID,
                property: 'allow_comments',
            }),
        )
    }
}

export const setAlbumSharingProperty = async (
    dispatch: Dispatch,
    albumID: JobID,
    enableSharing: boolean,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setPrivacyModeForJob(
            albumID,
            enableSharing
                ? CAPBAKPrivacyMode.Shared
                : CAPBAKPrivacyMode.Private,
        )

        dispatch(
            JobInfoActions.ChangeJobPropertySucceeded({
                job: albumID,
                property: 'isShared',
                value: enableSharing,
            }),
        )
    } catch (e) {
        dispatch(
            JobInfoActions.ChangeJobPropertyFailed({
                job: albumID,
                property: 'isShared',
            }),
        )
    }
}

export const loveAlbumFile = async (
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
): Promise<void> => {
    dispatch(
        FilesActions.ReactionChangesSubmitted({
            fileID,
            reaction: Reactions.Love,
        }),
    )

    try {
        localStorageSet('pendingLove', JSON.stringify({ albumID, fileID }))
        const userID = await guaranteeAUser()
        localStorageRemove('pendingLove')

        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.loveFile(albumID, fileID)

        dispatch(
            ReactionAdded({
                fileID,
                userUUID: userID,
                reaction: Reactions.Love,
            }),
        )
        dispatch(FilesActions.ReactionChangesSubmitSuccessfull(fileID))
    } catch (e) {
        dispatch(FilesActions.ReactionChangesSubmitError(fileID))
        localStorageRemove('pendingLove')
    }
}

export const unloveAlbumFile = async (
    dispatch: Dispatch,
    albumID: JobID,
    fileID: FileID,
): Promise<void> => {
    dispatch(
        FilesActions.ReactionChangesSubmitted({
            fileID,
            reaction: Reactions.None,
        }),
    )

    try {
        const userID = await guaranteeAUser()

        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.unLoveFile(albumID, fileID)

        dispatch(
            ReactionDeleted({
                fileID,
                userUUID: userID,
            }),
        )
        dispatch(FilesActions.ReactionChangesSubmitSuccessfull(fileID))
    } catch (e) {
        dispatch(FilesActions.ReactionChangesSubmitError(fileID))
    }
}

export const setAlbumSortOrder = async (
    dispatch: Dispatch,
    albumID: JobID,
    newSortOrder: AlbumSortOrder,
) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.setAlbumAttribute(albumID, 'sort_order', newSortOrder)
        dispatch(AlbumSortOrderSaved())
    } catch (error) {
        dispatch(AlbumSortOrderSaveFailed())
    }
}

// TODO: option endpoint is deprecated, migrate albumViewMode to user attributes
export const getUserDefinedViewMode = async (
    dispatch: Dispatch,
    albumID: JobID,
) => {
    const service = await getServiceProvider().getAppServiceForJob(albumID)
    const resp = await service.getUserOption(`albumViewMode_${albumID}`)
    if (resp === 'grid' || resp === 'flow') {
        dispatch(AlbumViewModeFetched({ jobID: albumID, viewMode: resp }))
    }
}

export const storeUserDefinedViewMode = async (
    albumID: JobID,
    viewMode: AlbumViewMode | null,
) => {
    const service = await getServiceProvider().getAppServiceForJob(albumID)
    if (viewMode === null) {
        service.deleteUserOption(`albumViewMode_${albumID}`)
    } else {
        service.setUserOption(`albumViewMode_${albumID}`, viewMode)
    }
}
