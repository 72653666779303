import type { Reaction } from '~/@types/backend-types'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const FileCommentSubmitted = createActionCreator<{
    fileID: FileID
    comment: string
}>('FILE_COMMENT_SUBMITTED') // When the user submits
export const FileCommentSubmitSuccessful = createActionCreator<FileID>(
    'FILE_COMMENT_WAS_SUBMITTED_SUCCESSFULLY',
) // When the server returns OK
export const FileCommentError =
    createActionCreator<FileID>('FILE_COMMENT_ERROR') // When the server returns an error
export const FileCommentAborted = createActionCreator<FileID>(
    'FILE_COMMENT_ABORTED',
) // ie when the user refuses to enter a name

export const VideoTranscodeStarted = createActionCreator<FileID>(
    'VIDEO_TRANSCODE_STARTED',
)
export const VideoTranscodeReady = createActionCreator<FileID>(
    'VIDEO_TRANSCODE_READY',
)
export const VideoTranscodeQueued = createActionCreator<FileID>(
    'VIDEO_TRANSCODE_QUEUED',
)
export const VideoTranscodeError = createActionCreator<FileID>(
    'VIDEO_TRANSCODE_ERROR',
)

export type FileDimensions = {
    fileID: FileID
    width: number
    height: number
}
export const FileDimensionsDiscovered = createActionCreator<FileDimensions[]>(
    'FILE_DIMENSIONS_DISCOVERED',
)

interface FileWasCommentedPayload {
    fileID: FileID
    comment: string
    commentUUID: CommentID
    timestamp: number
    userUUID: string
}
export const FileWasCommented =
    createActionCreator<FileWasCommentedPayload>('FILE_WAS_COMMENTED')

export const IgnoredFileEvent = createVoidActionCreator('IGNORED_FILE_EVENT') // Backend provides actions we do not recognize or have not yet implemented. Use this as a dummy event

export const DeleteCommentClicked = createActionCreator<{
    commentID: CommentID
}>('DELETE_COMMENT_CLICKED')
export const DeleteCommentCanceled = createActionCreator<{
    commentID: CommentID
}>('DELETE_COMMENT_CANCELED')
export const CommentDeletionStarted = createActionCreator<{
    commentID: CommentID
}>('COMMENT_IS_BEING_DELETED')
export const CommentWasDeleted = createActionCreator<CommentID>(
    'COMMENT_WAS_DELETED',
)
export const DeleteCommentFailed = createActionCreator<{
    commentID: CommentID
}>('DELETE_COMMENT_FAILED')

export const EditCommentClicked = createActionCreator<{ commentID: CommentID }>(
    'EDIT_COMMENT_CLICKED',
)
export const EditCommentCanceled = createActionCreator<{
    commentID: CommentID
}>('EDIT_COMMENT_CANCELED')
export const EditedCommentSubmitted = createActionCreator<{
    commentID: CommentID
    text: string
}>('EDITED_COMMENT_SUBMITTED')
export const EditCommentSuccessful = createActionCreator<{
    commentID: CommentID
    timestamp: number
}>('COMMENT_WAS_EDITED')
export const EditCommentFailed = createActionCreator<{ commentID: CommentID }>(
    'EDIT_COMMENT_FAILED',
)

export const FileDeletionStarted = createActionCreator<FileID>(
    'FILE_DELETION_STARTED',
)
export const FileDeletionCancelled = createActionCreator<FileID>(
    'FILE_DELETION_CANCELED',
)
export const FileDeletionFailed = createActionCreator<FileID>(
    'FILE_DELETION_FAILED',
)
export const DeleteFailedMessageDismissed = createActionCreator<FileID>(
    'FILE_DELETION_MESSAGE_DISMISSED',
)

export const FilesCopiedToTimeline = createActionCreator<FileID[]>(
    'FILES_COPIED_TO_TIMELINE',
)
type CopyFilesFailedReason = 'out_of_storage' | 'unknown'
export const FilesCopiedToTimelineFailed = createActionCreator<{
    reason: CopyFilesFailedReason
}>('FILES_COPIED_TO_TIMELINE_FAILED')

export const FilesWereShared = createVoidActionCreator('FILES_WERE_SHARED')

type ReactionChangesPaylod = {
    fileID: FileID
    reaction: Reaction
}
export const ReactionChangesSubmitted =
    createActionCreator<ReactionChangesPaylod>('REACTION_CHANGES_SUBMITTED')
export const ReactionChangesSubmitSuccessfull = createActionCreator<FileID>(
    'REACTION_CHANGES_SUBMIT_SUCCESSFULLY',
)
export const ReactionChangesSubmitError = createActionCreator<FileID>(
    'REACTION_CHANGES_SUBMIT_ERROR',
)

export const JobFilesWereExpired = createActionCreator<JobID[]>(
    'JOB_FILES_WERE_EXPIRED',
)
