import { _ } from '@capture/client/assets/localization/util'
import * as React from 'react'
import styled from 'styled-components'
import { Toggle, Typography } from '@capture/capture-components'

const ConsentToggleWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

type ConsentToggleProps = {
    title: string
    description: string
} & React.ComponentProps<typeof Toggle>
export const ConsentToggle = ({
    title,
    description,
    ...toogleProps
}: ConsentToggleProps) => (
    <ConsentToggleWrapper>
        <Toggle {...toogleProps}>
            <Typography.Bold>{title}</Typography.Bold>
        </Toggle>
        <Typography.Paragraph>{description}</Typography.Paragraph>
    </ConsentToggleWrapper>
)
