export const strings = {
    // General
    of: 'на',
    ok: 'ОК',
    yes: 'Да',
    got_it: 'Разбрах',
    see_more: 'Вижте повече',
    see_album: 'Вижте албума',
    read_more: 'Прочетете повече',
    skip: 'Пропусни',
    no: 'Не',
    done: 'Готово',
    reactivate: 'Активирай отново',
    subscribe: 'Абонирайте се',
    unsubscribe: 'Отписване',
    delete_: 'Изтрий',
    delete_all: 'Изтрийте всичко',
    download: 'Изтеглете',
    export: 'Експорт',
    edit: 'Редакция',
    copy: 'Копирай',
    update: 'Актуализация',
    cancel: 'Отказ',
    share: 'Споделяйте',
    undo: 'Назад',
    back: 'Назад',
    go_back: '< Назад',
    select_all: 'Избери всички',
    restore: 'Възстанови',
    restore_all: 'Възстанови всичко',
    filename: 'име на файла',
    file_type: 'вид на файла',
    deleted: 'изтрит',
    unknown: 'неизвестен',
    retry: 'Опитай отново',
    create_account: 'Създайте профил',
    sign_in: 'Вход',
    only_accessible_to_logged_in_users:
        'Тази функция е достъпна само за вписани потребители',
    loading: 'Зареждане',
    login_again_for_security:
        'За целите на сигурността моля впишете се отново за достъп до Вашите настройки за поверителност',
    logged_out_prompt: 'Вие излязохте. За да продължите, моля влезте',
    capture_save_see_share:
        'Запазвайте, разглеждайте и споделяйте всичките си снимки и клипове.',
    feature_only_available_in_app:
        'Тази функция в момента е налице само в приложението Capture',
    open_capture: 'Отворете Capture',
    open_in_app: 'Отвори в приложението',
    storage: 'Пространство',
    active: 'Активни',
    save: 'Запазвайте',
    // for buttons
    details: 'Подробности',
    accept: 'Приемам',
    deny: 'Отхвърли',
    num_of_total__format: '%num% от %total%',
    autoplay: 'автоматично възпроизвеждане',
    something_went_wrong: 'Ами сега! Нещо се обърка.',
    cookies: 'Бисквитки',
    invalid_field: 'Стойноста не е валидна',
    mandatory_field: 'Полето е задължително',
    movies: 'Клипове',
    recents: 'Скорошни',
    here: 'тук',
    screenshots: 'Снимки на екрана',
    item: 'елемент',
    items: 'елемента',

    // media types
    photos: 'Снимки',
    image: 'Снимка',
    livephoto: 'Жива снимка',
    movie: 'Видео',
    file: 'файл',
    files: 'файла',

    // Time
    january: 'януари',
    february: 'февруари',
    march: 'март',
    april: 'април',
    may: 'май',
    june: 'юни',
    july: 'юли',
    august: 'август',
    september: 'септември',
    october: 'октомври',
    november: 'ноември',
    december: 'декември',
    day: 'Ден',
    month: 'Месец',
    year: 'Година',
    monthly: 'месечно',
    yearly: 'ежегодно',
    jan: 'ян.',
    feb: 'февр.',
    mar: 'март',
    apr: 'апр.',
    jun: 'юни',
    jul: 'юли',
    aug: 'авг.',
    sep: 'септ.',
    oct: 'окт.',
    nov: 'ноем.',
    dec: 'дек.',
    num_days__format: '%d дни',
    one_day: '1 ден',
    // User Action Prompts & Alerts
    delete_files_prompt__format: `%d файла ще бъдат преместени в папка Изтрити елементи. Тези файлове ще бъдат изтрити завинаги след 30 дни.`,
    delete_single_file_prompt: `Файлът ще бъде преместен в папка Изтрити елементи. Файлът ще бъде изтрит завинаги след 30 дни.`,
    delete_file_failed_message__format:
        'При изтриването на "%s" възникна грешка. Моля, опитайте отново.',
    // --- album prompts ---
    delete_album_file_prompt_text:
        'Файлът ще бъде премахнат от албума. Всички коментари и харесвания, свързани с този файл, също ще бъдат изтрити.',
    delete_last_file_prompt_text:
        'Файлът ще бъде премахнат от албума. Премахването на последния файл в този албум ще изтрие и албума.',
    unsubscribe_album_prompt_text:
        'Вие вече няма да виждате този албум в списъка с албумите, нито ще получавате актуализации за него.',
    delete_album_prompt_text:
        'Албумът ще бъде изтрит и премахнат от списъка с албумите.',
    delete_comment_prompt_text: 'Коментарът ще бъде изтрит.',
    copy_files_to_timeline:
        'Файловете от албума ще бъдат копирани в снимките Ви.',
    // --- settings page prompts ---
    remove_device_prompt__format:
        'Вие ще бъдете отписани от %s на това устройство.',
    cancel_storage_plan_prompt__format:
        'Абонаментът ще приключи на %expired_date%. След приключване на абонамента общото хранилище ще бъде сведено до %storage_amount%.',
    reactivate_storage_plan_prompt__format:
        'Абонаментът ще продължи сегашния %period% период на таксуване.',
    cancel_plan: 'Отменете Абонамента',
    keep_plan: 'Запазете Абонамента',
    change_storage_plan_period_prompt:
        'Потвърдете промяна в текущия абонамент на периода на фактуриране. Новият период на фактуриране ще започне незабавно',
    // replace with plan_upgrade when charge amount is available
    upgrade_storage_plan_size_prompt__format:
        'Потвърдете промяната във Вашия текущ абонамент от %current_size% към %new_size%. Промяната ще влезе в сила веднага.',
    // remove once getting charge amount is possible
    upgrade_storage_plan_prompt__format:
        'Абонаментът ще бъде увеличен до %plan_price%/%period% (%size%). Ще бъдете таксувани с %amount%, като периодът на таксуване ще започне незабавно.',
    insufficient_plan_selected: 'Избран е недостатъчен абонамент',
    insufficient_plan_explanation__format:
        'Общото Ви пространство за съхранение след закупуване на този абонамент (%max_storage%) е по-малко от това, което използвате в момента (%used_space%). Моля, изберете по-голям абонамент или намалете използваното пространство.',
    downgrade_not_available:
        'В момента нямате възможност да понижите текущия Ви абонамент.',
    downgrade_active_plan_alert:
        'Трябва да отмените текущия си абонамент и да изчакате да приключи, преди да можете да изберете този абонамент.',
    downgrade_canceled_plan_alert:
        'Трябва да изчакате приключването на текущия Ви абонамент, преди да можете да изберете този абонамент.',
    // --- terms of service prompts ---
    accept_tos__header: 'Приеми Условията за ползване',
    accept_tos__we_have_made_changes__format:
        'Направихме промени в нашите %link_start%Условия за ползване%link_end%.',
    accept_tos__please_read_and_accept:
        'С използването на услугата Вие приемате %terms_of_service%. Моля, прочетете ги внимателно.',
    decline_tos: 'Отказ и отписване',
    // --- trash and document prompts ---
    delete_single_document_file: 'Файлът ще бъде изтрит завинаги.',
    delete_document_files__format: '%d файла ще бъдат изтрити завинаги.',
    restore_single_file_prompt: 'Файлът ще бъде възстановен в снимките Ви.',
    restore_files_prompt__format:
        '%d файла ще бъдат възстановени в снимките Ви.',
    permanently_delete_single_file_prompt:
        'Файлът ще бъде изтрит завинаги. Това действие не може да бъде отменено.',
    permanently_delete_files_prompt__format:
        '%d файла ще бъдат изтрити завинаги. Това действие не може да бъде отменено.',
    // Navigation
    timeline: 'Снимки',
    albums: 'Албуми',
    add_files: 'Добавяне на файлове',
    documents: 'Документи',
    settings: 'Настройки',
    telenor_ID: 'Telenor ID',
    account: 'Профил',
    help: 'Помощ',
    help_support: 'Помощ и поддръжка',
    deleted_items: 'Изтрити',
    login: 'Вписване',
    log_out: 'Изход',
    close: 'Затваряне',
    timeline_desc: 'Всичките Ви снимки и клипове, организирани по дати.',
    albums_desc: 'Добавяй снимки в албум и споделяйте с приятели!',
    search_albums: 'Търсене на албум',
    no_album_results:
        'Няма резултати за "%link_start%%search_string%%link_end%". Опитайте отново за друг албум.',
    // Drag and drop
    drag_drop: 'Провлачете и пуснете ',
    drag_drop_files__format: 'Влачете и пуснете Вашите файлове %split%или',
    drop_your_files__format:
        'Пуснете Вашите снимки за да ги качите веднага на %product_name%!',
    dropZone_text__timeline:
        'файлове за качване или папка за създаване на нов албум',
    dropZone_text__albums: 'файлове или папки за създаване на нови албуми',
    dropZone_text__singleAlbum: 'файлове отвсякъде за качване в %album_name%',
    // click_here_to_select: 'or click here to select from you computer',

    // UploaderBox
    file_s: 'файл(ове)',
    uploaded: 'качени',
    did_not_upload: 'неуспешно качени',
    upload_folder_reject: 'Не може да се качват папки',
    upload_folder_safari_reject: 'Safari не поддържа качването на папки',
    unsupported_file_reject: 'Неподдържан файл',
    was_not_uploaded: 'Не беше качен',
    too_large_upload: 'Файлът е прекалено голям за качване (максимум 200 GB)',
    see_which: 'Вижте кои',
    view_the_files: 'Преглед на файловете',
    // show_files: 'Show files',
    // show_photos: 'Show photos',
    offline: 'Няма връзка с интернет',
    upload: 'Качване',
    uploading: 'Качване...',
    // pause: 'Pause',
    // resume: 'Resume',
    retrying_in: 'Повторен опит след',
    sec: 'сек.',
    retrying_now: 'Протича повторен опит...',
    try_again: 'Опитайте отново',
    stop: 'Стоп',
    processing: 'Обработване',
    processing_with_dots: 'Обработка…',
    complete: 'Завършено',
    cancelled: 'Отменено',
    // unknown_format: 'Unknown format',
    preparing: 'Подготвяне...',
    waiting_for_internet_connection: 'Изчакване на интернет връзка...',
    out_of_storage: 'Недостатъчно пространство',
    free_up_space: 'Изтрийте файлове, за да възобновите качването',
    cancelling_upload_are_you_sure:
        'Сигурен/сигурна ли сте, че искате да спрете качването?',
    processing_video: 'Видеото се обработва',
    // upload options modal
    select_from_device: 'Качете снимки от устройство',
    add_from_capture__format: 'Добавете от %product_name%',
    // Popup
    reached_given_storage_ratio: 'Пространството се изчерпва',
    reached_given_storage_ratio_subText:
        'Вие имате използвани %used_storage%GB от %max_storage%GB. Освободете пространство като изтриете снимки или видеа или закупите повече пространство в Настройки.',
    not_enough_storage_for_add: 'Недостатъчно пространство',
    not_enough_storage_for_add_subText:
        'Нямате достатъчно пространство за всички файлове, които се опитвате да добавите. Освободете място, като изтриете снимки или видео клипове или купете още пространство от меню Настройки.',
    not_enough_storage_for_restore: 'Недостатъчно пространство',
    not_enough_storage_for_restore_subText:
        'Нямате достатъчно пространство за всички файлове, които се опитвате да възстановите. Освободете място като изтриете снимки или видео клипове или купете още пространство от меню Настройки.',
    // Popover
    popover__profile_picture_heading:
        'Искаш ли да смениш профилната си снимка?',
    popover__profile_picture_body:
        'Можеш да смениш профилната си снимка в настройки, така че всеки да знае, че това си ти!',
    // T I M E L I N E
    // subscription required
    timeline_subscription_required_header:
        'Изисква се абонамент в Capture за качване на снимки.',
    timeline_subscription_required_sub:
        'Вижте нашите абонаментни планове и започнете безплатния си пробен период днес!',
    timeline_subscription_required_cta: 'Вижте абонаментите',
    // emtpy state
    timeline_is_empty: 'Нямате съхранени снимки тук.',
    timeline_filtered_photos_empty: 'Нямате никакви снимки.',
    timeline_filtered_recents_empty: 'Нямате наскоро прегледани.',
    start_uploading_photos_now: 'Защо не започнете да качвате снимки още сега?',
    start_uploading_now: 'Защо не започнете да качвате снимки още сега?',
    drag_drop_photos_to_upload:
        'Можете да влачите и пускате снимките си тук или да натиснете бутона по-долу.',
    download_app_to_upload_directly_from_device:
        'Инсталирайте приложението Capture на Вашия мобилен телефон, за да качвате снимки и клипове директно от камерата или галерията със снимки на Вашето устройство',
    timeline_empty_screenshot_filter:
        'Нямате никакви скрийншоти. Браво на Вас!',
    // TODO: verify text
    timeline_filtered_videos_empty: 'Нямате никакви видео клипове.',
    drag_drop_files_to_upload:
        'Можете да влачите и пускате Вашите файлове тук или да натиснете бутона по-долу.',
    add_videos: 'Добавете видео клипове',
    // select from timeline empty
    upload_photos_add_album: 'Качете снимки, за да ги добавите към албума си.',
    // timeline page
    selected__format: '%d избрани',
    none_selected: 'Няма избрани елементи',
    select_files: 'Изберете файлове',
    file_count_exceeds_download_limit:
        'Не можете да теглите повече от 500 обекти едновременно. Моля, изберете по-малко обекти.',
    size_exceeds_download_limit:
        'Не можете да изтегляте повече от 3,5 GB наведнъж. Моля, променете избора си.',
    fetching_file_selection_info:
        'Извличане на файловата информация за извършване на избор',
    shift_select_hint_header:
        'Искате бързо да изберете много снимки едновременно?',
    shift_select_hint_body:
        'Задръжте shift (⇧) и кликнете за избор на няколко елемента едновременно.',
    fastscroller_hint_body: 'Отидете директно на месец като щракнете тук',
    fastscroller_select_hint_body:
        'Изберете лесно цял месец или година като щракнете тук',
    filter_by_videos: 'Клипове',
    filter_by_images: 'Снимки',
    filter_by_recents: 'наскоро добавени',
    filtered_by_videos: 'Филтриране по видеа',
    filtered_by_images: 'Филтриране по снимки',
    filtered_by_recents: 'Филтриране по прегледани наскоро',
    show_all: 'Покажи всички',
    download_heic: 'Изтегли HEIC',
    download_heic_desc_single: 'Оригинален файл. Съвместим с iOS и macOS',
    download_heic_desc_multi:
        'файловете както са били качени. Съвместими с iOS и macOS',
    download_jpeg: 'Изтегли JPEG',
    download_jpeg_desc: 'Съвместим с повечето устройства. Препоръчва се',
    download_original: 'Изтеглете оригиналния',
    download_original_desc: 'файловете, както са качени',
    download_converted: 'Конвертирайте и изтеглете',
    download_converted_desc: 'с формат, съвместим с повечето устройства',
    show: 'Покажи',
    added_today: 'Добавени днес',
    added_days__format: 'Добавени последните %d дни',
    // paginated download
    file__format: '%d файл',
    files__format: '%d файла',
    about_to_download_files__format: 'Вие ще свалите %file_count_size%',
    about_to_download_paginated_description:
        'Свалянето на много файлове едновременно може да отнеме време. Уверете се, че Вашата мрежова връзка е стабилна и че Вашето устройство е свързано към източник не електричество.',
    download_all_at_once: 'Свали всички наведнъж',
    download_your_photos_and_videos: 'Сваляне на снимките и клиповете Ви',
    download_in_smaller_parts: 'Свали на малки части',
    download_page__format: 'Изтеглете част %part% (%file_count%)',
    download_paginated_parts_below:
        'Моля свалете всяка от по-малките части по-долу.',
    download_paginated__format:
        'Изборът Ви на %file_count% %file_count_size% беше разделен на по-малки части за по-лесно сваляне. Кликнете на всеки zip файл по-долу, за да го свалите.',
    download_pagination_convenience:
        'За удобство можете да минимизирате този прозорец и да продължите да ползвате %product_name% докато файловете се свалят.',
    download_files: 'Сваляне на %file_count% (%file_count_size%)',
    close_window: 'Затвори прозореца',
    // close to quota - text ribbon
    close_to_quota__format: '%used_storage% GB използвани от %max_storage% GB',
    // A L B U M S
    album_name: 'Име на албума',
    album: 'албум',
    unnamed_album_placeholder: 'Неозаглавен албум',
    album_settings: 'Настройки на албума',
    add_to_albums: 'Добави към Моите албуми',
    subscribe_to_album: 'Ще се абонирате ли за албум',
    item__format: '%d обект',
    items__format: '%d обекти',
    participant__format: '%d участник',
    participants__format: '%d участници',
    participants: 'Участници',
    owner: 'Собственик',
    you_are_participating: 'Вие участвате',
    by: 'от',
    create_album: 'Създайте албум',
    auto_created_album_title: 'Неозаглавен албум',
    new_album: 'Нов албум',
    filter_by: 'Филтриране по',
    sort_by: 'Сортиране по',
    filter_albums: 'Филтрирайте албумите',
    filtered_by_private: 'Филтрирани като лични',
    filtered_by_shared: 'Филтрирани като споделени',
    sort_recent_activity: 'Скорошна активност',
    sort_newest_created: 'Най-нови',
    sort_oldest_created: 'Най-стари',
    sort_name: 'Име на албума',
    all_albums: 'Всички албуми',
    shared_albums: 'Споделени албуми',
    private_albums: 'Лични албуми',
    download_album: 'Изтеглете албум',
    add_photos: 'Добавете снимки',
    add_to_album: 'Добавете към албума',
    you_and_others_love_message_format: 'Вие и %d други харесвате това',
    you_and_one_love_message: 'Вие и 1 друг харесвате това',
    you_love_message: 'Вие харесвате това',
    one_person_love_message: '1 човек харесва това',
    people_love_message_format: '%d харесва това',
    leave_album: 'Излезте от албум',
    delete_album: 'Изтрийте албум',
    delete_file: 'Изтрий файла',
    download_file: 'Изтеглете файла',
    download_files_failed_message:
        'Възникна грешка при свалянето. Моля, опитайте отново.',
    set_as_cover_photo: 'Задай като снимка на корицата',
    add_file_to_timeline: `Добавете в "Снимки"`,
    add_album_to_timeline: `Добавете албума в "Снимки"`,
    change_album_viewMode: 'Променете изгледа',
    sort_album_files: 'Подредете албума',
    sort_album_files_title: 'Подредете албума по',
    sort_album_files_by_added: 'наскоро добавени',
    sort_album_files_by_newest_first: 'Първо най-новите',
    sort_album_files_by_oldest_first: 'Първо най-старите',
    album_file_count_exceeds_download_limit:
        'Не можете да изтегляте албум с повече от 500 елемента.',
    album_size_exceeds_download_limit:
        'Не можете да изтегляте повече от 3,5 GB наведнъж.',
    // empty album list state
    album_list_is_empty: 'Все още нямате никакви албуми.',
    empty_albumList_secondTitle: 'Защо не създадете такъв още сега?',
    empty_filtered_private_albumList_title: 'Нямате никакви лични албуми.',
    empty_filtered_shared_albumList_title: 'Нямате никакви споделени албуми.',
    empty_filtered_albumList_subtext:
        'Създаването на албум е лесно! Натиснете бутона по-долу, за да започнете.',
    empty_album_list_read_only_header:
        'Изисква се абонамент в Capture за създаване на албуми.',
    // empty album state
    empty_album_photos: 'В този албум все още няма снимки',
    // add to album
    album_select: 'Изберете албум',
    // create album
    create_new: 'Създайте нов',
    private_album: 'Лични албуми',
    shared_album: 'Споделени албуми',
    share_created_album_prompt_header: 'Вие създадохте албум!',
    share_created_album_prompt_text:
        'Споделете го с приятелите и семейството си, за да могат и те да добавят снимки и коментари към Вашия албум.',
    share_later: 'Споделете по-късно',
    share_now: 'Споделете сега',
    name_your_album: 'Кръстете Вашия албум',
    add_photos_to_album: 'Добавете снимки към албума',
    create_empty_album:
        'Създаване на празен албум сега и добавяне на снимки по-късно',
    save_create_album: 'Запазете и създайте албум',
    set_name_for_shared_album:
        'Потребителското име е нужно на Споделения Албум, за да знаят Вашите приятели кой сте.',
    album_name_is_required_for_shared_albums:
        'Името на албума е нужно за споделените албуми',
    // Uploading to album
    also_upload_to_timeline: 'Качете във Вашите снимки?',
    uploading_to_album_name__format: 'Вие качвате в "%album_name%"',
    uploading_to_album_also_to_timeline__format:
        'Да бъдат ли качени и тези файлове във Вашите %product_name%-photos?',
    uploading_files_to_new_album: 'Качвате файлове в нов албум.',
    uploading_files_to_multiple_albums__format:
        'Качвате файлове в %album_count% нови албума.',
    // Uploading folder to albums
    create_albums_prompt: 'Създаване на албуми от папки?',
    create_album_prompt: 'Създаване на албум?',
    also_create_albums__format:
        'Качвате папка на име “%album_name%”. Да създадем ли и албум с това съдържание?',
    also_create_albums_multiple__format:
        'Качвате %d папки. Да създадем ли и албуми с тях?',
    // misc album
    fetching_albums: 'Извличане на албуми',
    // Comments
    comment: 'коментар',
    comments: 'Коментари',
    write_your_comment: 'Напишете коментара си',
    comment_error_message:
        'Грешка при публикуването на коментара. Моля, опитайте отново.',
    more: 'Повече',
    edited: 'Редактирано',
    edit_comment_error: 'Коментарът не може да бъде редактиран',
    delete_comment_error: 'Коментарът не може да бъде изтрит',
    view_all_comments: 'Вижте всички коментари',
    what_is_your_name: 'Как се казвате?',
    how_would_you_continue: 'Как би предпочел да продължиш?',
    hey: 'Хей',
    tap_here_to_enter_name: 'Натиснете тук, за да въведете име',
    click_here_to_enter_name: 'Кликнете тук, за да въведете име',
    enter: 'въведете',
    register_or_sign_in: 'Регистрация / Вписване',
    continue_as_guest: 'Продължи като гост',
    provide_name_description__format:
        'Нека и другите в албума знаят кой/коя сте, като продължите с %product_name% профила.',
    year_ago__format: 'преди %d година',
    years_ago__format: 'преди %d години',
    month_ago__format: 'преди %d месец',
    months_ago__format: 'преди %d месеца',
    day_ago__format: 'преди %d ден',
    days_ago__format: 'преди %d дни',
    hour_ago__format: 'преди %d час',
    hours_ago__format: 'преди %d часа',
    minute_ago__format: 'преди %d минута',
    minutes_ago__format: 'преди %d минути',
    just_now: 'в момента',
    post: 'Публикуване',

    // C A R O U S E L   V I E W
    slideshow: 'Слайдшоу',
    play: 'Пусни',
    pause: 'Пауза',
    previous: 'Предишна',
    next: 'Следваща',
    exit_fullscreen: 'Изход',
    info: 'Информация',

    // F I L E  I N F O
    file_info: 'Информация за файла',

    // S E T T I N G S
    // profile
    profile_info: 'Информация за профила',
    enter_your_name: 'Въведете името си',
    set_your_name: 'Въведете името си',
    update_profile_picture: 'Актуализиране на профилната снимка',
    // storage
    other_sources: 'други източници',
    current_plan: 'настоящ абонамент',
    storage_management: 'Управление на пространството',
    manage_storage: 'Управление на пространството',
    total_storage: 'Общо пространство',
    remaining_storage: 'Оставащо',
    used_storage: 'Използвано',
    buy_more_storage: 'Допълнително пространство',
    buy_more_storage_install_app:
        'На вашето местоположение е единствено възможно да закупите още пространство в мобилното приложение Capture. Моля свалете приложението, отидете в Настройки и докоснете “Купете още пространство”.',
    buy_more_storage_open_app:
        'На вашето местоположение е единствено възможно да закупите още пространство в мобилното приложение Capture.',
    connected_devices: 'Включени устройства',
    unlimited: 'Неограничено',
    your_storage_plan: 'Вашият Абонамент',
    next_billing_date__format: 'Следваща дата за таксуване: %date%',
    expires_date__format: 'Абонаментът е отменен. Хранилището изтича на %date%',
    plan_is_canceled: 'Вие отменихте този абонамент',
    has_credit:
        'Вие имате кредит от %credit_amount% в %product_name%. Той ще бъде приложен автоматично при бъдещи плащания.',
    manage_plan: 'Управлявай',
    storage_used_out_of__format: '%storage_used% използвано от %storage_total%',
    upgrade_storage_plan: 'Надграждане на абонамент',
    cancel_storage_plan: 'Отказ от абонамент',
    renew_status__format: 'Абонаментът ще бъде подновен на %s',
    expired_status__format: 'Абонаментът изтича на %s',
    reactivate_storage_plan: 'Реактивация на Абонамент',
    choose_subscription: 'Изберете абонамент',
    subscribe_to_product: 'Абонирайте се за %product_name%',
    // storage sources
    storage_source_capture: 'Предоставено от %product_name%',
    storage_source_b2b_customer__format: 'Само за клиенти на %BU_name%',
    storage_source_web: 'Закупено в мрежата',
    storage_source_apple_store: 'Покупка в приложение iTunes',
    storage_source_googlePlay_store: 'Покупка в приложение Google Play Магазин',
    storage_source_connect_store: 'Закупено от стария уеб магазин',
    storage_source_other: 'Други източници',
    storage_source_customer_service:
        'Предоставено от отдел Обслужване на клиенти',
    storage_source_b2b__format: 'Предоставено от %BU_name%',
    free__as_in_no_cost: 'Безплатно',
    details_unlimited_storage: 'Имате неограничено хранилище!',
    details_text_unlimited_storage_1__format:
        'Като клиент на %BU_name%, Вие имате право на неограничено място за съхранение на %product_name% безплатно, така че никога не трябва да се безпокоите, че Ви свършва мястото!',
    details_text_unlimited_storage_2__format:
        'Интересувате се от цени за съхранение за хора, които не са клиенти на %BU_name%? Вижте по-долу:',
    // payment settings
    payment_settings: 'Настройки на плащането',
    payment_settings_info_text: 'Информация за Вашата регистрирана карта:',
    change_card_info: 'Променете информацията за карта',
    delete_card: 'Изтрий карта',
    update_card_info: 'Обновете данните за карта',
    card_number: 'Номер на карта',
    card_exp_date: 'Дата на валидност',
    card_cvc: 'CVC/CVV',
    confirm_payment: 'Потвърдете плащането',
    // Buy more storage
    save__format: 'Спестете %d',
    get_started: 'Започнете!',
    telenor_business_discount_hook_header: 'Telenor Business клиент?',
    telenor_business_discount_hook:
        'Ако сте Telenor Business клиент Вие имате право на цени с отстъпка.',
    telenor_business_discount_hook_link: 'Отиди на %telenor_mine_sider%.',
    telenor_mine_sider: 'Моите страници на Теленор',
    mine_sider: 'Моите страници',
    telenor_unlimited_hook__format:
        'Клиентите на Telenor Mobile имат достъп до неограничено пространство в %product_name%',
    telenor_unlimited_hook_subtext:
        'Норвегия: Предоставя се на всички частни абонаменти, с изключение на U11, и на Frihet/Flyt/Fri+ бизнес абонаменти.',
    monthly_sub: 'Месечни планове',
    yearly_sub: 'Годишни планове',
    email_request_explanation:
        'Имейл адресът ще бъде използван за известия относно абонамента Ви',
    fetch_storage_plan_failed: 'Не можем надеждно да извлечем Вашия абонамент',
    with_free_trial_detail:
        '%num_free_trial% месеца безплатно изпробване, %price_string% на %period% след това',
    downgrade_refund_detail:
        'Вие ще получите възстановена сума с кредит в %product_name%, който ще бъде приложен при бъдещо плащане.',
    without_free_trial_detail: 'Ще платите %price_string% на %period%',
    choose_subscription_plan_first: 'Първо изберете абонамент',
    choose_subscription_plan_to_see_details:
        'Изберете една от опциите за да видите подробностите',
    choose_subscription_plan_to_start_free_trial:
        'Изберете един от плановете за плащане горе, за да започнете безплатния пробен период',
    change_subscription_plan: 'Промяна на абонамент',
    subscribe_with_free_trial: 'Получете %num_free_trial% месеца безплатно',
    proceed_to_pay: 'Напред към плащането',
    select_plan_to_see_details: 'Изберете план за да видите подробности.',
    subscribe_to_receive: 'Изберете абонамент, за да започнете и да получите',
    months_free_trial: '%num_free_trial% месеца безплатен пробен период',
    learn_more: 'Научете повече',
    subscription_notes1:
        'Планове, които са по-малки от текущата Ви употреба, са недостъпни',
    // Update popup
    update_pop_header:
        'Направихме актуализация за сигурността в приложението %product_name%.',
    update_pop_body:
        'Моля уверете се, че сте надградили до последната версия на приложението (%version_number%) в App Store и Play Store на Вашия телефон',
    // select language
    language: 'Език',
    select_language: 'Изберете език',
    // privacy
    privacy_info:
        'Прегледайте данните си и контролирайте настройките си от едно място.',
    privacy: 'Поверителност',
    privacy_statement: 'Декларация за поверителност',
    open_privacy_pages: 'Отворете страниците за поверителност',
    consents: 'Съгласия',
    facial_recognition: 'Лицево разпознаване',
    privacy_desc__can_process_images_categorisation__format:
        '%company_name% може да обработи моите снимки за да предостави категоризация на съдържанието. Това включва групирането на подобни лица заедно.',
    face_grouping: 'Лицево групиране',
    privacy_desc__can_process_face_details__format:
        '%company_name% може да обработва лицевите детайли в снимките, за да предостави категоризация на съдържанието. Това включва групирането на подобни лица.',
    advanced_filtering_and_categorization:
        'Разширено филтриране и категоризация',
    privacy_desc__can_process_images_advanced_filtering__format:
        '%company_name% може да обработва съдържанието на снимките, за да предостави услуги като разширено филтириране и представяне на групи от снимки като спомени.',
    help_us_improve: 'Помогнете ни да станем по-добри',
    privacy_desc__can_process_data_for_improvement__format:
        '%company_name% може да обработва данни относно това как аз използвам техните услуги, за да подобрят техните продукти, например колко често използвам приложението.',
    stay_up_to_date: 'Бъдете в течение',
    privacy_desc__let_you_know_about_updates:
        'Искаме да Ви информираме относно актуализации, нови функции и страхотни нови продукти и услуги, които предлагаме. Също така ще Ви изпращаме съвети, за да подобрите своето изживяване, и други свързани предложения.',
    can_contact_me_via__format: '%company_name% може да се свърже с мен чрез:',
    push_notification: 'Известие',
    access_your_data: 'Достъп до данните Ви',
    privacy_desc__processes_with_privacy_policy__format:
        '%company_name% обработва определени категории с потребителска информация в съответствие с целите описани в нашата %link_start%Политика за поверителност%link_end%.',
    privacy_desc__download_personal_information__format:
        'Можете да свалите копие на личната информация, която съхраняваме за Вас, тук.',
    privacy_request_a_copy_of_data: 'Заявка за копие на Вашите лични данни',
    privacy_download_all:
        'Можете да свалите всички Ваши снимки, видеа и албуми от Capture на Вашия Mac/PC. Това е полезно когато искате да имате всички Ваши снимки на компютъра Ви, да ги качите на външен твърд диск или да ги архивирате в друга облачна услуга.',
    privacy_download_all_button: 'Свали всичко от Capture',
    per_gdpr_right_to_request:
        'Съгласно GDPR Вие имате правото да заявите копие на всички данни, които съхраняваме за Вас.',
    if_you_continue_metadata_download__format:
        'Ако продължите ние ще пакетираме всички метаданни и ще ги предоставим за сваляне. Можете да правите по една заявка на всеки %d часа.',
    metadata_recently_downloaded_please_wait__format:
        'Наскоро свалихте Вашите метаданни. Можете да правите по една заявка на всеки %d часа.',
    you_can_request_delete_account__format:
        'Можете да заявите на %company_name% изтриване на Вашия профил, включително на цялото съдържание и данни, които съхраняваме за Вас.',
    delete_account_includes_irreversible_deletion:
        'Това включва необратимо изтриване на всички снимки и видеа от Вашия Capture профил, така че се убедете, че сте направили архив преди да направите тази заявка.',
    it_may_take_up_to_days__format:
        'Може да ни отнеме до %d дни за да завършим тази заявка.',
    request_deletion: 'Заявка за изтриване',
    delete_account: 'Изтрий профила',
    delete_your_account: 'Изтриване на Вашия профил в Capture',
    delete_your_account__info1:
        'Можете да заявите на Telenor Software Lab AS изтриване на Вашия профил, включително на цялото съдържание и данни, които съхраняваме за Вас.',
    delete_your_account__info2:
        'Това включва необратимо изтриване на всички снимки и видеа от Вашия Capture профил, така че се убедете, че сте направили архив преди да направите тази заявка.',
    delete_your_account__info3:
        'Може да ни отнеме до 30 дни за да завършим тази заявка.',
    delete_your_account__info4:
        'Изтриването на Вашия профил в Capture ще изтрие окончателно всички Ваши снимки и клипове и всяка друга информация в профила Ви.',
    delete_your_account__info5:
        'Това е невъзвратим процес, така че моля уверете се, че имате друг архив на Вашите снимки и клипове преди да продължите.',
    delete_your_account__info6:
        'Когато натиснете “Изтрий профила” ще бъдете отписани от всички Ваши устройства.',
    delete_your_account__success1: 'Вашата заявка за изтриване е получена.',
    delete_your_account__success2__format:
        'Бяхте отписан(а) от всички устройства и ние ще обработим заявката Ви за изтриване в рамките на %d дни. Ще получите електронна поща веднага щом заявката е завършена.',
    delete_your_account__failed1:
        'Нещо се обърка при обработката на Вашата заявка за изтриване.',
    generic_explanation_for_why_it_may_failed:
        'Вашата връзка към Интернет може да е прекъсната или ние да имаме временен проблем с нашите сървъри.',
    // TAKEOUT PAGE
    takeout_file: 'файл',
    takeout_files: 'файла',
    capture_settings: 'Настройки на Capture',
    takeout_mobile_text:
        'Тази страница не е достъпна на мобилни устройства, моля използвайте Mac/PC.',
    takeout_download_all_title: 'Свали всички снимки, видеа и албуми',
    takeout_download_all_body: `Свалете всичките си снимки, видеа и албуми от
                        Capture на Вашия Mac/PC. Това е полезно когато искате да
                        имате всички Ваши файлове на компютъра Ви, да ги качите на
                        външен твърд диск или да ги архивирате в различна
                        облачна услуга. При въпроси относно сваляне посетете
                        страницата %support_url%. За повече информация относно нашите
                        абонаменти отидете на %capture_settings%.`,
    takeout_subheader_photos: 'Снимки в Capture',
    takeout_body_photos: `Свалете всичките си снимки и видеа, които са архивирани
                        във Вашия таб Снимки в Capture. Това ще включи всякакви
                        документи, които може да имате, но не включва албуми. Всички
                        снимки и видеа ще бъдат свалени в техните оригинални
                        формати. За повече информация относно форматите посетете нашата страница %support_url%.`,
    takeout_subheader_albums: 'Албуми в Capture',
    takeout_body_albums: `По-долу има списък с всички лични и споделени албуми във
                        Вашия таб Албуми в Capture. Щракнете върху тези, които бихте желали
                        да свалите.`,
    takeout_stats:
        'Вие имате %num_files файла (%total_size) във Вашите Снимки в Capture.',
    takeout_download_button: 'Свали всичко от снимките в Capture',
    // SUBSCRIBE MODAL
    subscribe_title: 'Абонирайте се за %product_name%',
    subscribe_welcome_text: 'Абонирайте се за %product_name% днес и получете ',
    subscribe_welcome_text2:
        'Можете да научите повече за %product_name% %faq_pages%.',
    subscribe_free: 'БЕЗПЛАТНО',
    subscribe_sub: 'ПЛАТЕНО',
    subscribe_text1: 'Качване на снимки и видеа',
    subscribe_text2: 'Организирайте снимките в албуми',
    subscribe_text3: 'Споделете снимки и албуми',
    subscribe_text4: 'Редактирайте снимки и видеа',
    subscribe_text5: 'Присъединете се към споделени албуми',
    subscribe_accept_tos: 'Приемам %terms_of_service% на Capture',
    subscribe_confirm: 'Изберете абонамент',
    subscribe_skip: 'Продължете без абонамент',
    subscribe_reconsider: 'Вижте абонаментните планове',
    subscribe_error: 'Вие трябва да приемете Условията за Използване',
    no_subscription_title: 'Без абонамент',
    no_subscription_limit:
        'Използването на Capture без абонамент ще Ви позволи само да участвате в албуми, които други са споделили с Вас.',
    no_subscription_reconsider:
        'Можете да се абонирате по-късно или да управлявате профила си от Настройки.',
    enter_payment_information: 'Въведете информация за плащането',
    business_users:
        '*Бизнес-клиентите на Теленор могат да получат намалени цени. Вижте %telenor_mine_sider%.',
    // UNSUBSCRIBE PAGE
    unsubscribe_from_email_form_title: 'Отписване от имейлите на Capture',
    unsubscribe_from_email_form_text1:
        'Ние изпращаме по един имейл на месец с полезни предложения, съвети и нови възможности.',
    unsubscribe_from_email_form_text2:
        'Сигурни ли сте, че искате да се отпишете от нашия пощенски списък?',
    unsubscribe_from_email_form_button_stay: 'Остани в списъка!',
    unsubscribe_from_email_form_button_unsubscribe: 'Отпиши ме',
    unsubscribe_from_email_success_title: 'Успешно отписване!',
    unsubscribe_from_email_success_text:
        'Може да промените избора си по всяко време в "Настройки за поверителност" в Capture.',
    unsubscribe_from_email_stay_title:
        'Вашите настройки за имейл в Capture бяха запазени!',
    unsubscribe_from_email_stay_text:
        'Може да промените избора си по всяко време в "Настройки за поверителност" в Capture.',
    // I N F O
    // Download app page
    thanks_for_signing_in: 'Благодарим Ви за регистрацията!',
    get_capture_to_create_album:
        'Инсталирайте Capture на мобилното си устройство, за да създавате свои собствени албуми.',
    return_to_album: 'Връщане в албума',
    // Footer
    telenor_capture_text:
        'Capture е разработено от Telenor Digital, компания на Telenor, която предоставя дигиталните решения на бъдещето. Профилът в Connect се използва за вход в услугите, предоставяни от Telenor Digital.',
    terms_of_service: 'Правила за ползване',
    customer_service: 'Обслужване на клиенти',
    capture_footer_text_mobile:
        'Вижте в приложението Capture за най-добро изживяване',
    open: 'отвори',
    legal: 'Правна информация',
    // Album Not Found
    album_not_found: 'Албумът не е намерен',
    album_not_found_description:
        'Съжаляваме! Не успяхме да открием този албум. Възможно е линкът да е изписан погрешно или албумът да е бил изтрит.',
    capture_create_user_description:
        'Capture е безопасна и лесна за ползване услуга за съхранение за снимки и други файлове онлайн. Ако все още не сте потребител на Capture, можете да се регистрирате.',
    capture_more_link: 'Научете повече за Capture ',
    // Share Not Found
    share_not_found: 'Споделянето не е открито',
    share_not_found_description:
        'Може би сте кликнали на изтекъл, изтрит или погрешно написан линк.',
    // Page Not Found
    page_not_found: 'Не можахме да намерим страницата, която търсите!',
    oops_page_not_found: 'Оппа! Страницата не е намерена.',
    helpful_links_description: 'Вместо това, ето някои полезни линкове',
    helpful_links_about_capture: 'За Capture',
    // Provide Password
    password_required: 'Необходима е парола',
    password_input_placeholder: 'Кликнете тук, за да въведете парола',
    password_required_submit: 'въведете',
    password_incorrect: 'Невалидна парола',
    album_password_required_description:
        'Подателят е защитил този албум с парола. Моля, въведете паролата в полето по-горе, за да отключите албума.',
    // Require login to perform action
    needs_login_subscribe_to_album:
        'Необходим Ви е %product_name% профил, за да получавате новини от този албум',
    needs_connect_to_create_album:
        'Необходим Ви е %product_name% профил с абонамент, за да създавате албуми',
    needs_login_to_copy_files_to_timeline:
        'Необходим Ви е %product_name% профил с абонамент, за да запазите тези файлове',
    needs_login_to_copy_album_to_timeline:
        'Необходим Ви е %product_name% профил с абонамент, за да запазите файловете от този албум',
    needs_login_to_view_share:
        'Трябва да сте вписани в %product_name%, за да видите това споделяне',
    // Edit/Create album page
    no_title_yet: 'Все още няма заглавие',
    add_album_title: 'Въведете заглавие на албума',
    // Suggest Modern Browser
    upgrade_your_browser: 'Моля, актуализирайте браузъра си',
    upgrade_your_browser_des:
        'Съжаляваме! Това съдържание не може да се гледа с текущата версия на Вашия браузър. Актуализирайте я или използвайте друг браузър като Chrome, Firefox или Microsoft Edge.',
    // Album settings
    share_album: 'Споделете албум',
    edit_album_settings: 'Редактирайте настройките на албума',
    enabled: 'Позволено',
    disabled: 'Непозволено',
    privacy_private: 'Лично',
    privacy_shared: 'Споделено',
    edit_album_title_text: 'Заглавие на албума (Натиснете, за да промените):',
    edit_allow_comments_text: 'Всеки може да вижда и да добавя коментари',
    edit_allow_upload_text: 'Всеки може да добавя снимки',
    edit_privacy_mode_text: 'Ниво на поверителност',
    // Toasts
    toast__coverPhoto_was_set: 'Основната снимка е променена',
    set_cover_photo_failed_message: 'Неуспешна промяна на началната снимка.',
    toast__delete_album_failed_message: 'Неуспешно изтриване на албума.',
    toast__unsubscribe_album_failed_message: 'Неуспешен изход от албума.',
    toast__multiple_files_deleted__format: '%d файла бяха изтрити',
    toast__single_file_deleted: 'Файлът беше изтрит',
    delete_file_failed_message: 'Неуспешно изтриване на файла',
    toast__delete_multiple_files_failed__format:
        'Неуспешно изтриване на %d файла',
    toast__multiple_files_perm_deleted__format:
        '%d файла бяха изтрити завинаги',
    toast__single_file_perm_deleted: 'Файлът беше изтрит завинаги',
    toast__multiple_files_perm_deleted_failed__format:
        'Грешка - %d файла не бяха изтрити завинаги',
    toast__single_file_perm_deleted_failed:
        'Грешка - файлът не беше изтрит завинаги',
    toast__single_file_restored: 'Файлът беше възстановен',
    toast__multiple_files_restored__format: '%d файла бяха възстановени',
    toast__single_file_restored_failed: 'Грешка - файлът не беше възстановен',
    toast__multiple_files_restored_failed__format:
        'Грешка - %d файла не бяха възстановени',
    toast__single_file_added_to_album__format:
        'Файлът беше добавен към албум %album_name%',
    toast__multiple_files_added_to_album__format:
        '%d файла бяха добавени към албум %album_name%',
    toast__single_file_added_to_album_failed__format:
        'Грешка - файлът не беше добавен',
    toast__multiple_file_added_to_album_failed__format:
        'Грешка - %d файла не бяха добавени',
    toast__album_auto_crated__format:
        'Албумът "%album_name%" е създаден за Вас',
    toast__see_album: 'Вижте албума',
    toast__album_added_to_timeline: 'Албумът е добавен в Снимки',
    toast__album_added_to_timeline_failed:
        'Неуспешно добавяне на албум. Моля, опитайте отново',
    toast__album_changed_to_private: 'Албумът е променен на Личен',
    toast__file_added_to_timeline: 'Файлът е добавен в Снимки',
    toast__multiple_files_added_to_timeline: 'Файловете са добавени в Снимки',
    toast__file_added_to_timeline_failed:
        'Неуспешно добавяне на файла. Моля, опитайте отново',
    toast__files_were_shared: 'Вашите файлове бяха споделени!',
    toast__files_are_deleting: 'Изтриване на файлове...',
    toast__files_are_restoring: 'Възстановяване на файлове...',
    toast__files_are_copying: 'Копиране на файлове...',
    toast__preparing_to_download: 'Подготовка за сваляне...',
    toast__credit_card_updated:
        'Информацията за разплащателната карта е актуализирана',
    toast__credit_card_update_failed: 'Информацията не успя да се актуализира',
    toast__purchase_successful: 'Абонаментът е поръчан',
    toast__purchase_failed: 'Неуспех при поръчка на абонамент',
    toast__plan_canceled: 'Абонаментът е отказан',
    toast__plan_cancel_failed: 'Неуспех при отказ от абонамент',
    toast__plan_change_succeeded: 'Абонаментът е актуализиран успешно',
    toast__plan_change_failed: 'Неуспех при актуализация на абонамент',
    toast__plan_reactivated: 'Абонаментът е реактивиран',
    toast__plan_reactivation_failed: 'Неуспех при реактивация на абонамент',
    toast__delete_user_card_success: 'Разплащателната карта е изтрита',
    toast__delete_user_card_failed:
        'Изтриването на информацията за кредитната карта не успя',
    toast__change_profile_picture_failed:
        'Неуспешно актуализиране на профилната снимка',
    toast__selection_count_limit_reached: 'Избрали сте твърде много снимки',
    toast__file_copied_to_clipboard: 'Копирано в клипборда!',
    toast__file_copy_to_clipboard_failed: 'Неуспех при копирането!',
    toast__change_user_name_failed: 'Неуспех при промяна на профилното име',

    toast__goto_albums: 'Към Албумите',
    toast__album_restored: 'Вашият албум е възстановен',
    toast__albums_restored: 'Вашите албуми са възстановени',
    toast__album_restore_failed: 'Неуспешно възстановяване на албума',
    toast__album_deleted: 'Вашият албум е окончателно изтрит',
    toast__albums_deleted: 'Вашите албуми са окончателно изтрити',
    toast__album_delete_failed: 'Неуспешно изтриване на албума',

    toast__goto_timeline: 'Към Снимки',

    // Trash
    photos_and_videos: 'Снимки и видеа',
    trash_deleted: 'Изтрити снимки и видеа',
    trash_delete_info:
        'Изтритите файлове ще бъдат изтрити завинаги след 30 дни.',
    trash_no_files: 'Вие нямате изтрити файлове.',
    trash_restore_info:
        'Вие можете да ги възстановите по всяко време преди това!',
    deleted_today: 'Изтривания днес',
    permanently_deleted_soon: 'Скоро ще бъдат изтрити завинаги',
    recently_deleted: 'Наскоро изтрити',
    recently_deleted_albums: 'Наскоро изтрити албуми',
    trash_no_albums: 'Нямате наскоро изтрити албуми',
    trash_delete_info_albums:
        'Изтритите албуми ще бъдат окончателно изтрити след 90 дни',
    album_trash__restore_albums_title: 'Възстановете албуми',
    album_trash__restore_albums_body: 'Искате ли да възстановите тези албуми?',
    album_trash__delete_album_title: 'Изтриване завинаги',
    album_trash__delete_albums_body:
        'Искате ли да изтриете тези албуми завинаги?',
    album_trash__delete_album_body:
        'Искате ли да изтриете този албум завинаги?',
    photos_trash__restore_all_title: 'Възстанови всички снимки и видеа',
    photos_trash__restore_all_body:
        'Искате ли да възстановите всички снимки и видеа в Capture Снимки?',
    photos_trash__restore_all_completed_title:
        'Всички снимки и видеа са възстановени',
    photos_trash__restore_all_completed_body:
        'Всички Ваши снимки и видеа са отново в Capture Снимки.',
    photos_trash__restore_all_incomplete_title: 'Възстановяването е непълно',
    photos_trash__restore_all_incomplete_single_file_body:
        'Един файл не можа да бъде възстановен. Останалите снимки и видеа са отново в Capture Снимки.',
    photos_trash__restore_all_incomplete_multiple_files_body:
        '%count% файла не можаха да бъдат възстановени. Останалите снимки и видеа са отново в Capture Снимки.',

    photos_trash__restoring_all: 'Възстановяване на всички снимки и видеа',
    photos_trash__keep_open: 'Моля не затваряйте този прозорец.',
    photos_trash__keep_open_app: 'Моля не затваряйте приложението.',
    photos_trash__download_all_title: 'Свали всички снимки и видеа',
    photos_trash__download_all_body:
        'Искате ли да свалите всички снимки и видеа на компютъра си?',
    photos_trash__download_all_body_app:
        'Искате ли да свалите всички снимки и видеа на устройството си?',

    // Documents
    documents_no_files: 'Нямате никакви документи.',
    // Share files popup
    share_as_album: 'Споделете като албум',
    share_album_explanation:
        'Създайте албум с всички снимки, които искате да споделите. Всеки, на когото го споделяте, също може да харесва, коментира и добавя свои снимки.',
    share_get_link_explanation:
        'Споделете снимки с временна връзка. Връзката изтича след 30 дни.',
    share_select_how_to: 'Изберете как бихте желали да споделите',
    share_album_created_link:
        'Албумът е създаден! Споделете албума с помощта на следните опции:',
    share_disclaimer:
        'Когато споделяте албум, всеки имащ хипервръзката ще може да го разглежда, дори и да не е поканен лично от Вас.',
    my_new_shared_album: 'Моят нов споделен албум',
    share_to: 'Споделете чрез',
    email: 'Имейл',
    SMS: 'SMS',
    get_link: 'Копирайте линка',
    hold_to_copy_link: 'Натиснете и задръжте, за да копирате линка',
    copy_link: 'Копирайте линка',
    link_copied: 'Линкът беше копиран!',
    share_files_success_header: 'Вие споделихте албум!',
    can_not_share_video_to_fb_album:
        'Видеоклиповете не могат да бъдат споделяни във Facebook албума',
    share_stop_sharing: 'Искате ли да спрете споделянето на тези файлове?',
    share_login_to_stop_sharing:
        'Ако сте споделил(а) тези файлове и желаете да спрете тяхното споделяне първо трябва да %link_start%се впишете%link_end%.',
    // share album form
    allow_others_comment: 'Позволете на други да коментират снимки',
    allow_others_upload: 'Позволете на други да качват снимки',
    // email sharing form
    send_email_to: 'Изпратете имейл на:',
    enter_receivers_address: 'Въведете адреса на получателя тук',
    email_subject: 'Относно:',
    enter_subject: 'Въведете темата тук',
    email_message: 'Съобщение (по избор):',
    enter_message: 'Въведете съобщението си тук',
    invalid_input: 'Невалидно',
    // S H A R E   R E C E I V E R
    share__header_SingleFile__format:
        '%user_name% сподели файл с вас чрез %product_name%',
    share__header_MultipleFiles__format:
        '%user_name% сподели няколко файла с вас чрез %product_name%',
    share__header_SingleFile_owner__format:
        'Споделихте файл чрез %product_name%',
    share__header_MultipleFiles_owner__format:
        'Споделихте някои файлове чрез %product_name%',
    share__add_singleFile_to_capture__format: 'Добавете снимка към %s',
    share__add_multipleFiles_to_capture__format: 'Добавете снимки към %s',
    download_all: 'Изтегли всички',
    share_added_to_timeline: 'Файлове бяха добавени',
    share_added_to_timeline_failed:
        'Неуспешно добавяне на файлове. Моля, опитайте отново',
    share__stop: 'Спри споделянето',
    share__confirm_stop: 'Сигурни ли сте, че искате да спрете споделянето?',
    share__deletion_failed: 'Неуспешно спиране на споделянето на файлове',
    share__deleted_SingleFile: 'Файлът вече не е споделен',
    share__deleted_MultipleFiles: 'Файловете вече не са споделени',
    share_failed: 'Неуспешно споделяне на файлове. Моля, опитайте отново',
    share__not_capture_user: 'Не сте потребител на Capture?',
    share__try_capture: 'Кликнете тук, за да го изпробвате!',
    share__service_info_part1__format:
        '%product_name% цели да осигури безопасността на Вашите снимки и видеоклипове! Това е най-лесният начин автоматично да архивирате снимки и видеоклипове от телефона си, така че да имате достъп до тях по всяко време и да ги споделяте с приятелите и семейството си.',
    share__service_info_part2__format:
        'Регистрирайте се в %product_name% и получете 2 месеца безплатно ползване. Ако сте мобилен клиент на Теленор Норвегия, е възможно да имате неограничено пространство в %product_name% включено във Вашия абонамент.',
    share__service_info_part2__minSky__format:
        'Регистрирайте се в %product_name% и получете 2 месеца безплатно ползване. Ако имате личен мобилен абонамент или Frihet/Flyt/Fri+ бизнес-абонамент от Теленор Норвегия, Вие имате включено безплатно неограничено пространство в %product_name%. Изпратете SMS на 1999 ако безплатното Ви пространство още не е активирано.',
    share__service_info_footer__format:
        'Предоставено с всички лични мобилни абонаменти и Frihet, Flyt и Fri+ бизнес-абонаменти.',
    // Password required
    share_password_required_description:
        'Подателят е защитил това споделяне с парола. Моля, въведете паролата в полето по-горе, за да видите споделянето.',
    // Select from external page
    choose_photo_selection_source: 'Изберете снимки от ',
    all_photos: 'Всички снимки',
    // user hints
    hint_longpress_show_full_image:
        'Натиснете и задръжте снимката, за да се покаже пълното изображение',
    editor_hint_body: 'Добавете филтри, изрежете снимка и още много!',
    // Tooltip
    readonly_tooltip: 'Нужен е абонамент за пълен достъп.',
    // Sunset
    deletion_prompt__title: 'Changes to your Capture terms',
    deletion_prompt__body:
        'Capture will become a paid service on %delete_date%. To keep your photos and videos and continue using Capture, see our subscription plans. The first 6 months are free of charge.',
    deletion_prompt__more_info: 'More information',
    deletion_prompt__plans: 'Subscription plans',
    // casting
    cast: 'Излъчване',
    casting_to: 'Излъчване към %s',
    // consent
    cookie_explanation__format:
        '%product_name% използва бисквитки за да анализира употребата на сайта и за да подобри потребителското преживяване. Част от събираната информация се използва от трети страни.',
    cookie_explanaion_link__format:
        'Прочетете нашата %link_start%Декларация за поверителност%link_end%, за да научите повече за това как използваме бисквитки.',
    account_terminated_error: 'Акаунтът е прекратен',
    account_terminated_desc__format:
        'Акаунтът е прекратен от своя потребител и заради това вече е недостъпен. Моля свържете се с нашето %link_start%Обслужване на клиенти%link_end% за въпроси или помощ.',
    // PhotoEditorSDK
    common_error: 'Грешка',
    common_warning: 'Предупреждение',
    common_color_colorPicker_hex: 'Hex',
    common_color_colorPicker_r: 'R',
    common_color_colorPicker_g: 'G',
    common_color_colorPicker_b: 'B',
    common_color_colorPicker_sliderHue: 'Цвят',
    common_color_colorPicker_sliderOpacity: 'Непрозрачност на цвета',
    mainCanvasActions_buttonExport: 'Запазвайте',
    mainCanvasActions_buttonUndo: 'Назад',
    mainCanvasActions_buttonRedo: 'Направи отново',
    mainCanvasActions_buttonClose: 'Затваряне',
    infoModals_exporting_heading: 'Експортиране...',
    infoModals_exporting_body: 'Само няколко секунди...',
    infoModals_saving_heading: 'Запазване...',
    infoModals_saving_body: 'Само няколко секунди...',
    infoModals_loading_heading: 'Зареждане...',
    infoModals_loading_body: 'Само няколко секунди...',
    infoModals_resizing_heading: 'Преоразмеряване...',
    infoModals_resizing_body: 'Само няколко секунди...',
    infoModals_loadingFonts_heading: 'Зареждане на шрифтове...',
    infoModals_loadingFonts_body: 'Само няколко секунди...',
    infoModals_stickerLoading_heading: 'Зареждане на стикери...',
    infoModals_stickerLoading_body: 'Само няколко секунди...',
    errorModals_imageLoading_headingDelimiter: ':',
    errorModals_imageLoading_body:
        'Несупех при зареждането на изображение. Може да има различни причини за това, например файлът е повреден или типът на файла не се поддържа',
    errorModals_imageLoading_buttonYes: 'Опитай отново',
    errorModals_imageLoading_buttonNo: 'Затваряне',
    errorModals_rendering_headingDelimiter: ':',
    errorModals_rendering_body: 'Възникна грешка при показване на снимката',
    errorModals_rendering_buttonYes: 'Опитай отново',
    errorModals_rendering_buttonNo: 'Затваряне',
    errorModals_fontLoading_headingDelimiter: ':',
    errorModals_fontLoading_heading: 'Несупех при зареждането на шрифт',
    errorModals_fontLoading_body:
        'Следните шрифтове не могат да се заредят: ${error}',
    errorModals_fontLoading_buttonNo: 'Затваряне',
    errorModals_webcamUnavailable_headingDelimiter: ':',
    errorModals_webcamUnavailable_body:
        'Невъзможност да се покаже снимка от уебкамерата (Грешка: ${error})',
    errorModals_webcamUnavailable_buttonYes: 'Затваряне',
    errorModals_stickerLoading_headingDelimiter: ':',
    errorModals_stickerLoading_body: 'Невъзможност да се зареди стикер',
    errorModals_stickerLoading_buttonNo: 'Затваряне',
    errorModals_unexpectedError_headingDelimiter: ':',
    errorModals_unexpectedError_body: 'Възникна неочаквана грешка ${error}',
    errorModals_unexpectedError_buttonYes: 'Опитай отново',
    errorModals_unexpectedError_buttonNo: 'Затваряне',
    warningModals_imageResized_headingDelimiter: ':',
    warningModals_imageResized_heading: 'Изображението е преоразмерено',
    warningModals_imageResized_body:
        'Вашето изображение надхвърля максималния размер от ${megapixels} мегапиксела и беше преоразмерено до ${width}x${height} пиксела',
    warningModals_imageResized_buttonYes: 'Продължи',
    warningModals_unsupportedSerializationVersion_headingDelimiter: ':',
    warningModals_unsupportedSerializationVersion_body:
        'Вашите данни бяха възстановени от стар даннов формат и може да изглеждат различно',
    warningModals_unsupportedSerializationVersion_buttonYes:
        'Приложи промените',
    warningModals_unsupportedSerializationVersion_buttonNo: 'Отказ',
    warningModals_discardChanges_headingDelimiter: ':',
    warningModals_discardChanges_body:
        'Имате несъхранени промени. Сигурни ли сте, че искате да отхърлите промените?',
    warningModals_discardChanges_buttonYes: 'Откажи промените',
    warningModals_discardChanges_buttonNo: 'Запази промените',
    warningModals_unsavedChanges_headingDelimiter: ':',
    warningModals_unsavedChanges_body:
        'Имате незапазени промени. Сигурни ли сте, че искате да излезете?',
    warningModals_unsavedChanges_buttonYes: 'Изход без запазване',
    warningModals_unsavedChanges_buttonNo: 'Отказ',
    warningModals_unsupportedWebGLRenderer_body:
        'Засечен е проблем в браузъра който може да е причина за бавно зареждане. Моля пробвайте различен браузър.',
    warningModals_unsupportedWebGLRenderer_buttonYes: 'Продължи',
    library_title: 'Библиотека',
    library_controls_buttonUpload: 'Качи изображение',
    library_controls_buttonWebcamOpen: 'Отвори уебкамерата',
    library_controls_buttonWebcamClose: 'Затвори уебкамерата',
    library_controls_placeholderSearch: 'Търси в библиотеката',
    library_controls_noResults: 'Няма резултати',
    filter_title: 'Филтри',
    filter_controls_buttonReset: 'Премахни филтъра',
    filter_controls_sliderIntensity: 'Филтрова интензивност',
    filter_categories_imgly_filter_category_duotone: 'ДуоТон',
    filter_categories_imgly_filter_category_bw: 'Черно и бяло',
    filter_categories_imgly_filter_category_vintage: 'Винтидж',
    filter_categories_imgly_filter_category_smooth: 'Гладък',
    filter_categories_imgly_filter_category_cold: 'Студен',
    filter_categories_imgly_filter_category_warm: 'Топъл',
    filter_categories_imgly_filter_category_legacy: 'Наследство',
    filter_items_imgly_lut_celsius: 'Ад',
    filter_items_imgly_lut_chest: 'Кестен',
    filter_items_imgly_lut_fixie: 'Фикси',
    filter_items_imgly_lut_fridge: 'Хладилник',
    filter_items_imgly_lut_front: 'Слънчеви 70-те',
    filter_items_imgly_lut_k2: 'Матово черен',
    filter_items_imgly_lut_mellow: 'Сочен',
    filter_items_imgly_lut_sin: 'Твърди неща',
    filter_items_imgly_lut_texas: 'Старовремски',
    filter_items_imgly_lut_ad1920: '1920 сл.н.е.',
    filter_items_imgly_lut_ancient: 'Древен',
    filter_items_imgly_lut_bleached: 'Калмен',
    filter_items_imgly_lut_bleachedblue: 'Джоран',
    filter_items_imgly_lut_blues: 'Полароид',
    filter_items_imgly_lut_blueshadows: 'Зефир',
    filter_items_imgly_lut_breeze: 'Леванте',
    filter_items_imgly_lut_bw: 'Посивен',
    filter_items_imgly_lut_classic: 'Класически',
    filter_items_imgly_lut_colorful: 'Шарен',
    filter_items_imgly_lut_cool: 'Пъргав',
    filter_items_imgly_lut_cottoncandy: 'Бонбони',
    filter_items_imgly_lut_creamy: 'Кремав',
    filter_items_imgly_lut_eighties: 'Тих огън',
    filter_items_imgly_lut_elder: 'Кола',
    filter_items_imgly_lut_evening: 'Изгрев',
    filter_items_imgly_lut_fall: 'Мъх',
    filter_items_imgly_lut_food: 'Храна',
    filter_items_imgly_lut_glam: 'Бляскав',
    filter_items_imgly_lut_gobblin: 'Гоблин',
    filter_items_imgly_lut_highcarb: 'Високо въглехидратен',
    filter_items_imgly_lut_highcontrast: 'Хикон',
    filter_items_imgly_lut_k1: 'К1',
    filter_items_imgly_lut_k6: 'К6',
    filter_items_imgly_lut_kdynamic: 'Камъче',
    filter_items_imgly_lut_keen: 'Устремен',
    filter_items_imgly_lut_lenin: 'Лимон',
    filter_items_imgly_lut_litho: 'Лито',
    filter_items_imgly_lut_lomo: 'Ломо',
    filter_items_imgly_lut_lomo100: 'Ломо 100',
    filter_items_imgly_lut_lucid: 'Ясен',
    filter_items_imgly_lut_neat: 'Стегнат',
    filter_items_imgly_lut_nogreen: 'Тиква',
    filter_items_imgly_lut_orchid: 'Соланус',
    filter_items_imgly_lut_pale: 'Блед',
    filter_items_imgly_lut_pitched: 'Наклонен',
    filter_items_imgly_lut_plate: 'Изветрял',
    filter_items_imgly_lut_pola669: 'Зелена пролука',
    filter_items_imgly_lut_polasx: 'Пола Ес Екс',
    filter_items_imgly_lut_pro400: 'Про 400',
    filter_items_imgly_lut_quozi: 'Куози',
    filter_items_imgly_lut_sepiahigh: 'Сепия',
    filter_items_imgly_lut_settled: 'Улегнал',
    filter_items_imgly_lut_seventies: 'Седемдесетте',
    filter_items_imgly_lut_soft: 'Мек',
    filter_items_imgly_lut_steel: 'Стомана',
    filter_items_imgly_lut_summer: 'Лято',
    filter_items_imgly_lut_sunset: 'Златен',
    filter_items_imgly_lut_tender: 'Нежен',
    filter_items_imgly_lut_twilight: 'Здрач',
    filter_items_imgly_lut_winter: 'Меко',
    filter_items_imgly_lut_x400: 'Прашно',
    filter_items_imgly_duotone_desert: 'Пустиня',
    filter_items_imgly_duotone_peach: 'Праскова',
    filter_items_imgly_duotone_clash: 'Сблъсък',
    filter_items_imgly_duotone_plum: 'Слива',
    filter_items_imgly_duotone_breezy: 'Полъх',
    filter_items_imgly_duotone_deepblue: 'Тъмно син',
    filter_items_imgly_duotone_frog: 'Жаба',
    filter_items_imgly_duotone_sunset: 'Залез',
    adjustment_title: 'Регулиране',
    adjustment_controls_buttonReset: 'Нулиране на регулирането',
    adjustment_categories_basics: 'Основен',
    adjustment_categories_refinements: 'Пресявания',
    adjustment_items_brightness: 'Яркост',
    adjustment_items_saturation: 'Насищане',
    adjustment_items_contrast: 'Контраст',
    adjustment_items_gamma: 'Гама',
    adjustment_items_sharpness: 'Острота',
    adjustment_items_clarity: 'Яснота',
    adjustment_items_exposure: 'Експонация',
    adjustment_items_shadows: 'Сенки',
    adjustment_items_highlights: 'Акценти',
    adjustment_items_whites: 'Бели',
    adjustment_items_blacks: 'Черни',
    adjustment_items_temperature: 'Температура',
    focus_title: 'Фокус',
    focus_controls_buttonReset: 'Премахване на фокуса',
    focus_controls_sliderIntensity: 'Интензивност на фокуса',
    focus_items_radial: 'Радиален',
    focus_items_mirrored: 'Огледален',
    focus_items_linear: 'Линеен',
    focus_items_gaussian: 'Гаусов',
    focus_history_focusPosition: 'Позиция на фокуса',
    focus_history_focusSize: 'Размер на фокуса',
    overlay_title: 'Наслагвания',
    overlay_controls_buttonReset: 'Премахни наслагването',
    overlay_controls_sliderOpacity: 'Непрозрачност на наслагването',
    overlay_controls_carouselBlendMode: 'Режим смесване на наслагванията',
    overlay_controls_blendModeNormal: 'Нормален',
    overlay_controls_blendModeOverlay: 'Наслагване',
    overlay_controls_blendModeHardLight: 'Твърда светлина',
    overlay_controls_blendModeSoftLight: 'Мека светлина',
    overlay_controls_blendModeMultiply: 'Мутлипликация',
    overlay_controls_blendModeDarken: 'Затъмнен',
    overlay_controls_blendModeLighten: 'Изсветлен',
    overlay_controls_blendModeScreen: 'Екран',
    overlay_controls_blendModeColorBurn: 'Цветно изгаряне',
    overlay_controls_tabOpacity: 'Непрозрачност',
    overlay_controls_tabBlendMode: 'Режим смесване',
    overlay_items_imgly_overlay_bokeh: 'Боке',
    overlay_items_imgly_overlay_chop: 'Котлет',
    overlay_items_imgly_overlay_clouds: 'Облаци',
    overlay_items_imgly_overlay_golden: 'Златен',
    overlay_items_imgly_overlay_grain: 'Зърно',
    overlay_items_imgly_overlay_hearts: 'Сърца',
    overlay_items_imgly_overlay_lightleak1: 'Теч на светлина 1',
    overlay_items_imgly_overlay_lightleak2: 'Теч на светлина 2',
    overlay_items_imgly_overlay_metal: 'Метал',
    overlay_items_imgly_overlay_mosaic: 'Мозайка',
    overlay_items_imgly_overlay_painting: 'Картина',
    overlay_items_imgly_overlay_paper: 'Хартия',
    overlay_items_imgly_overlay_rain: 'Дъжд',
    overlay_items_imgly_overlay_vintage: 'Винтидж',
    overlay_items_imgly_overlay_wall1: 'Стена',
    overlay_items_imgly_overlay_wall2: 'Стена 2',
    overlay_items_imgly_overlay_wood: 'Дървесина',
    sticker_title: 'Стикери',
    sticker_controls_buttonUpload: 'Качи стикер',
    sticker_controls_sliderOpacity: 'Непрозрачност на стикера',
    sticker_controls_selectColor: 'Цвят на стикера',
    sticker_controls_tabColor: 'Цвят',
    sticker_controls_tabOpacity: 'Непрозрачност',
    sticker_categories_imgly_sticker_emoticons: 'Емотикони',
    sticker_categories_imgly_sticker_shapes: 'Форми',
    sticker_categories_imgly_sticker_custom: 'Персонализиран',
    sticker_items_imgly_sticker_emoticons_alien: 'Пришълец',
    sticker_items_imgly_sticker_emoticons_angel: 'Ангел',
    sticker_items_imgly_sticker_emoticons_angry: 'Гневен',
    sticker_items_imgly_sticker_emoticons_anxious: 'Разтревожен',
    sticker_items_imgly_sticker_emoticons_asleep: 'Заспал',
    sticker_items_imgly_sticker_emoticons_attention: 'Внимание',
    sticker_items_imgly_sticker_emoticons_baby_chicken: 'Пиленце',
    sticker_items_imgly_sticker_emoticons_batman: 'Батман',
    sticker_items_imgly_sticker_emoticons_beer: 'Бира',
    sticker_items_imgly_sticker_emoticons_black: 'Черно',
    sticker_items_imgly_sticker_emoticons_blue: 'Син',
    sticker_items_imgly_sticker_emoticons_blush: 'Изчервяване',
    sticker_items_imgly_sticker_emoticons_boxer: 'Боксьор',
    sticker_items_imgly_sticker_emoticons_business: 'Бизнес',
    sticker_items_imgly_sticker_emoticons_chicken: 'Пиле',
    sticker_items_imgly_sticker_emoticons_cool: 'Готин',
    sticker_items_imgly_sticker_emoticons_cry: 'Плач',
    sticker_items_imgly_sticker_emoticons_deceased: 'Починал',
    sticker_items_imgly_sticker_emoticons_devil: 'Дявол',
    sticker_items_imgly_sticker_emoticons_duckface: 'Патешко лице',
    sticker_items_imgly_sticker_emoticons_furious: 'Яростен',
    sticker_items_imgly_sticker_emoticons_grin: 'Усмивка',
    sticker_items_imgly_sticker_emoticons_guitar: 'Китара',
    sticker_items_imgly_sticker_emoticons_harry_potter: 'Хари Потър',
    sticker_items_imgly_sticker_emoticons_hippie: 'Хипи',
    sticker_items_imgly_sticker_emoticons_hitman: 'Убиец',
    sticker_items_imgly_sticker_emoticons_humourous: 'Хумористичен',
    sticker_items_imgly_sticker_emoticons_idea: 'Идея',
    sticker_items_imgly_sticker_emoticons_impatient: 'Нетърпелив',
    sticker_items_imgly_sticker_emoticons_kiss: 'Целувка',
    sticker_items_imgly_sticker_emoticons_kisses: 'Целувки',
    sticker_items_imgly_sticker_emoticons_laugh: 'Смях',
    sticker_items_imgly_sticker_emoticons_loud_cry: 'Силен плач',
    sticker_items_imgly_sticker_emoticons_loving: 'Влюбен',
    sticker_items_imgly_sticker_emoticons_masked: 'Маскиран',
    sticker_items_imgly_sticker_emoticons_music: 'Музика',
    sticker_items_imgly_sticker_emoticons_nerd: 'Зубрач',
    sticker_items_imgly_sticker_emoticons_ninja: 'Нинджа',
    sticker_items_imgly_sticker_emoticons_not_speaking_to_you:
        'Не говоря с теб',
    sticker_items_imgly_sticker_emoticons_pig: 'Прасе',
    sticker_items_imgly_sticker_emoticons_pumpkin: 'Тиква',
    sticker_items_imgly_sticker_emoticons_question: 'Въпрос',
    sticker_items_imgly_sticker_emoticons_rabbit: 'Заек',
    sticker_items_imgly_sticker_emoticons_sad: 'Тъжен',
    sticker_items_imgly_sticker_emoticons_sick: 'Болен',
    sticker_items_imgly_sticker_emoticons_skateboard: 'Скейтборд',
    sticker_items_imgly_sticker_emoticons_skull: 'Череп',
    sticker_items_imgly_sticker_emoticons_sleepy: 'Сънлив',
    sticker_items_imgly_sticker_emoticons_smile: 'Усмивка',
    sticker_items_imgly_sticker_emoticons_smoking: 'Пушещ',
    sticker_items_imgly_sticker_emoticons_sobbing: 'Хлипане',
    sticker_items_imgly_sticker_emoticons_star: 'Звезда',
    sticker_items_imgly_sticker_emoticons_steaming_furious: 'Пуфтящ и яростен',
    sticker_items_imgly_sticker_emoticons_sunbathing: 'Слънчева баня',
    sticker_items_imgly_sticker_emoticons_tired: 'Уморен',
    sticker_items_imgly_sticker_emoticons_tongue_out_wink:
        'Изплезен език и намигване',
    sticker_items_imgly_sticker_emoticons_wave: 'Помахване',
    sticker_items_imgly_sticker_emoticons_wide_grin: 'Широка усмивка',
    sticker_items_imgly_sticker_emoticons_wink: 'Намигване',
    sticker_items_imgly_sticker_emoticons_wrestler: 'Борец',
    sticker_items_imgly_sticker_shapes_arrow_02: 'Стрела 1',
    sticker_items_imgly_sticker_shapes_arrow_03: 'Стрела 2',
    sticker_items_imgly_sticker_shapes_badge_01: 'Бадж 1',
    sticker_items_imgly_sticker_shapes_badge_11: 'Бадж 5',
    sticker_items_imgly_sticker_shapes_badge_12: 'Бадж 6',
    sticker_items_imgly_sticker_shapes_badge_13: 'Бадж 7',
    sticker_items_imgly_sticker_shapes_badge_15: 'Бадж 8',
    sticker_items_imgly_sticker_shapes_badge_18: 'Бадж 9',
    sticker_items_imgly_sticker_shapes_badge_19: 'Бадж 10',
    sticker_items_imgly_sticker_shapes_badge_20: 'Бадж 11',
    sticker_items_imgly_sticker_shapes_badge_28: 'Бадж 12',
    sticker_items_imgly_sticker_shapes_badge_32: 'Бадж 13',
    sticker_items_imgly_sticker_shapes_badge_35: 'Бадж 14',
    sticker_items_imgly_sticker_shapes_badge_36: 'Бадж 15',
    sticker_items_imgly_sticker_shapes_badge_04: 'Бадж 2',
    sticker_items_imgly_sticker_shapes_badge_06: 'Бадж 3',
    sticker_items_imgly_sticker_shapes_badge_08: 'Бадж 4',
    sticker_items_imgly_sticker_shapes_spray_01: 'Спрей 1',
    sticker_items_imgly_sticker_shapes_spray_03: 'Спрей 2',
    sticker_items_imgly_sticker_shapes_spray_04: 'Спрей 3',
    sticker_canvasActions_buttonDelete: 'Изтрий',
    sticker_canvasActions_buttonBringToFront: 'Премести напред',
    sticker_canvasActions_buttonDuplicate: 'Дубликат',
    sticker_canvasActions_buttonFlipHorizontal: 'Обръщане',
    sticker_canvasActions_buttonFlipVertical: 'Обръщане',
    sticker_history_add: 'Стикер',
    sticker_history_resize: 'Преоразмеряване на стикера',
    sticker_history_position: 'Позиция на стикера',
    sticker_history_color: 'Цвят на стикера',
    sticker_history_delete: 'Изтриване на стикера',
    sticker_history_order: 'Подредба на стикера',
    sticker_history_opacity: 'Непрозрачност на стикера',
    sticker_history_flip: 'Обръщане на стикера',
    text_title: 'Текст',
    text_controls_buttonNew: 'Нов текст',
    text_controls_dropdownFontFamily: 'Семейство от шрифтове',
    text_controls_textFontSize: 'Размер на фокуса',
    text_controls_selectAlignment: 'Подравняване',
    text_controls_selectFontColor: 'Цвят на шрифта',
    text_controls_selectBackgroundColor: 'Фонов цвят',
    text_controls_sliderLineSpacing: 'Разстояние между редовете',
    text_controls_tabColor: 'Цвят',
    text_controls_tabBgColor: 'Фонов цвят',
    text_controls_tabAlignment: 'Подравняване',
    text_controls_tabLineHeight: 'Височина на реда',
    text_controls_tabFontSize: 'Размер на фокуса',
    text_canvasControls_placeholderText: 'Напишете нещо',
    text_canvasControls_buttonSave: 'Готово',
    text_canvasControls_buttonClose: 'Отказ',
    text_canvasControls_inputText: 'Въвеждане на текст',
    text_canvasActions_buttonEdit: 'Редакция',
    text_canvasActions_buttonDelete: 'Изтрий',
    text_canvasActions_buttonBringToFront: 'Премести напред',
    text_canvasActions_buttonDuplicate: 'Дубликат',
    text_history_add: 'Текст',
    text_history_edit: 'Редакция на текста',
    text_history_resize: 'Преоразмеряване на текста',
    text_history_position: 'Позиция на текста',
    text_history_alignment: 'Подравняване на текста',
    text_history_textColor: 'Цвят на текста',
    text_history_backgroundColor: 'Цвят на фона на текста',
    text_history_fontFamily: 'Семейство от шрифтове',
    text_history_fontStyle: 'Стил на шрифта',
    text_history_lineSpacing: 'Разстояние между редовете',
    text_history_width: 'Широчина на текста',
    text_history_delete: 'Изтриване на текста',
    text_history_order: 'Подредба на текста',
    textdesign_title: 'Дизайн на текста',
    textdesign_controls_buttonNew: 'Нов дизайн на текста',
    textdesign_controls_buttonShuffle: 'Разбъркване на оформлението',
    textdesign_controls_selectColor: 'Цвят на текста',
    textdesign_controls_tabColor: 'Цвят',
    textdesign_controls_tabShuffle: 'Разбъркване',
    textdesign_canvasControls_placeholderText: 'Напишете нещо със стил',
    textdesign_canvasControls_buttonSave: 'Готово',
    textdesign_canvasControls_buttonClose: 'Затваряне',
    textdesign_canvasControls_inputText: 'Въвеждане на текст',
    textdesign_canvasActions_buttonEdit: 'Редакция',
    textdesign_canvasActions_buttonInvert: 'Инверсия',
    textdesign_canvasActions_buttonDelete: 'Изтрий',
    textdesign_canvasActions_buttonBringToFront: 'Премести напред',
    textdesign_canvasActions_buttonDuplicate: 'Дубликат',
    textdesign_history_add: 'Дизайн на текста',
    textdesign_history_edit: 'Редакция на дизайна на текста',
    textdesign_history_resize: 'Преоразмеряване на дизайна на текста',
    textdesign_history_position: 'Позиция на дизайна на текста',
    textdesign_history_color: 'Цвят на дизайна на текста',
    textdesign_history_shuffle: 'Разбъркване на дизайна на текста',
    textdesign_history_invert: 'Инверсия на дизайна на текста',
    textdesign_history_padding: 'Допълване на дизайна на текста',
    textdesign_history_order: 'Подредба на дизайна на текста',
    textdesign_history_delete: 'Изтриване на дизайна на текста',
    frame_title: 'Рамки',
    frame_controls_buttonReset: 'Премахни рамката',
    frame_controls_sliderOpacity: 'Непрозрачност на рамката',
    frame_controls_sliderSize: 'Размер на рамката',
    frame_controls_selectColor: 'Цвят на рамката',
    frame_controls_tabColor: 'Цвят',
    frame_controls_tabOpacity: 'Непрозрачност',
    frame_controls_tabSize: 'Размер',
    frame_items_imgly_frame_dia: 'Диамант',
    frame_items_imgly_frame_art_decor: 'Арт деко',
    frame_items_imgly_frame_black_passepartout: 'Черно',
    frame_items_imgly_frame_lowpoly_shadow: 'Нискополигонален',
    frame_items_imgly_frame_wood_passepartout: 'Дървесина',
    brush_title: 'Четка',
    brush_controls_buttonReset: 'Премахни четката',
    brush_controls_sliderSize: 'Размер на четката',
    brush_controls_sliderHardness: 'Твърдост на четката',
    brush_controls_selectColor: 'Цвят на четката',
    brush_controls_tabSize: 'Размер',
    brush_controls_tabHardness: 'Твърдост',
    brush_controls_tabColor: 'Цвят',
    brush_history_brushStroke: 'Мазка с четка',
    transform_title: 'Трансформация',
    transform_controls_buttonReset: 'Нулиране до настройки по подразбиране',
    transform_controls_checkboxKeepResolution: 'Запази резолюцията',
    transform_controls_inputCropSize: 'Размер на отрязването',
    transform_controls_inputHeight: 'h',
    transform_controls_inputWidth: 'w',
    transform_controls_tabFlipAndRotate: 'Обърни и завърти',
    transform_controls_tabResolution: 'Резолюция',
    transform_controls_tabCropSize: 'Размер на отрязването',
    transform_categories_imgly_transforms_common: 'Общ',
    transform_categories_imgly_transforms_facebook: 'Фейсбук',
    transform_categories_imgly_transforms_twitter: 'Туитър',
    transform_categories_imgly_transforms_instagram: 'Инстаграм',
    transform_items_imgly_transform_common_custom: 'Персонализиран',
    transform_items_imgly_transform_common_square: 'Квадрат',
    transform_items_imgly_transform_common_4: '4:3',
    transform_items_imgly_transform_common_16: '16:9',
    transform_items_imgly_transform_common_3: '3:4',
    transform_items_imgly_transform_common_9: '9:16',
    transform_items_imgly_transform_facebook_profile: 'Профил',
    transform_items_imgly_transform_facebook_title: 'Заглавие',
    transform_items_imgly_transform_facebook_post: 'Публикуване',
    transform_items_imgly_transform_instagram_story: 'История',
    transform_items_imgly_transform_instagram_landscape: 'Пейзаж',
    transform_items_imgly_transform_instagram_portrait: 'Портрет',
    transform_items_imgly_transform_instagram_square: 'Квадрат',
    transform_items_imgly_transform_twitter_profile: 'Профил',
    transform_items_imgly_transform_twitter_title: 'Заглавие',
    transform_items_imgly_transform_twitter_post: 'Публикуване',
    transform_transformActions_buttonFlipHorizontal: 'Обърни хоризонтално',
    transform_transformActions_buttonFlipVertical: 'Обърни вертикално',
    transform_transformActions_buttonRotateClockwise:
        'Завърти по часовниковата стрелка',
    transform_transformActions_buttonRotateAntiClockwise:
        'Завърти срещу часовниковата стрелка',
}
