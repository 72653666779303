import type { DeletedFile } from '~/@types/backend-types'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import { FileDimensionsDiscovered } from '../files/actions'
import {
    FilesRestorationFailed,
    FilesRestorationStarted,
    FilesRestorationSucceeded,
} from '../job/actions'
import {
    FetchRemainingTrashFilesProgressUpdated,
    RestoreAllProgressUpdated,
    TrashFilesDeletionFailed,
    TrashFilesDeletionStarted,
    TrashFilesDeletionSucceeded,
    TrashLoadingFailed,
    TrashLoadingStarted,
    TrashLoadingSucceeded,
} from './actions'

export type TrashState = {
    fetchState: 'not_started' | 'pending' | 'fetched' | 'failed'
    fileList: Record<FileID, DeletedFile | null>
    paginations: Record<number, FileID[]>
    processingFiles: FileID[]
    totalItemCount?: number
    fetchRemainingTrashFilesProgress?: number
    restoreAllProgress?: number
}

const initialState: TrashState = {
    fetchState: 'not_started',
    fileList: {},
    paginations: {},
    processingFiles: [],
}

const addProcessingFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    return {
        ...state,
        processingFiles: Array.from(
            new Set(state.processingFiles.concat(targetFiles)),
        ),
    }
}

const removeProcessingFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    const processingFiles = state.processingFiles.filter(
        (f) => !targetFiles.includes(f),
    )
    return { ...state, processingFiles }
}

const removeDeletedFilesFromState = (
    state: TrashState,
    targetFiles: FileID[],
): TrashState => {
    return {
        ...state,
        processingFiles: state.processingFiles.filter(
            (f) => !targetFiles.includes(f),
        ),
        fileList: {
            ...state.fileList,
            ...targetFiles.reduce(
                (acc, fileID) => {
                    acc[fileID] = null
                    return acc
                },
                {} as Record<FileID, null>,
            ),
        },
    }
}

export const trashReducer = (
    state: TrashState = initialState,
    action: Action,
): TrashState => {
    if (isType(action, TrashLoadingStarted)) {
        return { ...state, fetchState: 'pending' }
    }
    if (isType(action, TrashLoadingSucceeded)) {
        const { deletedFiles, offset, totalItemCount } = action.payload

        return {
            ...state,
            fetchState: 'fetched',
            fileList: {
                ...state.fileList,
                ...deletedFiles.reduce(
                    (acc, file) => {
                        acc[file.id] = file
                        return acc
                    },
                    {} as Record<FileID, DeletedFile>,
                ),
            },
            paginations: {
                ...state.paginations,
                [offset]: deletedFiles.map((f) => f.id),
            },
            totalItemCount,
        }
    }
    if (isType(action, TrashLoadingFailed)) {
        return { ...state, fetchState: 'failed' }
    }

    if (isType(action, TrashFilesDeletionStarted)) {
        return addProcessingFilesFromState(state, action.payload)
    }
    if (isType(action, TrashFilesDeletionSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload)
    }
    if (isType(action, TrashFilesDeletionFailed)) {
        return removeProcessingFilesFromState(state, action.payload)
    }

    if (isType(action, FilesRestorationStarted)) {
        return addProcessingFilesFromState(state, action.payload.files)
    }
    if (isType(action, FilesRestorationSucceeded)) {
        return removeDeletedFilesFromState(state, action.payload.files)
    }
    if (isType(action, FilesRestorationFailed)) {
        return removeProcessingFilesFromState(state, action.payload.files)
    }
    if (isType(action, FileDimensionsDiscovered)) {
        return {
            ...state,
            fileList: {
                ...state.fileList,
                ...action.payload.reduce(
                    (acc, info) => {
                        const curentFile = state.fileList[info.fileID]
                        if (!curentFile) {
                            return acc
                        }
                        acc[info.fileID] = {
                            ...curentFile,
                            width: info.width,
                            height: info.height,
                        }
                        return acc
                    },
                    {} as Record<FileID, DeletedFile>,
                ),
            },
        }
    }

    if (isType(action, FetchRemainingTrashFilesProgressUpdated)) {
        return {
            ...state,
            fetchRemainingTrashFilesProgress: action.payload,
        }
    }

    if (isType(action, RestoreAllProgressUpdated)) {
        return {
            ...state,
            restoreAllProgress: action.payload,
        }
    }

    return state
}

export const trashReducerMapObj = {
    trash: trashReducer,
}

export type StateWithTrash = StateOfReducerMapObj<typeof trashReducerMapObj>
