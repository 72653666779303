import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import SvgDeleteForever from '@capture/capture-components/src/icons/DeleteForever'
import SvgDownload from '@capture/capture-components/src/icons/Download'
import SvgRestore from '@capture/capture-components/src/icons/Restore'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { NavBarButton, Prompt } from '@capture/capture-components'
import {
    downloadAllTrashFiles,
    maybePaginatedDownloadDeletedFiles,
} from '~/API/download'
import { loadTrashContent, restoreAllTrashFiles } from '~/API/job'
import { trackEvent, trackEventInternal } from '~/analytics/eventTracking'
import trashIllustration from '~/assets/illustrations/trash-illustration.svg'
import { _, getStringByAmount } from '~/assets/localization/util'
import { colors, layout, mediaQueries } from '~/assets/styleConstants'
import { RoutePath } from '~/routing'
import {
    ConfirmableSelectionAction,
    FilesWereDeselected,
    SelectionActionInitiated,
} from '~/state/selectedFiles/actions'
import { getTimelineJobID } from '~/state/timeline/selectors'
import { FetchMoreTrashTriggered } from '~/state/trash/actions'
import {
    TrashGroupKey,
    getAllTrashFileIDsForRestore,
    getAllFetchedTrashFileInfos,
    getSelectedTrashFileIDs,
    getSelectedTrashFileInfos,
    getSelectedTrashFilesTotalSize,
    getTrashGroupWithSelection,
    getTrashTotalItemCount,
    getTrashViewFiles,
    hasMoreTrashFilesToFetch,
    hasSelectedTrashFiles,
    isLoadingTrash,
} from '~/state/trash/selectors'
import { getImageGroupStyle, isMobileMode } from '~/state/viewMode/selectors'
import type {
    FilesRestorationFailedPayload,
    FilesRestorationSucceededPayload,
} from '~/state/job/actions'
import {
    FilesRestorationFailed,
    FilesRestorationSucceeded,
} from '~/state/job/actions'
import { getIsUserFromInsideApp } from '~/state/currentUser/selectors'
import { TRASH_FETCH_LIMIT } from '~/config/constants'
import { DownloadAllPrompt } from '~/components/Trash/DownloadAllPrompt'
import { DownloadProgressPrompt } from '~/components/Trash/DownloadProgressPrompt'
import { Button } from '../Common/Button'
import { DeletionNotice } from '../Common/DeletionNotice'
import { EmptyStatePage } from '../Common/EmptyStatePage'
import { PageWrapper } from '../Common/PageWrapper'
import { RippleLoader, RippleLoaderPage } from '../Common/RippleLoader'
import { SelectionActionDialogPlacement } from '../Common/SelectionActionDialogPlacement'
import { HeaderText } from '../Common/StandardPageElements'
import { FilesOptionsPlacement } from '../FilesOptions/FilesOptionsPlacement'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'
import { ImageGroupList } from '../Timeline/ImageGroupList'
import {
    SelectionToolbarDesktop,
    SelectionToolbarMobile,
} from '../Timeline/SelectionToolBar'
import { ReadonlyTooltip } from '../Common/ReadonlyTooltip'
import { RestoreAllPrompt } from './RestoreAllPrompt'
import { RestoreProgressPrompt } from './RestoreProgressPrompt'
import { RestoreAllCompletedPrompt } from './RestoreAllCompletedPrompt'
import { RestoreAllIncompletePrompt } from './RestoreAllIncompletePrompt'

const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: ${layout.selectionToolbarHeight}px;

    ${mediaQueries.mobile} {
        padding-top: 32px;
    }
`

const Header = styled(HeaderText)`
    margin-top: 0;
    text-align: left;
    width: ${(props: { width: number }) => props.width}px;
`

const Count = styled.span`
    :before {
        content: '•';
        color: ${colors.captureGrey400};
        margin-inline: 0.5rem;
    }
    font-weight: 400;
`

const LoaderWrapper = styled.div.attrs({ 'aria-hidden': true })`
    display: flex;
    justify-content: center;
`

const ButtonContainer = styled.section`
    display: flex;
    gap: 1rem;
`

const SelectModeNavBar = () => {
    const dispatch = useDispatch()
    const timelineID = useSelector(getTimelineJobID)
    const selectedFileIDs = useSelector(getSelectedTrashFileIDs)
    const selectedFileInfos = useSelector(getSelectedTrashFileInfos)
    const selectedFilesTotalSize = useSelector(getSelectedTrashFilesTotalSize)
    const isMobile = useSelector(isMobileMode)

    const Toolbar = isMobile ? SelectionToolbarMobile : SelectionToolbarDesktop

    return (
        <Toolbar
            buttons={[
                Button(
                    _('download'),
                    () => {
                        if (timelineID) {
                            maybePaginatedDownloadDeletedFiles(dispatch, {
                                jobID: timelineID,
                                fileInfos: selectedFileInfos,
                                context: 'TrashPage',
                                zipFileName: _('deleted_items'),
                            })
                        }
                    },
                    {
                        icon: SvgDownload,
                        cyKey: 'trash__exportBtn',
                    },
                ),
                Button(
                    _('restore'),
                    () => {
                        dispatch(
                            SelectionActionInitiated(
                                ConfirmableSelectionAction.RESTORE,
                            ),
                        )
                    },
                    {
                        icon: SvgRestore,
                        cyKey: 'trash__restoreBtn',
                        isDisabledForReadonly: true,
                    },
                ),
                Button(
                    _('delete_'),
                    () => {
                        dispatch(
                            SelectionActionInitiated(
                                ConfirmableSelectionAction.PERM_DELETE,
                            ),
                        )
                    },
                    {
                        icon: SvgDeleteForever,
                    },
                ),
            ]}
            selectedFiles={selectedFileIDs}
            selectedFilesSize={selectedFilesTotalSize}
            cancelSelectMode={() => {
                dispatch(FilesWereDeselected(selectedFileIDs))
            }}
        />
    )
}

type TrashPhotoNavBarProps = {
    inAppMode?: boolean
    onRestoreAllConfirmed: () => Promise<void>
    onDownloadAllConfirmed: () => Promise<void>
}
const TrashPhotoNavBar = ({
    inAppMode,
    onRestoreAllConfirmed,
    onDownloadAllConfirmed,
}: TrashPhotoNavBarProps) => {
    const navigate = useNavigate()
    const [searchParams, _setSearchParams] = useSearchParams()
    const isFromInAppNavigation = searchParams.get('fromNav') === '1'
    const canGoBack = !inAppMode || isFromInAppNavigation

    const [isRestoreAllPromptOpen, setIsRestoreAllPromptOpen] = useState(false)
    const [isDownloadAllPromptOpen, setIsDownloadAllPromptOpen] =
        useState(false)

    const isSelectMode = useSelector(hasSelectedTrashFiles)
    const totalItemCount = useSelector(getTrashTotalItemCount)

    if (isSelectMode) {
        return <SelectModeNavBar />
    }

    return (
        <TwoAreasTopNavBar
            hideUserAvi
            left={
                canGoBack && (
                    <NavBarButton
                        onPress={() =>
                            navigate(
                                inAppMode
                                    ? RoutePath.InAppTrashNavigation
                                    : RoutePath.TrashNavigation,
                            )
                        }
                        text={_('recently_deleted')}
                        icon={SvgArrowBack}
                    />
                )
            }
            right={
                <ButtonContainer>
                    <Prompt.Root
                        open={isDownloadAllPromptOpen}
                        onOpenChange={setIsDownloadAllPromptOpen}>
                        <Prompt.Trigger asChild>
                            <NavBarButton
                                data-cy="trash__downloadAllBtn"
                                text={_('download_all')}
                                icon={SvgDownload}
                                isDisabled={!totalItemCount}
                                onPress={() =>
                                    trackEventInternal(
                                        'photos_trash__download_all_button_clicked',
                                    )
                                }
                            />
                        </Prompt.Trigger>
                        <DownloadAllPrompt
                            isInsideApp={inAppMode}
                            handleConfirm={() => {
                                setIsDownloadAllPromptOpen(false)
                                onDownloadAllConfirmed()
                            }}
                        />
                    </Prompt.Root>
                    <ReadonlyTooltip
                        renderElement={(isReadOnlyUser) => (
                            <Prompt.Root
                                open={isRestoreAllPromptOpen}
                                onOpenChange={setIsRestoreAllPromptOpen}>
                                <Prompt.Trigger asChild>
                                    <NavBarButton
                                        data-cy="trash__restoreAllBtn"
                                        text={_('restore_all')}
                                        icon={SvgRestore}
                                        isDisabled={
                                            isReadOnlyUser || !totalItemCount
                                        }
                                        onPress={() =>
                                            trackEventInternal(
                                                'photos_trash__restore_all_button_clicked',
                                            )
                                        }
                                    />
                                </Prompt.Trigger>
                                <RestoreAllPrompt
                                    handleConfirm={() => {
                                        setIsRestoreAllPromptOpen(false)
                                        onRestoreAllConfirmed()
                                    }}
                                />
                            </Prompt.Root>
                        )}
                    />
                </ButtonContainer>
            }
        />
    )
}

const TrashPhotosContent = () => {
    const contentElementRef = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()
    const files = useSelector(getTrashViewFiles)
    const isTrashLoading = useSelector(isLoadingTrash)
    const timelineID = useSelector(getTimelineJobID)
    const hasMoreToFetch = useSelector(hasMoreTrashFilesToFetch)
    const groupStyle = useSelector(getImageGroupStyle)
    const trashImageGroups = useSelector(getTrashGroupWithSelection)
    const selectedFileIDs = useSelector(getSelectedTrashFileIDs)
    const trashTotalItemCount = useSelector(getTrashTotalItemCount)

    useLayoutEffect(() => {
        const isReachingBottomOfList = () => {
            if (
                !isTrashLoading &&
                contentElementRef.current &&
                contentElementRef.current.scrollHeight -
                    window.innerHeight -
                    window.scrollY <
                    1000 &&
                hasMoreToFetch
            ) {
                trackEvent('Trash', 'fetchedMoreTrashItems')
                dispatch(FetchMoreTrashTriggered())
            }
        }

        window.addEventListener('scroll', isReachingBottomOfList)

        return () =>
            window.removeEventListener('scroll', isReachingBottomOfList)
    }, [contentElementRef, isTrashLoading, hasMoreToFetch, dispatch])

    if ((files.length === 0 && isTrashLoading) || timelineID === undefined) {
        return <RippleLoaderPage />
    }

    if (files.length === 0 && timelineID) {
        const header = _('trash_no_files')
        const subHeader = (
            <div>
                <div>{_('trash_delete_info')}</div>
                <div>{_('trash_restore_info')}</div>
            </div>
        )
        return (
            <EmptyStatePage
                illustration={<img src={trashIllustration} alt="Empty trash" />}
                header={header}
                subHeader={subHeader}
            />
        )
    }

    return (
        <ContentWrapper ref={contentElementRef}>
            <Header width={groupStyle.width}>
                {_('trash_deleted')}
                {trashTotalItemCount !== undefined && (
                    <Count>{`${trashTotalItemCount} ${getStringByAmount(trashTotalItemCount, _('item'), _('items'))}`}</Count>
                )}
            </Header>
            <ImageGroupList
                isInSelectMode={true}
                selectGroupDisabled={true}
                imagesGrouped={trashImageGroups}
                groupStyle={groupStyle}
                transformHeader={(key: string) => {
                    if (key === TrashGroupKey.DeletedSoon) {
                        return _('permanently_deleted_soon')
                    }
                    if (key === TrashGroupKey.PermanetlyDeletedSoon) {
                        return _('recently_deleted')
                    }
                }}
                getAdditionalBottomAreaElements={(
                    fileID: FileID,
                    _i: number,
                ) => {
                    const file = files.find((f) => f.id === fileID)
                    if (file !== undefined) {
                        return (
                            <DeletionNotice
                                daysRemaining={file.daysRemaining}
                            />
                        )
                    }
                }}
            />
            {hasMoreToFetch && (
                <LoaderWrapper>
                    <RippleLoader />
                </LoaderWrapper>
            )}
            <SelectionActionDialogPlacement
                jobID={timelineID}
                selectionOverride={selectedFileIDs}
            />
            <FilesOptionsPlacement
                jobID={timelineID}
                context={'TrashPage'}
                files={selectedFileIDs}
            />
        </ContentWrapper>
    )
}

type AllFilesActionPrompt =
    | { type: 'restore_all_progress' }
    | {
          type: 'restore_all_complete'
          successFilesResult: FilesRestorationSucceededPayload
      }
    | {
          type: 'restore_all_incomplete'
          successFilesResult?: FilesRestorationSucceededPayload
          failedFilesResult: FilesRestorationFailedPayload
      }
    | { type: 'download_all_progress' }

export const TrashPhotosPage = () => {
    const dispatch = useDispatch()
    const allFetchedTrashFileInfos = useSelector(getAllFetchedTrashFileInfos)
    const fetchedTrashFileIDs = useSelector(getAllTrashFileIDsForRestore)
    const totalItemCount = useSelector(getTrashTotalItemCount)
    const timelineID = useSelector(getTimelineJobID)
    const inAppMode = useSelector(getIsUserFromInsideApp)

    const [allFilesActionPrompt, setAllFilesActionPrompt] =
        useState<AllFilesActionPrompt | null>(null)

    useEffect(() => {
        loadTrashContent(dispatch, 0, TRASH_FETCH_LIMIT)
    }, [dispatch])

    return (
        <PageWrapper
            hideFooter={inAppMode}
            navBar={
                <TrashPhotoNavBar
                    inAppMode={inAppMode}
                    onRestoreAllConfirmed={async () => {
                        if (
                            timelineID === undefined ||
                            totalItemCount === undefined
                        ) {
                            return
                        }

                        setAllFilesActionPrompt({
                            type: 'restore_all_progress',
                        })

                        const { successFilesResult, failedFilesResult } =
                            await restoreAllTrashFiles(dispatch, {
                                timelineID,
                                limit: TRASH_FETCH_LIMIT,
                                totalItemCount,
                                fetchedTrashFileIDs,
                            })

                        if (failedFilesResult === undefined) {
                            setAllFilesActionPrompt({
                                type: 'restore_all_complete',
                                successFilesResult: successFilesResult!,
                            })
                        } else {
                            setAllFilesActionPrompt({
                                type: 'restore_all_incomplete',
                                successFilesResult,
                                failedFilesResult,
                            })
                        }
                    }}
                    onDownloadAllConfirmed={async () => {
                        if (
                            timelineID === undefined ||
                            totalItemCount === undefined
                        ) {
                            return
                        }

                        setAllFilesActionPrompt({
                            type: 'download_all_progress',
                        })

                        await downloadAllTrashFiles(dispatch, {
                            timelineID,
                            limit: TRASH_FETCH_LIMIT,
                            totalItemCount,
                            fetchedTrashFileInfos: allFetchedTrashFileInfos,
                            zipFileName: _('deleted_items'),
                        })

                        setAllFilesActionPrompt(null)
                    }}
                />
            }>
            <TrashPhotosContent />
            <Prompt.Root
                open={allFilesActionPrompt !== null}
                onOpenChange={(open) => {
                    if (!open) {
                        setAllFilesActionPrompt(null)
                    }
                }}>
                {allFilesActionPrompt?.type === 'restore_all_progress' && (
                    <RestoreProgressPrompt isInsideApp={inAppMode} />
                )}
                {allFilesActionPrompt?.type === 'restore_all_complete' && (
                    <RestoreAllCompletedPrompt
                        handleConfirm={() => {
                            setAllFilesActionPrompt(null)
                            dispatch(
                                FilesRestorationSucceeded(
                                    allFilesActionPrompt.successFilesResult,
                                ),
                            )
                        }}
                    />
                )}
                {allFilesActionPrompt?.type === 'restore_all_incomplete' && (
                    <RestoreAllIncompletePrompt
                        failedFilesCount={
                            allFilesActionPrompt.failedFilesResult.files.length
                        }
                        handleConfirm={() => {
                            setAllFilesActionPrompt(null)

                            if (allFilesActionPrompt.successFilesResult) {
                                dispatch(
                                    FilesRestorationSucceeded(
                                        allFilesActionPrompt.successFilesResult,
                                    ),
                                )
                            }

                            if (allFilesActionPrompt.failedFilesResult) {
                                dispatch(
                                    FilesRestorationFailed(
                                        allFilesActionPrompt.failedFilesResult,
                                    ),
                                )
                            }
                        }}
                    />
                )}
                {allFilesActionPrompt?.type === 'download_all_progress' && (
                    <DownloadProgressPrompt isInsideApp={inAppMode} />
                )}
            </Prompt.Root>
        </PageWrapper>
    )
}
