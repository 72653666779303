import { getRange } from './arrayUtils'

export const someTime = (delay: number): Promise<void> =>
    new Promise((ok, _err) => setTimeout(ok, delay))

// Utility that works like Promise.all(elems.map(generator)), but only have maxPending promises pending at a time
export const managedPromiseAll = <T, U>(
    elems: T[],
    generator: (t: T, index: number) => Promise<U>,
    maxPending = 5,
): Promise<U[]> => {
    return new Promise((ok, err) => {
        const results: U[] = []
        let startedCount = 0
        let resolvedCount = 0
        let hasFailed = false

        const fail = (e: unknown) => {
            if (!hasFailed) {
                hasFailed = true
                err(e)
            }
        }

        const start = (index: number) => {
            generator(elems[index], index).then((r: U) => {
                results[index] = r
                resolvedCount++
                if (resolvedCount === elems.length) {
                    ok(results)
                } else {
                    next()
                }
            }, fail)
        }

        const next = () => {
            if (!hasFailed && startedCount < elems.length) {
                start(startedCount)
                startedCount++
            }
        }

        getRange(maxPending).forEach(next)
    })
}

export const makeDecomposedPromise = <T>(): {
    promise: Promise<T>
    resolve: (t: T) => unknown
    reject: () => unknown
} => {
    let resolve: (t: T) => unknown = () => {
        /*override by promise creator*/
    }
    let reject: () => unknown = () => {
        /*override by promise creator*/
    }
    const promise = new Promise<T>((ok, err) => {
        resolve = ok
        reject = err
    })
    return { promise, resolve, reject }
}

// not needed after upgrading to ESNEXT with Promise.finally()
export const Promise_finally = (
    promise: Promise<void>,
    finallyCB: () => unknown,
) => promise.then(finallyCB, finallyCB)

export class AbortablePromise<T> {
    private controller: AbortController
    private promise: Promise<T>

    constructor(executor: (signal: AbortSignal) => Promise<T>) {
        this.controller = new AbortController()
        const { signal } = this.controller

        // Wrap the executor to handle abort behavior
        this.promise = new Promise<T>((resolve, reject) => {
            executor(signal)
                .then(resolve)
                .catch((err) => {
                    // Handle explicit abort signal as a special case
                    if (signal.aborted) {
                        reject(new DOMException('Aborted', 'AbortError'))
                    } else {
                        reject(err)
                    }
                })
        })
    }

    /**
     * Aborts the underlying request and invokes custom handlers.
     */
    abort(): void {
        this.controller.abort()
    }

    /**
     * Returns the wrapped promise for external use.
     */
    getPromise(): Promise<T> {
        return this.promise
    }
}
