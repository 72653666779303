import styled from 'styled-components'
import { getServiceProvider } from '~/API/HostProvider'
import { getSupportedExtensions } from '~/API/job'

const Wrapper = styled.div`
    font-size: 20px;
    padding: 16px;
`

const Column = styled.fieldset`
    display: flex;
    flex-direction: column;
    gap: 8px;

    button {
        margin-top: 8px;
        width: max-content;
        padding: 0 3em;
        height: 32px;
    }
`

export const AdvancedSettingsPage = () => {
    const handleGetExtensions = () => {
        getSupportedExtensions().then((res) => {
            console.log(res)
        })
    }

    const deleteStripeTestData = async () => {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.deleteStripeTestData()
        if (response.status === 200) {
            alert('Stripe data deletion succeeded!')
        } else {
            console.log('Response from delete endpoint', response)
            alert(
                'Stripe data deletion failed. See the console for the response',
            )
        }
    }

    return (
        <Wrapper>
            <Column>
                <legend>Stripe Testing</legend>
                <button type="button" onClick={deleteStripeTestData}>
                    Delete Stripe Data
                </button>
            </Column>
            <Column>
                <legend>Supported extensions</legend>
                <button type="button" onClick={handleGetExtensions}>
                    Get extensions (see console)
                </button>
            </Column>
        </Wrapper>
    )
}
