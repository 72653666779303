import {
    CAPBAKReactionType,
    CAPBAKRollbackResultStatus,
    CAPBAKUploadPolicy,
} from '@capture/client-api/src/schemas/data-contracts'
import type {
    CAPBAKCommentResponse,
    CAPBAKDataProtectionRequestResult,
    CAPBAKFilesDedupPostParams,
    CAPBAKJobSetPermissionsResponse,
    CAPBAKPartialMetadataResponse,
    CAPBAKStripePurchasePostParams,
    CAPBAKUploadPostParams,
    CAPBAKPrivacyMode,
    CAPBAKPublishShareResponse,
    CAPBAKPermissionsPostParams,
    CAPBAKSetReactionResponse,
    CAPBAKStripeDeleteCCResponse,
    CAPBAKSyncUploadExistsResponse,
    CAPBAKTimelineMonths,
    CAPBAKUsersResponse,
    CAPBAKRestoreAlbumsResponse,
    CAPBAKTrashcanAlbumItem,
    CAPBAKUserStatsEventModel,
    CAPBAKAPIKey,
    CAPBAKPublishPostParams,
    CAPBAKFilesDetailParams,
    CAPBAKRollbackMultiResponse,
} from '@capture/client-api/src/schemas/data-contracts'

import { AccountInfo } from '@capture/client-api/src/schemas/AccountInfo'
import { AccountAttribute } from '@capture/client-api/src/schemas/AccountAttribute'
import { Name } from '@capture/client-api/src/schemas/Name'
import { Jobs } from '@capture/client-api/src/schemas/Jobs'
import { TrashCan } from '@capture/client-api/src/schemas/TrashCan'
import { TrashCanAlbums } from '@capture/client-api/src/schemas/TrashCanAlbums'
import { EmptyTrashCan } from '@capture/client-api/src/schemas/EmptyTrashCan'
import { RollbackAlbums } from '@capture/client-api/src/schemas/RollbackAlbums'
import { DeleteAlbumsFromTrashCan } from '@capture/client-api/src/schemas/DeleteAlbumsFromTrashCan'
import { Options } from '@capture/client-api/src/schemas/Options'
import { Devices } from '@capture/client-api/src/schemas/Devices'
import { Logout } from '@capture/client-api/src/schemas/Logout'
import { UserGrants } from '@capture/client-api/src/schemas/UserGrants'
import { DataProtection } from '@capture/client-api/src/schemas/DataProtection'
import { UserStatisticsEvents } from '@capture/client-api/src/schemas/UserStatisticsEvents'
import { ClientStats as ClientStatsService } from '@capture/client-api/src/schemas/ClientStats'
import { ApproveTos } from '@capture/client-api/src/schemas/ApproveTos'
import { Test } from '@capture/client-api/src/schemas/Test'
import { SupportedExtensions } from '@capture/client-api/src/schemas/SupportedExtensions'

import type {
    AccountInfoResponse,
    ChangesAndAsyncUploadStatus,
    ClientStats,
    CreateJobProperties,
    DataProtectionConsentData,
    DeletedFile,
    DeletedFileResponse,
    ExtraJobQueryParamsOf,
    ExtraQueryParamsOf,
    JobChange,
    JobFile,
    JobInfoResponse,
    StoryJobInfoResponse,
    StripePaymentInfo,
    StripeProductsResponse,
    StripePurchaseResponse,
    UserAccountAttributeKey,
    UpsertUserAccountAttribute,
    StripeProductsMode,
    JobListResponse,
    RequiredCommonQueryParams,
    UserGrant,
    UserAccountAttribute,
    DataProtectionUpdateRequestResult,
} from '~/@types/backend-types'
import {
    PRODUCT_NAME,
    getCurrentLangDefinition,
    getDeviceProps,
} from '~/config/constants'
import { canReadFileContent } from '~/state/uploader/uploadFileURL'
import { UploadError, UploadFailedReason } from '~/state/uploader/uploadQueue'
import '~/API/apiUtils'
import { chunks } from '~/utilities/arrayUtils'
import { managedPromiseAll } from '~/utilities/promises'
import type { ServiceDict } from '../externals'
import type { FetchObject } from '../FetchObject'
import {
    HostUrl,
    customFetch,
    downloadThroughAnchor,
    sendPOSTRedirect,
} from '../toolbox'

export class AppService {
    private hostUrl: HostUrl
    private downloadHost: HostUrl
    private commonQueryParams: RequiredCommonQueryParams & {
        'foreign-auth'?: string
    }

    private accountInfoClient: AccountInfo
    private accountAttributeClient: AccountAttribute
    private nameClient: Name
    private jobsClient: Jobs
    private trashCanClient: TrashCan
    private trashCanAlbumsClient: TrashCanAlbums
    private emptyTrashCanClient: EmptyTrashCan
    private rollbackAlbumsClient: RollbackAlbums
    private deleteAlbumsFromTrashCanClient: DeleteAlbumsFromTrashCan
    private optionsClient: Options
    private devicesClient: Devices
    private logoutClient: Logout
    private userGrantsClient: UserGrants
    private dataProtectionClient: DataProtection
    private userStatisticsClient: UserStatisticsEvents
    private clientStatsClient: ClientStatsService
    private approveTosClient: ApproveTos
    private testClient: Test
    private supportedExtensionsClient: SupportedExtensions

    constructor(
        private fetchObject: FetchObject,
        hosts: ServiceDict,
        authToken: string,
        foreignAuth?: string,
    ) {
        this.commonQueryParams = {
            // TODO: Remove optional `import.meta.env`,
            // when we can load vite env variables through `vite-register`.
            // Currently `vite-register` only loads `.env` from root, not `tests/.env`
            key: (import.meta.env?.VITE_API_KEY || 'testing') as CAPBAKAPIKey,
            client_v: (import.meta.env?.VITE_VERSION || 'testing') as string,
            device_id: getDeviceProps().device_id,
        }

        /**
         * Foreign auth
         * ---------------
         * Foreign auth is used when we fetch an job that doesnt belong to the logged in user
         * In those cases, the Auth token becomes foreign-auth
         * If the job is password protected, the job password is sent in place of the auth token
         *
         * @example Accessing a job with user auth:
         * Authorization: `Basic ${btoa(`USER:${authToken}`)}`
         *
         * @example Accessing a password protected job with foreign auth:
         * Authorization: `Basic ${btoa(`FOREIGN+PUBLIC:${authToken} ${password}`)}`
         */
        // TODO CAPWEB-3123: inject foreign auth only where needed, we will be able to do this when we get app service per session rather than per job
        if (foreignAuth) {
            this.commonQueryParams['foreign-auth'] = foreignAuth
        }

        // we maintain the authToken here for the endpoints which are not migrated
        // and still use the host provider
        this.hostUrl = new HostUrl(hosts.appHost, {
            ...this.commonQueryParams,
            auth: authToken,
        })
        this.downloadHost = new HostUrl(hosts.downloadHost, {
            ...this.commonQueryParams,
            auth: authToken,
        })

        const commonParams = {
            baseUrl: `https://${hosts.appHost}/st/4`,
            baseApiParams: {
                headers: {
                    Authorization: `Basic ${btoa(`USER:${authToken}`)}`,
                },
            },
            customFetch, // custom fetch handles network errors
        }

        // Account data endpoints
        this.accountInfoClient = new AccountInfo(commonParams)

        this.accountAttributeClient = new AccountAttribute(commonParams)

        // Job endpoints
        this.jobsClient = new Jobs(commonParams)

        // Trash endpoints
        this.trashCanClient = new TrashCan(commonParams)
        this.emptyTrashCanClient = new EmptyTrashCan(commonParams)
        this.trashCanAlbumsClient = new TrashCanAlbums(commonParams)
        this.deleteAlbumsFromTrashCanClient = new DeleteAlbumsFromTrashCan(
            commonParams,
        )
        this.rollbackAlbumsClient = new RollbackAlbums(commonParams)

        // User data endpoints
        this.nameClient = new Name(commonParams)
        this.optionsClient = new Options(commonParams)
        this.userGrantsClient = new UserGrants(commonParams)
        this.dataProtectionClient = new DataProtection(commonParams)
        this.userStatisticsClient = new UserStatisticsEvents(commonParams)
        this.clientStatsClient = new ClientStatsService(commonParams)
        this.approveTosClient = new ApproveTos(commonParams)
        this.devicesClient = new Devices(commonParams)
        this.logoutClient = new Logout(commonParams)

        // Dev util endpoints
        this.testClient = new Test(commonParams)
        this.supportedExtensionsClient = new SupportedExtensions(commonParams)
    }

    public async getAccountInfo(): Promise<AccountInfoResponse> {
        const response = await this.accountInfoClient
            .accountInfoGet({
                ...this.commonQueryParams,
                app_lang: getCurrentLangDefinition().appLang ?? 'en',
            })
            .unwrapError()

        return response.data as AccountInfoResponse
    }

    public async setProfileName(name: string) {
        const response = await this.nameClient
            .namePost({
                ...this.commonQueryParams,
                name,
            })
            .unwrapError()

        return response.data
    }

    public async setProfilePictureFromBlob(blob: Blob): Promise<Response> {
        const url = this.hostUrl.getPath(`/st/4/profile/picture`, {
            path: 'profile.jpg',
        })
        const payload = new FormData()
        payload.append('file', blob)

        const request = new XMLHttpRequest()
        await new Promise((success, error) => {
            request.addEventListener('load', success)
            request.addEventListener('error', error)
            request.addEventListener('abort', error)

            request.open('POST', url)
            request.send(payload)
        })
        if (request.status !== 201) {
            throw new Error(request.responseText)
        }
        return request.response
    }

    public async getUserOption(optionKey: string) {
        const response = await this.optionsClient
            .optionsDetailByName(
                {
                    ...this.commonQueryParams,
                    name: optionKey,
                },
                { format: 'text' },
            )
            .unwrapError()

        return response.data
    }

    public async setUserOption(optionKey: string, value: string) {
        return this.optionsClient
            .optionsUpdateByName({
                ...this.commonQueryParams,
                name: optionKey,
                value,
            })
            .unwrapError()
    }

    public async deleteUserOption(optionKey: string) {
        return this.optionsClient
            .optionsDeleteByName({
                ...this.commonQueryParams,
                name: optionKey,
            })
            .unwrapError()
    }

    public async getConnectedDevices() {
        const response = await this.devicesClient
            .devicesGet(this.commonQueryParams)
            .unwrapError()

        return response.data
    }

    public async deleteConnectedDevice(deviceID: string) {
        await this.devicesClient
            .devicesDeleteByDeviceId({
                ...this.commonQueryParams,
                deviceId: deviceID,
            })
            .unwrapError()
    }

    public logout() {
        return this.logoutClient.logoutPost(this.commonQueryParams)
    }

    public async getJobList(): Promise<JobListResponse> {
        const response = await this.jobsClient
            .jobsGet({
                ...this.commonQueryParams,
                stories: true,
                include_details: true,
            })
            .unwrapError()

        return response.data as JobListResponse
    }

    public async getDefaultJob() {
        const response = await this.jobsClient
            .defaultGet(this.commonQueryParams)
            .unwrapError()

        return response.data
    }

    public async getTimelineMonths(
        jobID: JobID,
    ): Promise<CAPBAKTimelineMonths> {
        const response = await this.jobsClient
            .timelineMonthsDetail({
                ...this.commonQueryParams,
                jobUuid: jobID,
            })
            .unwrapError()

        return response.data
    }

    public async getJobInfo(jobID: JobID): Promise<JobInfoResponse> {
        const response = await this.jobsClient
            .infoDetail({
                ...this.commonQueryParams,
                include_details: true,
                jobUuid: jobID,
            })
            .unwrapError()

        return response.data as JobInfoResponse
    }

    public async getJobChanges(
        jobID: JobID,
        since: number,
    ): Promise<JobChange[]> {
        const response = await this.jobsClient
            .changesDetail({
                ...this.commonQueryParams,
                jobUuid: jobID,
                since,
            })
            .unwrapError()

        return response.data as JobChange[]
    }

    public async getJobContributors(
        jobID: JobID,
        includeProfilePicture: boolean,
    ): Promise<CAPBAKUsersResponse> {
        const response = await this.jobsClient
            .usersDetail({
                ...this.commonQueryParams,
                jobUuid: jobID,
                include_profile_url: includeProfilePicture,
            })
            .unwrapError()

        return response.data
    }

    public async createJob(
        opts: CreateJobProperties,
    ): Promise<StoryJobInfoResponse> {
        const response = await this.jobsClient
            .jobsPost({
                ...this.commonQueryParams,
                ...opts,
            })
            .unwrapError()

        return response.data as StoryJobInfoResponse
    }

    public async publishJob(
        jobID: JobID,
        opts: ExtraJobQueryParamsOf<CAPBAKPublishPostParams>,
    ): Promise<CAPBAKPublishShareResponse> {
        const response = await this.jobsClient
            .publishPost({
                ...this.commonQueryParams,
                ...opts,
                jobUuid: jobID,
            })
            .unwrapError()

        return response.data
    }

    public async deleteJob(jobID: JobID) {
        await this.jobsClient
            .jobsDeleteByJobUuid({
                ...this.commonQueryParams,
                jobUuid: jobID,
            })
            .unwrapError()
    }

    public async getFiles(
        jobID: string,
        options: ExtraJobQueryParamsOf<CAPBAKFilesDetailParams>,
    ): Promise<{
        lastEventSerial: number
        files: JobFile[]
    }> {
        const response = await this.jobsClient
            .filesDetail({
                ...this.commonQueryParams,
                ...options,
                jobUuid: jobID,
            })
            .unwrapError()

        return {
            files: response.data as JobFile[],
            lastEventSerial: parseInt(
                response.headers.get('x-last-event-serial') || '-1',
                10,
            ),
        }
    }

    public async getDeletedFiles(
        offset: number,
        limit: number,
    ): Promise<DeletedFileResponse> {
        const response = await this.trashCanClient
            .trashCanGet({
                ...this.commonQueryParams,
                total_item_count: true,
                offset,
                limit,
            })
            .unwrapError()

        const totalItems = response.headers.get('capture-total-items')

        return {
            deletedFiles: response.data as DeletedFile[],
            totalItemCount: totalItems
                ? parseInt(totalItems, 10)
                : response.data.length,
        }
    }

    public async getDeletedAlbums(): Promise<CAPBAKTrashcanAlbumItem[]> {
        const response = await this.trashCanAlbumsClient
            .trashCanAlbumsGet(this.commonQueryParams)
            .unwrapError()

        return response.data
    }

    public async getFileMetadata(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKPartialMetadataResponse> {
        const response = await this.jobsClient
            .metadataDetailByJobUuidAndFileUuid({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
            })
            .unwrapError()

        return response.data
    }

    public async dedupFile(
        targetJob: JobID,
        opts: ExtraJobQueryParamsOf<CAPBAKFilesDedupPostParams>,
    ): Promise<string> {
        const response = await this.jobsClient
            .filesDedupPost({
                ...this.commonQueryParams,
                ...opts,
                jobUuid: targetJob,
                want_json: true,
            })
            .unwrapError()
            .catch((_error) => {
                throw new UploadError(
                    UploadFailedReason.FileError,
                    'File rejected by server',
                )
            })

        return response.data.uuid
    }

    public async copyFilesToDefaultJob(
        sourceJobID: JobID,
        fileIDs: FileID[],
    ): Promise<void> {
        await this.jobsClient
            .keepFilesPost(
                {
                    ...this.commonQueryParams,
                    jobUuid: sourceJobID,
                },
                fileIDs.join('\n'),
            )
            .unwrapError()
    }

    public async copyJobToDefaultJob(sourceJobID: JobID): Promise<void> {
        await this.jobsClient
            .keepAllFilesPost({
                ...this.commonQueryParams,
                jobUuid: sourceJobID,
            })
            .unwrapError()
    }

    public async uploadFile(
        jobID: string,
        path: string,
        file: File | Blob,
        mtime?: number,
        opt_request?: XMLHttpRequest,
    ): Promise<CAPBAKSyncUploadExistsResponse> {
        const params: ExtraJobQueryParamsOf<CAPBAKUploadPostParams> = {
            path,
            mtime,
            policy: CAPBAKUploadPolicy.NoDuplicates,
        }
        const url = this.hostUrl.getPath(`/st/4/jobs/${jobID}/upload`, params)
        const payload = new FormData()
        payload.append('file', file)

        const request = opt_request || new XMLHttpRequest()
        try {
            await new Promise((success, error) => {
                request.addEventListener('load', success)
                request.addEventListener('error', error)
                request.addEventListener('abort', error)

                request.open('POST', url)
                request.send(payload)
            })
        } catch (e) {
            // CAPWEB-2088: Upload can fail because local files have been removed
            if (!(await canReadFileContent(file))) {
                throw new UploadError(
                    UploadFailedReason.LocalFileUnavailable,
                    'File no longer exists',
                )
            }

            throw new UploadError(
                UploadFailedReason.NetworkError,
                'Network error',
            )
        }

        if (
            request.status ===
            413 /* Request Entity Too Large => Out of storage */
        ) {
            throw new UploadError(
                UploadFailedReason.OutOfStorage,
                'Out of storage',
            )
        }

        return JSON.parse(request.responseText)
    }

    public async deleteFile(jobID: JobID, fileID: FileID) {
        const response = await this.jobsClient
            .filesByIdDeleteByJobUuidAndFileUuid({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
            })
            .unwrapError()

        return response.data
    }

    public async emptyTrashCan(fileid: FileID): Promise<void> {
        await this.emptyTrashCanClient
            .emptyTrashCanPost({
                ...this.commonQueryParams,
                fileid,
            })
            .unwrapError()
    }

    public async restoreMultipleFiles(
        jobID: JobID,
        fileIDs: FileID[],
        onProgress?: (progress: number) => void,
    ): Promise<CAPBAKRollbackMultiResponse> {
        // 200 fileIDs max for each multiRollbackPost from backend end point,
        // split fileIDs into chunks with max 200 fileIDs in each chunk
        const fileIDsChunks = chunks(fileIDs, 200)
        const chunkCount = fileIDsChunks.length
        const responses: CAPBAKRollbackMultiResponse[] = []

        let finished = 0
        await managedPromiseAll(fileIDsChunks, async (chunk) => {
            try {
                const response = await this.jobsClient
                    .multiRollbackPost(
                        {
                            ...this.commonQueryParams,
                            jobUuid: jobID,
                        },
                        { file_uuids: chunk },
                    )
                    .unwrapError()
                responses.push(response.data)
            } catch (error) {
                responses.push({
                    results: chunk.map((fileID) => ({
                        file_uuid: fileID,
                        status: CAPBAKRollbackResultStatus.Error,
                    })),
                })
            } finally {
                finished += 1
                if (onProgress) {
                    onProgress(finished / chunkCount)
                }
            }
        })

        return responses.reduce(
            (acc, r) => {
                const results = acc.results.concat(r.results)

                let quota = acc.quota

                if (r.quota !== undefined) {
                    quota = {
                        used_space: Math.max(
                            acc.quota?.used_space ?? 0,
                            r.quota.used_space || 0,
                        ),
                        max_space: r.quota.max_space,
                    }
                }

                return {
                    results,
                    quota,
                }
            },
            {
                results: [],
                quota: undefined,
            } as CAPBAKRollbackMultiResponse,
        )
    }

    /** Restores all albums in trash  */
    public async restoreAlbums(
        jobIDs: string[],
    ): Promise<CAPBAKRestoreAlbumsResponse> {
        const response = await this.rollbackAlbumsClient
            .rollbackAlbumsPost(this.commonQueryParams, { job_uuids: jobIDs })
            .unwrapError()

        return response.data
    }

    /** Permanently deletes all albums in trash */
    public async emptyTrashCanAlbums(
        jobIDs: string[],
    ): Promise<CAPBAKRestoreAlbumsResponse> {
        const response = await this.deleteAlbumsFromTrashCanClient
            .deleteAlbumsFromTrashCanDelete(this.commonQueryParams, {
                job_uuids: jobIDs,
            })
            .unwrapError()

        return response.data
    }

    public async addComment(
        jobID: JobID,
        fileID: string,
        comment: string,
    ): Promise<CAPBAKCommentResponse> {
        const response = await this.jobsClient
            .filesByIdCommentsPost({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
                comment,
            })
            .unwrapError()

        return response.data
    }

    public async deleteComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
    ): Promise<CAPBAKCommentResponse> {
        const response = await this.jobsClient
            .filesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuid({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
                commentUuid: commentID,
            })
            .unwrapError()

        return response.data
    }

    public async editComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
        commentText: string,
    ): Promise<CAPBAKCommentResponse> {
        const params = {
            ...this.commonQueryParams,
            jobUuid: jobID,
            fileUuid: fileID,
            commentUuid: commentID,
            comment: commentText,
        }
        const response = await this.jobsClient
            .filesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuid(params)
            .unwrapError()

        return response.data
    }

    public async subscribeToJob(jobID: JobID) {
        await this.jobsClient
            .subscribePost({
                ...this.commonQueryParams,
                jobUuid: jobID,
            })
            .unwrapError()
    }

    public async unsubscribeFromJob(jobID: JobID) {
        await this.jobsClient
            .unsubscribePost({
                ...this.commonQueryParams,
                jobUuid: jobID,
            })
            .unwrapError()
    }

    public static getDownloadOptions(
        type: 'download' | 'export' | 'takeout',
        zipFileName: string,
        convertHEIC: boolean = false,
    ) {
        switch (type) {
            case 'download':
                return {
                    flattened: '1',
                    heic_to_jpeg: convertHEIC ? '1' : '0',
                    master_only: '1',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'export':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'takeout':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '1',
                    zip_filename: zipFileName,
                }
        }
    }

    /**
     * Downloads files from a specified job based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {FileID[]} fileIDs - An array of file IDs to download.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the files have been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     a. single file:
     *         uses `downloadThroughAnchor` with `GET` request to download the file through `a` tag.
     *         download single file thumbnail, for HEIC file it will convert to JPEG.
     *     b. multiple files:
     *         uses `sendPOSTRedirect` with `POST` request with `fileIDs` as body to download the files through `form` submit.
     *         download all files thumbnail as archive, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format as archive.
     * 2. downloadType === 'takeout':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format with its subrevisions as archive.
     *
     */
    public async downloadFilesFromJob(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        fileIDs: FileID[],
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        if (fileIDs.length === 1) {
            downloadThroughAnchor(
                this.getFilePreviewURL(
                    jobID,
                    fileIDs[0],
                    downloadType === 'download' && hasHEIC,
                ),
            )
            return
        }

        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
            fileIDs,
        )
    }
    /**
     * Downloads all files from a specified job as an archive based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the archive has been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     uses `downloadThroughAnchor` with `GET` request to download all files from job through `a` tag.
     *     download all files thumbnail from target job, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     download all files from target job in original format as archive.
     * 2. downloadType === 'takeout':
     *     download all files from target job in original format with its subrevisions as archive.
     *
     */
    public async downloadJobAsArchive(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        downloadThroughAnchor(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
        )
    }

    public async getFileBlobFromId(
        jobID: JobID,
        fileID: FileID,
        toJpeg?: boolean,
    ): Promise<Blob> {
        const response = await this.jobsClient
            .filesByIdDetailByJobUuidAndFileUuid(
                {
                    ...this.commonQueryParams,
                    jobUuid: jobID,
                    fileUuid: fileID,
                    to_jpeg: toJpeg,
                },
                { format: 'blob' },
            )
            .unwrapError()

        return response.blob()
    }

    public getFilePreviewURL(
        jobID: JobID,
        fileID: FileID,
        thumbnail = false,
    ): URLstring {
        return this.hostUrl.getPath(
            `/st/4/jobs/${jobID}/files_by_id/${fileID}`,
            thumbnail ? { to_jpeg: '1' } : undefined,
        )
    }

    public downloadFilesFromTrashAsArchive(
        jobID: JobID,
        files: FileID[],
        zipFileName: string,
    ): Promise<void> {
        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_from_trash_as_archive`,
                {
                    flattened: 1,
                    zip_filename: zipFileName,
                },
            ),
            files,
        )
    }

    public async setJobName(jobID: JobID, name: string) {
        await this.jobsClient
            .namePost({
                ...this.commonQueryParams,
                jobUuid: jobID,
                name,
            })
            .unwrapError()
    }

    public async setCoverPhoto(jobID: JobID, fileID: FileID) {
        await this.jobsClient
            .coverPost({
                ...this.commonQueryParams,
                jobUuid: jobID,
                id: fileID,
            })
            .unwrapError()
    }

    public async setPermissionsforJob(
        jobID: JobID,
        permissions: ExtraJobQueryParamsOf<CAPBAKPermissionsPostParams>,
    ): Promise<CAPBAKJobSetPermissionsResponse> {
        const response = await this.jobsClient
            .permissionsPost({
                ...this.commonQueryParams,
                ...permissions,
                jobUuid: jobID,
            })
            .unwrapError()

        return response.data
    }

    public async setPrivacyModeForJob(
        jobID: JobID,
        mode: CAPBAKPrivacyMode,
    ): Promise<CAPBAKJobSetPermissionsResponse> {
        const response = await this.jobsClient
            .privacyModePost({
                ...this.commonQueryParams,
                jobUuid: jobID,
                mode,
            })
            .unwrapError()

        return response.data
    }

    public async loveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKSetReactionResponse> {
        const response = await this.jobsClient
            .filesByIdReactionPost({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
                reaction: CAPBAKReactionType.Love,
            })
            .unwrapError()

        return response.data
    }

    public async unLoveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<CAPBAKSetReactionResponse> {
        /**
         * This endpoint call removes the file reaction, regardless of which
         * We currently assume that there is only one reaction per file:
         * - You can favourite timeline files
         * - You can love album files
         */
        const response = await this.jobsClient
            .filesByIdReactionDelete({
                ...this.commonQueryParams,
                jobUuid: jobID,
                fileUuid: fileID,
            })
            .unwrapError()

        return response.data
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public getStripeProducts(
        mode: StripeProductsMode /* 'test'|'production'*/,
    ): Promise<StripeProductsResponse> {
        return this.fetchObject
            .get(
                this.hostUrl.getPath('/st/4/stripe_products', { [mode]: true }),
            )
            .asJson()
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public stripePurchase({
        plan,
        token,
        card,
    }: ExtraQueryParamsOf<CAPBAKStripePurchasePostParams>): Promise<StripePurchaseResponse> {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/stripe_purchase', {
                    plan,
                    token,
                    card,
                }),
            )
            .asJson<StripePurchaseResponse>()
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public validateStripePurchase(
        subscription_id: string,
        payment_intent_id: string,
    ) {
        return this.fetchObject
            .get(
                this.hostUrl.getPath(
                    `/st/4/stripe_purchase/${subscription_id}/validate_purchase`,
                    { payment_intent_id },
                ),
            )
            .rawResponse()
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public postStripePaymentMethod(token: string, card: string) {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/stripe_payment_method', {
                    token,
                    card,
                }),
            )
            .rawResponse()
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public getStripePaymentMethodInfo() {
        return this.fetchObject
            .get(this.hostUrl.getPath('/st/4/stripe_payment_method'))
            .asJson<StripePaymentInfo>()
    }

    public async getUserGrants() {
        const response = await this.userGrantsClient
            .userGrantsGet(this.commonQueryParams)
            .unwrapError()

        return response.data.result.grants as UserGrant[]
    }

    public executeGrantLink(link: string) {
        return customFetch(link, { method: 'POST' })
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public updateCurrentPlan(plan: string) {
        return this.fetchObject
            .post(
                this.hostUrl.getPath('/st/4/update_stripe_purchase', { plan }),
            )
            .rawResponse()
    }

    // TODO(CAPWEB-2959): move this method to a StripeService
    public deleteUserCreditCard(
        card_id: string,
    ): Promise<CAPBAKStripeDeleteCCResponse> {
        return this.fetchObject
            .delete(
                this.hostUrl.getPath('/st/4/stripe_user_credit_card', {
                    card_id,
                }),
            )
            .asJson<CAPBAKStripeDeleteCCResponse>()
    }

    public async setAlbumAttribute(
        albumID: JobID,
        attributeName: string,
        attributeValue: string,
    ) {
        return this.jobsClient
            .attributeUpdateByJobUuidAndAttribute({
                ...this.commonQueryParams,
                jobUuid: albumID,
                attribute: attributeName,
                value: attributeValue,
            })
            .unwrapError()
    }

    public async getDataProtectionConsentValues() {
        const response = await this.dataProtectionClient
            .consentValuesGet(this.commonQueryParams)
            .unwrapError()

        // we unwrap the data from the response, datav2 is used in mobile client
        return response.data.data as DataProtectionConsentData
    }

    public async updateDataProtectionConsentValues(
        values: Partial<DataProtectionConsentData>,
    ) {
        const response = await this.dataProtectionClient
            .consentValuesPost(this.commonQueryParams, values)
            .unwrapError()

        return response.data as DataProtectionUpdateRequestResult
    }

    public async getRequestDataAccessAvailability(): Promise<CAPBAKDataProtectionRequestResult> {
        // Throws 503 if call to request_data_access will yield error due to rate-limiting
        const response = await this.dataProtectionClient
            .requestDataAccessAvailabilityGet(this.commonQueryParams)
            .unwrapError()

        return response.data
    }

    public downloadDataProtectionRequestedDataAccess() {
        window.location.href = this.hostUrl.getPath(
            '/st/4/data_protection/request_data_access',
        )
    }

    public async sendDataProtectionRequestForAccountDeletion() {
        return this.dataProtectionClient
            .requestAccountDeletionPost(this.commonQueryParams)
            .unwrapError()
    }

    public async postUserStatistics(events: CAPBAKUserStatsEventModel[]) {
        return this.userStatisticsClient
            .userStatisticsEventsPost(this.commonQueryParams, { events })
            .unwrapError()
    }

    public async postClientStats(client_status: ClientStats) {
        return this.clientStatsClient
            .clientStatsPost(this.commonQueryParams, {
                client_status,
            })
            .unwrapError()
    }

    public async approveTOS(tos_version: string) {
        return this.approveTosClient
            .approveTosPost({
                ...this.commonQueryParams,
                tos_version,
            })
            .unwrapError()
    }

    public async getChangesAndAsyncUploadStatus(
        jobID: JobID,
        since: number,
    ): Promise<ChangesAndAsyncUploadStatus> {
        const response = await this.jobsClient
            .changesAndAsyncUploadStatusDetail({
                ...this.commonQueryParams,
                jobUuid: jobID,
                since,
            })
            .unwrapError()

        return response.data as ChangesAndAsyncUploadStatus
    }

    // Test endpoint for deleting stripe data
    public deleteStripeTestData() {
        return this.testClient
            .deleteStripeDataPost(this.commonQueryParams)
            .unwrapError()
    }

    public async getSupportedExtensions() {
        const response = await this.supportedExtensionsClient
            .supportedExtensionsGet()
            .unwrapError()

        return response.data
    }

    // User account attributes endpoints
    public async getAccountAttributes(): Promise<UserAccountAttribute[]> {
        const response = await this.accountAttributeClient
            .accountAttributeGet(this.commonQueryParams)
            .unwrapError()

        return response.data.result as UserAccountAttribute[]
    }

    public async upsertAccountAttributes(
        body: UpsertUserAccountAttribute,
    ): Promise<UserAccountAttribute[]> {
        const response = await this.accountAttributeClient
            .accountAttributePost(
                {
                    ...this.commonQueryParams,
                    account_attribute_key: body.account_attribute_key,
                },
                body.value,
            )
            .unwrapError()

        return response.data.result as UserAccountAttribute[]
    }

    public async deleteAccountAttribute(key: UserAccountAttributeKey) {
        await this.accountAttributeClient
            .accountAttributeDelete({
                ...this.commonQueryParams,
                account_attribute_key: key,
            })
            .unwrapError()
    }
}
