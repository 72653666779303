import type { ReactNode } from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { VisibleGridItems } from '~/components/VirtualList/VisibleGridItems'
import { colors, fontSize } from '~/assets/styleConstants'
import type { BasicViewFile } from '~/state/files/selectors'
import type {
    ImageGroup,
    ImageGroupItemReference,
} from '~/state/timeline/selectors'
import { withoutTheUndefined } from '~/utilities/arrayUtils'
import { isMobileDevice } from '~/utilities/device'
import { calcImagesPerRow } from '~/utilities/gridElementSizeCalculator'
import type { ImageGroupStyle } from '~/utilities/imageGroupStyle'
import { MethodProvider } from '../Common/MethodProvider'
import { SelectionIndicator } from '../Common/SelectionIndicator'
import type { GroupSelectionStatus } from '../TimelineScroller/FastScrollerContent'
import {
    GroupListItem,
    NormalItemImage,
    SelectModeGroupListItem,
} from './GroupListItem'
import type { FileSelectionStatus, VisibleRange } from './ImageGroupList'
import { ShiftSelectionHint } from './ShiftSelectionHint'

const TitleArea = styled.div`
    font-size: ${fontSize.large_24};
    user-select: none;

    display: flex;
    align-items: center;
    margin-bottom: ${(props: { selectMode: boolean; marginBottom: number }) =>
        props.marginBottom}px;

    ${(props) =>
        !isMobileDevice.any() && !props.selectMode
            ? `
        &:hover .item-select-indicator {
            visibility: visible;
        }
    `
            : ''}
`

const GroupCheckMark = styled.div`
    height: 24px;
    margin-left: 16px;
`

type Props = ImageGroup & {
    onItemClick: (fileID: FileID) => void
    onItemDoubleClick?: (fileID: FileID) => void
    groupStyle: ImageGroupStyle
    visibleRange: VisibleRange
    groupSelectionStatus: GroupSelectionStatus
    selection?: {
        isInSelectMode: boolean
        selectGroupDisabled?: boolean
        onSelectFile: (file: BasicViewFile) => void
        onSelectGroup: (fileIDs: FileID[]) => void
        onDeselectFile: (file: BasicViewFile) => void
        onDeselectGroup: (fileIDs: FileID[]) => void
        fileWithShiftSelectHint: FileID | undefined
        clearShiftSelectHint: () => void
    }
    focusedItem?: ImageGroupItemReference
    scaleFocusedItem?: boolean
    showHeader?: boolean
    isAlbum?: boolean
    isSharedAlbum?: boolean
    transformHeader?: (groupKey: string) => ReactNode | string
    getFileSelectionStatus: (id: FileID) => FileSelectionStatus
    getAdditionalBottomAreaElements?: (
        fileID: FileID,
        indexInGroup: number,
    ) => ReactNode
    isFirstVisibleMonth: boolean
}

export const GroupListEntry = ({
    focusedItem,
    getAdditionalBottomAreaElements,
    getFileSelectionStatus,
    groupKey,
    groupSelectionStatus,
    groupStyle,
    images,
    isAlbum,
    isFirstVisibleMonth,
    isSharedAlbum,
    onItemClick,
    onItemDoubleClick,
    position,
    scaleFocusedItem,
    selection,
    showHeader,
    transformHeader,
    visibleRange,
}: Props) => {
    const startOfImageArea =
        position + groupStyle.headerHeight + groupStyle.headerBottomGap

    const getLabelsFromHeader = (header: string) => {
        let groupSelection
        if (selection && selection.selectGroupDisabled !== true) {
            const allItemsSelected = groupSelectionStatus === 'all-selected'

            groupSelection = (
                <GroupCheckMark data-cy="timeline__groupTitleSelector">
                    <SelectionIndicator
                        onClick={handleToggleSelectGroup}
                        showPlaceholder={selection.isInSelectMode}
                        isSelected={groupSelectionStatus !== 'none-selected'}
                        fillColor={
                            !selection.isInSelectMode || allItemsSelected
                                ? colors.captureBlue
                                : 'white'
                        }
                        checkmarkColor={
                            !selection.isInSelectMode || allItemsSelected
                                ? 'white'
                                : colors.captureBlue
                        }
                        borderColor={colors.captureBlue}
                        cyKey="icon-button"
                    />
                </GroupCheckMark>
            )
        }
        return (
            <TitleArea
                selectMode={selection !== undefined && selection.isInSelectMode}
                marginBottom={groupStyle.headerBottomGap}>
                {transformHeader ? transformHeader(header) : header}
                {groupSelection}
            </TitleArea>
        )
    }

    const isGroupSelected = () => {
        const imgs = withoutTheUndefined(images)
        return (
            imgs.length > 0 &&
            imgs.every(
                ({ fileID }) => getFileSelectionStatus(fileID) === 'Selected',
            )
        )
    }

    const handleToggleSelectGroup = () => {
        if (selection) {
            const fileIDs: FileID[] = withoutTheUndefined(images).map(
                (img) => img.fileID,
            )
            if (isGroupSelected()) {
                selection.onDeselectGroup(fileIDs)
            } else {
                const nonSelectedFiles = fileIDs.filter(
                    (id) => !(getFileSelectionStatus(id) === 'Selected'),
                )
                selection.onSelectGroup(nonSelectedFiles)
            }
        }
    }

    const getElement = useCallback(
        (i: number) => {
            const selectItem = (file: BasicViewFile) => {
                if (selection) {
                    selection.onSelectFile(file)
                }
            }

            const deselectItem = (file: BasicViewFile) => {
                if (selection) {
                    selection.onDeselectFile(file)
                }
            }
            const image = images[i]
            const shouldFocus =
                focusedItem &&
                groupKey === focusedItem.group &&
                i === focusedItem.index

            if (image) {
                const itemProps = {
                    file: image,
                    width: groupStyle.elementWidth,
                    height: groupStyle.elementHeight,
                    spaceAround: groupStyle.elementSpaceAround,
                    selection: selection && {
                        onToggleSelect: selectItem,
                    },
                    shouldFocus,
                    focusedItemScale: scaleFocusedItem === false ? 1 : 1.15,
                    additionalBottomAreaElements:
                        getAdditionalBottomAreaElements?.(image.fileID, i),
                    isFirstVisibleMonth: isFirstVisibleMonth,
                }

                const fileSelectionStatus = getFileSelectionStatus(image.fileID)
                const isSelected = fileSelectionStatus === 'Selected'

                if (selection?.isInSelectMode) {
                    let shiftSelectHint: ReactNode = null
                    if (image.fileID === selection.fileWithShiftSelectHint) {
                        const imagesPerRow = calcImagesPerRow(groupStyle)
                        const numInRow = i % imagesPerRow
                        shiftSelectHint = (
                            <ShiftSelectionHint
                                placement={
                                    numInRow === 0
                                        ? 10
                                        : numInRow + 1 === imagesPerRow
                                          ? 90
                                          : 50
                                }
                                onGotIt={selection.clearShiftSelectHint}
                            />
                        )
                    }

                    return (
                        <MethodProvider
                            method={isSelected ? deselectItem : selectItem}
                            arg={image}
                            key={i}>
                            {(onToggleSelect) => (
                                <SelectModeGroupListItem
                                    {...itemProps}
                                    selection={{
                                        fileSelectionStatus,
                                        onToggleSelect,
                                    }}
                                    selectHint={shiftSelectHint}
                                    key={i}
                                />
                            )}
                        </MethodProvider>
                    )
                }

                return (
                    <GroupListItem
                        {...itemProps}
                        onItemClick={onItemClick}
                        onItemDoubleClick={onItemDoubleClick}
                        key={i}
                        isAlbum={isAlbum}
                        isSharedAlbum={isSharedAlbum}
                    />
                )
            }

            // if image count is higher than number of images, a gray square will fill the gap
            return (
                <NormalItemImage // using <img> without specifying a 'src' attribute leads to a thin border around image placeholder
                    elementWidth={groupStyle.elementWidth}
                    elementHeight={groupStyle.elementHeight}
                    elementSpaceAround={groupStyle.elementSpaceAround}
                    key={i}
                />
            )
        },
        [
            images,
            focusedItem,
            groupKey,
            selection,
            groupStyle,
            scaleFocusedItem,
            getAdditionalBottomAreaElements,
            isFirstVisibleMonth,
            getFileSelectionStatus,
            onItemClick,
            onItemDoubleClick,
            isAlbum,
            isSharedAlbum,
        ],
    )

    return (
        <>
            {showHeader !== false && getLabelsFromHeader(groupKey)}
            <VisibleGridItems
                groupStyle={groupStyle}
                visibleRange={visibleRange}
                groupPosition={startOfImageArea}
                getVisibleElement={getElement}
                totalItems={images.length}
            />
        </>
    )
}
