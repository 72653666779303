import { ProgressBar, Prompt, Typography } from '@capture/capture-components'
import { useSelector } from 'react-redux'
import { _ } from '~/assets/localization/util'
import { getFetchRemainingTrashFilesProgress } from '~/state/trash/selectors'

interface DownloadProgressPromptProps {
    isInsideApp?: boolean
}

export const DownloadProgressPrompt = ({
    isInsideApp,
}: DownloadProgressPromptProps) => {
    const fetchProgress = useSelector(getFetchRemainingTrashFilesProgress) ?? 0
    const completePercentage = Math.floor(fetchProgress * 100)

    return (
        <Prompt.Content
            data-cy="download_all_progress_prompt"
            title={_('processing_with_dots')}
            preventCloseOnClickOutside
            hideCloseButton>
            <Typography.Paragraph>
                {isInsideApp
                    ? _('photos_trash__keep_open_app')
                    : _('photos_trash__keep_open')}
            </Typography.Paragraph>
            <ProgressBar value={completePercentage} />
        </Prompt.Content>
    )
}
