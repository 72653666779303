/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** APIKey */
export enum CAPBAKAPIKey {
    O7AHYgm13P = 'o7aHYgm13p',
    MiHHWlmDUn = 'MiHHWlmDUn',
    Xoo2Kath5O = 'Xoo2Kath5O',
    IdX8S4SEDF = 'idX8S4sEDF',
    KVEzWDMsj = 'KVEz-WDMsj',
    OyfPnKpTpH = 'oyfPnKpTpH',
    EY9NdlOcGr = 'eY9NdlOcGr',
    Value8MC08FjI54 = '8MC08FjI54',
    BT3D2S24Io = 'BT3d2S24io',
    VwLKSgXDiE = 'vwLKSgXDiE',
    Hsn6YzECfW = 'Hsn6yzECfW',
    OZzqAS81P7 = 'OZzqAS81P7',
    CBNeQZPnHx = 'cBNeQZPnHx',
    ZJukqEyVqR = 'ZJukqEyVqR',
    MFymNt3317 = 'MFymNt3317',
    Value78F9AkpvkI = '78f9akpvkI',
}

/** APIPingResponse */
export interface CAPBAKAPIPingResponse {
    hostname: string
    result?: 'pong'
}

/** AccountAttribute */
export interface CAPBAKAccountAttribute {
    user_uuid: string
    key: string
    value: string | any[] | object | number
    update_timestamp: number
    create_timestamp: number
}

/** AccountAttributeResponse */
export interface CAPBAKAccountAttributeResponse {
    result: CAPBAKAccountAttribute[]
}

/** AccountDeletionRequestResult */
export interface CAPBAKAccountDeletionRequestResult {
    status: CAPBAKDataProtectionRequestStatus
    undelete_url?: string
}

/** AccountInfo */
export interface CAPBAKAccountInfo {
    name: string
    used_space: string
    max_space: string
    uuid: string
    kissmetrics_id: string
    logged_in_as?: string
    username: string
    files_disabled: boolean
    has_unlimited_quota: number
    external_urls_hash: string
    user_groups: CAPBAKUserGroupMembership[]
    internal_privacy_dashboard: boolean
    latest_account_attribute_update_timestamp?: number
    full_screen_card_hash: string
    user_has_orden_account: boolean
    orden_account_link_status: object
    permitted_services?: CAPBAKPermittedServices
    push_token?: string
    shutdown_info?: object
    profile_picture_url?: string
    upload_error_log?: object
    tos_to_approve?: Record<string, string>
    sunset_delete_date?: string
    number_of_subscribed_albums?: number
    file_type_counters?: CAPBAKMediaCounters
    is_read_only_user?: boolean
    has_files_not_media?: boolean
    sunset_file_added_to_trash_date?: string
}

/** AgentId */
export interface CAPBAKAgentId {
    agent_id: number
}

/** AlbumMessageResponse */
export interface CAPBAKAlbumMessageResponse {
    message: string
    status: string
    uuid: string
}

/** AndroidClientRight */
export interface CAPBAKAndroidClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    android_order_id: string
    android_product_id: string
}

/** AndroidPurchase */
export interface CAPBAKAndroidPurchase {
    is_sandbox: boolean
    order_id: string
    product_id: string
    is_active: boolean
}

/** AndroidRightInfoResponse */
export interface CAPBAKAndroidRightInfoResponse {
    quota: number
    active_grants: CAPBAKClientRight[]
    created_grants?: object[]
}

/** AndroidStoreResponse */
export interface CAPBAKAndroidStoreResponse {
    result: CAPBAKAndroidRightInfoResponse
}

/** AndroidSubscription */
export interface CAPBAKAndroidSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** AppleClientRight */
export interface CAPBAKAppleClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    apple_original_transaction_id: string
    apple_product_id: string
}

/** ApplePurchase */
export interface CAPBAKApplePurchase {
    is_sandbox: boolean
    transaction_id: string
    product_id: string
    is_active: boolean
}

/** AppleRightInfoResponse */
export interface CAPBAKAppleRightInfoResponse {
    quota: number
    active_grants: CAPBAKClientRight[]
    created_grants?: CAPBAKGrantInfo[]
}

/** AppleStoreResponse */
export interface CAPBAKAppleStoreResponse {
    result: CAPBAKAppleRightInfoResponse
}

/** AppleSubsInReceipt */
export interface CAPBAKAppleSubsInReceipt {
    transaction_id: string
    original_transaction_id: string
    product_id: string
    expires_date_ms: number
    is_sandbox: boolean
    cancelled_date_ms?: number
    size: number
}

/** AppleSubscription */
export interface CAPBAKAppleSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** AppleTransactionResponse */
export interface CAPBAKAppleTransactionResponse {
    largest_valid_transaction?: CAPBAKAppleSubsInReceipt
    transactions: CAPBAKAppleSubsInReceipt[]
}

/** AsyncUploadExistsResponse */
export interface CAPBAKAsyncUploadExistsResponse {
    content: CAPBAKAsyncUploadExistsResponseContent
}

/** AsyncUploadExistsResponseContent */
export interface CAPBAKAsyncUploadExistsResponseContent {
    uuid: string
    status: CAPBAKFileLifetimeStatus
}

/** AsyncUploadInfo */
export interface CAPBAKAsyncUploadInfo {
    uuid: string
    file_checksum?: string
    path: string
    status: CAPBAKAsyncUploadState
    bytes_received: number
}

/** AsyncUploadResponse */
export interface CAPBAKAsyncUploadResponse {
    content: CAPBAKAsyncUploadResponseContent
}

/** AsyncUploadResponseContent */
export interface CAPBAKAsyncUploadResponseContent {
    status: CAPBAKAsyncUploadState
    bytes_received: number
}

/**
 * AsyncUploadState
 * Type that replicates the ASYNC_UPLOAD_STATE enum in postgres
 */
export enum CAPBAKAsyncUploadState {
    Uploading = 'uploading',
    Resumable = 'resumable',
    ReadyForProcessing = 'ready_for_processing',
    Processing = 'processing',
    ReadyForTranscoding = 'ready_for_transcoding',
    Failed = 'failed',
    Done = 'done',
}

/** AsyncUploadStatus */
export interface CAPBAKAsyncUploadStatus {
    status: CAPBAKAsyncUploadState
    checksum?: string
    bytes_received?: number
}

/** AsyncUploadStatusAndChangesResponse */
export interface CAPBAKAsyncUploadStatusAndChangesResponse {
    changes: any[]
    uploads?: CAPBAKAsyncUploadInfo[]
    info?: CAPBAKSpaceInfo
}

/** AsyncUploadStatusResponse */
export interface CAPBAKAsyncUploadStatusResponse {
    content: CAPBAKAsyncUploadStatus
}

/** AuthVerificationResp */
export interface CAPBAKAuthVerificationResp {
    message: 'invalid_user_auth' | 'no_longer_valid' | 'valid_user_auth'
    valid_to_epoch?: number
    current_time?: number
}

/** BackendControlledFullScreenCard */
export interface CAPBAKBackendControlledFullScreenCard {
    card_id: string
    user_uuid: string
    template: CAPBAKFullScreenTemplate
    create_timestamp: string
    update_timestamp?: string
    remove_at?: string
    priority: number
    language: CAPBAKLanguage
    dismissed_at?: string
    client_values: CAPBAKFullScreenCardProperties
}

/** BasicDeviceInfo */
export interface CAPBAKBasicDeviceInfo {
    username: string
    valid: boolean
    accessed: string
    valid_to: string
    api_key: string
    last_auth: string
}

/** Body_create_private_share_test_private_share_post */
export interface CAPBAKBodyCreatePrivateShareTestPrivateSharePost {
    recipients: string[]
}

/** CTAction */
export interface CAPBAKCTAction {
    label: string
    deeplink: string
    web_action: CAPBAKWebAction
    dismiss_on_tap: boolean
}

/** CancelAccountDeletion */
export interface CAPBAKCancelAccountDeletion {
    status: string
}

/** Card */
export interface CAPBAKCard {
    title: string
    image_url: string
    action_url: string
    cover_color?: string
    cover_image_url?: string
    emoji?: string
    secondary_emoji?: string
    seen: boolean
}

/** CardBrand */
export enum CAPBAKCardBrand {
    Amex = 'amex',
    Diners = 'diners',
    Discover = 'discover',
    EftposAu = 'eftpos_au',
    Jcb = 'jcb',
    Mastercard = 'mastercard',
    Unionpay = 'unionpay',
    Visa = 'visa',
    Unknown = 'unknown',
}

/** CardSection */
export interface CAPBAKCardSection {
    section_title: string
    show_as_swimlane: boolean
    cards: CAPBAKCard[]
    card_width?: string
    card_height?: string
}

/** Cards */
export interface CAPBAKCards {
    card_sections: CAPBAKCardSection[]
}

/** ChangesOption */
export interface CAPBAKChangesOption {
    description: string
    details: string
}

/** ClassificationCategory */
export enum CAPBAKClassificationCategory {
    Document = 'document',
}

/** ClientFacingPush */
export enum CAPBAKClientFacingPush {
    UnderperformingUploads = 'underperforming_uploads',
    PermissionIssue = 'permission_issue',
    PermissionIssueIos14 = 'permission_issue_ios_14',
    PermissionIssueOldClientsIos14 = 'permission_issue_old_clients_ios_14',
    InstantMemories = 'instant_memories',
    InstantMemoriesDataPush = 'instant_memories_data_push',
    ChristmasCollection = 'christmas_collection',
    NewYearsEveCollection = 'new_years_eve_collection',
    NorwegianConstitutionDayCollection = 'norwegian_constitution_day_collection',
    SummerTravelsCollection = 'summer_travels_collection',
    AutumnTravelsCollection = 'autumn_travels_collection',
    InactiveSessionsWeekBeforeLogout = 'inactive_sessions_week_before_logout',
    InactiveSessionsDayBeforeLogout = 'inactive_sessions_day_before_logout',
    EngagingInactiveUsers = 'engaging_inactive_users',
    Sunset = 'sunset',
    OldSunset = 'old_sunset',
}

/** ClientGrantor */
export enum CAPBAKClientGrantor {
    AppleStore = 'apple store',
    AndroidStore = 'android store',
    Capture = 'capture',
    CaptureStripe = 'capture stripe',
    B2B = 'b2b',
    ConnectStore = 'connect store',
    CustomerService = 'customer service',
    Other = 'other',
}

/** ClientLoginOrRegistrationDict */
export interface CAPBAKClientLoginOrRegistrationDict {
    auth: string
    account_info: CAPBAKAccountInfo
    user_creation: number
    country_code: string
    service: CAPBAKServiceInfo
    use_cellular?: string
    td_sls?: string
    state?: string
    missing_tos_review?: number
}

/** ClientRight */
export interface CAPBAKClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
}

/** Collection */
export interface CAPBAKCollection {
    title: string
    subtitle: string
    photos: string[]
    seen_photos?: string[]
    collection_uuid: string
    collection_type: CAPBAKCollectionType
    highlights: string[]
    labels: Record<string, string>
    updates_card: string[]
    updates_card_title: string
    updates_card_body: string
    cover_color: string
    cover_image_url: string
    theme_color: string
    emoji: string
    secondary_emoji: string
    unseen_photo_index: number
}

/** CollectionType */
export enum CAPBAKCollectionType {
    AutumnTravels = 'autumn_travels',
    Christmas = 'christmas',
    NewYearsEve = 'new_years_eve',
    NorwegianConstitutionDay = 'norwegian_constitution_day',
    SummerTravels = 'summer_travels',
}

/** CommentResponse */
export interface CAPBAKCommentResponse {
    comment_uuid: string
    result?: 'OK'
}

/** Consent */
export interface CAPBAKConsent {
    key: CAPBAKConsentKey
    value: boolean
    changed_timestamp?: string
    user_changed_timestamp?: string
    created_timestamp: string
}

export enum CAPBAKConsentKey {
    FacialRecognition = 'facial_recognition',
    AdvancedFilteringAndCategorization = 'advanced_filtering_and_categorization',
    FaceGrouping = 'face_grouping',
    HelpUsImprove = 'help_us_improve',
    StayUpToDateEmail = 'stay_up_to_date__email',
    StayUpToDatePushNotification = 'stay_up_to_date__push_notification',
    StayUpToDateSms = 'stay_up_to_date__sms',
}

/** ConsentResponsev2 */
export interface CAPBAKConsentResponsev2 {
    value: boolean
    is_user_set: boolean
}

/** ConsentsResponse */
export interface CAPBAKConsentsResponse {
    data: Record<string, boolean>
    data_v2: Record<string, CAPBAKConsentResponsev2>
}

/** CsamDelayResponse */
export interface CAPBAKCsamDelayResponse {
    disable_user_new_due_at?: number
    delete_shadow_account_new_due_at?: number
}

/** CsamExistResponse */
export interface CAPBAKCsamExistResponse {
    is_csam_found: boolean
}

/** CumulusEventsData */
export interface CAPBAKCumulusEventsData {
    event_ids: string[]
}

/** Currency */
export enum CAPBAKCurrency {
    NOK = 'NOK',
    SEK = 'SEK',
    DKK = 'DKK',
    BGN = 'BGN',
}

/** CustomerServiceAgent */
export interface CAPBAKCustomerServiceAgent {
    username: string
    name: string
    comment: string
    id: number
    created: number
    disabled?: number
    role_ids: number[]
    roles: string[]
    permissions: CAPBAKCustomerServicePermission[]
}

/** CustomerServiceFileInfo */
export interface CAPBAKCustomerServiceFileInfo {
    file_uuid: string
    file_id: number
    flags: number
    ctime: number
    timestamp: number
    upload_time: number
    checksum: string
    size: number
}

/** CustomerServicePermission */
export enum CAPBAKCustomerServicePermission {
    CaptureFindSubscriptions = 'capture:find_subscriptions',
    CaptureGetInfo = 'capture:get_info',
    CaptureGetMerge = 'capture:get_merge',
    CapturePostMerge = 'capture:post_merge',
    CaptureMergeTicketGet = 'capture:merge_ticket_get',
    CaptureMergeTicketPost = 'capture:merge_ticket_post',
    CaptureMergeTicketSpecificGet = 'capture:merge_ticket_specific_get',
    CaptureMergeTicketSpecificPut = 'capture:merge_ticket_specific_put',
    CaptureMergeTicketSpecificPost = 'capture:merge_ticket_specific_post',
    CaptureMergeTicketSpecificDelete = 'capture:merge_ticket_specific_delete',
    CaptureDeleteDevice = 'capture:delete_device',
    CaptureToggleDisabled = 'capture:toggle_disabled',
    CaptureRegenerateThumbnails = 'capture:regenerate_thumbnails',
    CaptureRestoreDeletedFiles = 'capture:restore_deleted_files',
    CaptureCreatePoliceReportShare = 'capture:create_police_report_share',
    CaptureGetUserCsamStatus = 'capture:get_user_csam_status',
    CaptureAddTelenorIdEmail = 'capture:add_telenor_id_email',
    CaptureRemoveTelenorIdEmail = 'capture:remove_telenor_id_email',
    CaptureAddTelenorIdPhone = 'capture:add_telenor_id_phone',
    CaptureRemoveTelenorIdPhone = 'capture:remove_telenor_id_phone',
    CaptureDelayCsamNotificationsAndDeletionOfShadowAccount = 'capture:delay_csam_notifications_and_deletion_of_shadow_account',
    CaptureCancelCsamReport = 'capture:cancel_csam_report',
    CaptureDeleteUserAccount = 'capture:delete_user_account',
    CaptureDeleteTestUserAccount = 'capture:delete_test_user_account',
    CaptureCancelRegisteredAccountDeletionForUserWithoutTelenorId = 'capture:cancel_registered_account_deletion_for_user_without_telenor_id',
    CaptureUpdateDeleteTimeForAccount = 'capture:update_delete_time_for_account',
    CaptureCreateCaptureRight = 'capture:create_capture_right',
    CaptureDeleteCaptureRight = 'capture:delete_capture_right',
    CaptureResetThrottling = 'capture:reset_throttling',
    CaptureRestorePlayStorePurchase = 'capture:restore_play_store_purchase',
    CaptureRestoreAppStorePurchase = 'capture:restore_app_store_purchase',
    CaptureGetAppStorePurchase = 'capture:get_app_store_purchase',
    CaptureDelaySunsetUserHardDeletion = 'capture:delay_sunset_user_hard_deletion',
    CaptureGdprMetadata = 'capture:gdpr_metadata',
    GeneralSendSmsOtp = 'general:send_sms_otp',
    GeneralSendEmailOtp = 'general:send_email_otp',
    GeneralAdmin = 'general:admin',
    OrdenGetAccountInfo = 'orden:get_account_info',
    OrdenDeleteDevice = 'orden:delete_device',
    MimirGetRights = 'mimir:get_rights',
}

/** CustomerServiceRole */
export interface CAPBAKCustomerServiceRole {
    title: string
    permissions: CAPBAKCustomerServicePermission[]
    id: number
    created: number
    disabled?: number
}

/** CustomerServiceToolAuthResp */
export interface CAPBAKCustomerServiceToolAuthResp {
    customer_service_tool_client_auth: string
}

/** DBPingResponse */
export interface CAPBAKDBPingResponse {
    hostname: string
    result: Record<string, CAPBAKDBPingResult>
}

/** DBPingResult */
export interface CAPBAKDBPingResult {
    time: number
    error?: string
}

/** DataProtectionRequestResult */
export interface CAPBAKDataProtectionRequestResult {
    status: CAPBAKDataProtectionRequestStatus
}

/** DataProtectionRequestStatus */
export enum CAPBAKDataProtectionRequestStatus {
    OK = 'OK',
    CREATED = 'CREATED',
    COOLDOWN = 'COOLDOWN',
}

/** DataProtectionUpdateRequestResult */
export interface CAPBAKDataProtectionUpdateRequestResult {
    status: CAPBAKDataProtectionRequestStatus
    consents: Record<string, CAPBAKConsentResponsev2>
}

/** DefaultJob */
export interface CAPBAKDefaultJob {
    id: string
    name: string
    mtime: number
    type: number
    size: number
    last_update?: number
    ctime?: number
}

/** DelaySunsetUserHardDeletionResponse */
export interface CAPBAKDelaySunsetUserHardDeletionResponse {
    new_deletion_date: string
}

/** DeleteAlbumsResponse */
export interface CAPBAKDeleteAlbumsResponse {
    success: CAPBAKAlbumMessageResponse[]
    errors: CAPBAKAlbumMessageResponse[]
}

/** DeleteFilesResp */
export interface CAPBAKDeleteFilesResp {
    result: 'All_files_deleted'
}

/** DeleteTemplateResp */
export interface CAPBAKDeleteTemplateResp {
    result: 'deleted'
}

/** DeletedFilesInfoItem */
export interface CAPBAKDeletedFilesInfoItem {
    name: string
    size: number
    checksum: string
    ctime: number
}

/** DeletedFilesInfoResponse */
export interface CAPBAKDeletedFilesInfoResponse {
    deleted_files: CAPBAKDeletedFilesInfoItem[]
}

/** DetailedStoryInfoResponse */
export interface CAPBAKDetailedStoryInfoResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    owner: CAPBAKJobOwner
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: CAPBAKJobPermissionsExtended
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
}

/** DetailedStoryResponse */
export interface CAPBAKDetailedStoryResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: number
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
    owner?: CAPBAKJobOwner
}

/** DeviceInfo */
export interface CAPBAKDeviceInfo {
    deletecount?: number
    auto_upload?: string
    client_version?: string
    free_space?: number
    total_space?: number
    platform?: string
    os_version?: string
    uploadcount?: number
    downloadcount?: number
    rollbackcount?: number
    trashcan_visitcount?: number
    last_delete?: number | boolean
    last_upload?: number | boolean
    last_download?: number | boolean
    last_rollback?: number | boolean
    last_trashcan_visit?: number | boolean
    device_id: string
    device_name: string
    is_authorized: string
    login_methods: string[]
    last_connection: number
    login_time: number
    user_agent: string
    last_push_feedback_time?: number
    auth_id: string
    has_push_token: boolean
}

/** DeviceModel */
export interface CAPBAKDeviceModel {
    device_id?: string
    device_name?: string
}

/** DeviceResp */
export interface CAPBAKDeviceResp {
    auth_token: string
}

/** DeviceWithAuth */
export interface CAPBAKDeviceWithAuth {
    id: string
    name: string
    expires: string
}

/** DevicesWithAuth */
export interface CAPBAKDevicesWithAuth {
    current: string
    devices: CAPBAKDeviceWithAuth[]
}

/** DigitalLegacyChoice */
export enum CAPBAKDigitalLegacyChoice {
    OnlyProvideContentExplicitlyDetailedInMyWill = 'only_provide_content_explicitly_detailed_in_my_will',
    DoNotProvideContentToAnybody = 'do_not_provide_content_to_anybody',
    ProvideContentToContact = 'provide_content_to_contact',
}

/** DigitalLegacyContact */
export interface CAPBAKDigitalLegacyContact {
    name: string
    address: string
    email_address: string
    phone_number: string
}

/** DigitalLegacyData */
export interface CAPBAKDigitalLegacyData {
    user_uuid: string
    created_at: string
    updated_at?: string
    contact_information: CAPBAKDigitalLegacyContact[]
}

/** DigitalLegacyInfo */
export interface CAPBAKDigitalLegacyInfo {
    choice: CAPBAKDigitalLegacyChoice
    digital_legacy_data?: CAPBAKDigitalLegacyData
}

/** DummyPoliceJwtResp */
export interface CAPBAKDummyPoliceJwtResp {
    jwt: string
}

/** EmailEntry */
export interface CAPBAKEmailEntry {
    email: string
    timestamp: number
}

/** ErrorLogDataResult */
export interface CAPBAKErrorLogDataResult {
    key: string
    content: string
}

/** FeatureFlag */
export interface CAPBAKFeatureFlag {
    version: number
    force_reset: number
}

/** FeatureFlags */
export interface CAPBAKFeatureFlags {
    flags: Record<string, CAPBAKFeatureFlag>
}

/** FileDeletionsByDevice */
export interface CAPBAKFileDeletionsByDevice {
    deleted: number
    not_recoverable: number
    device_name: string
    info: Record<string, string | string[] | number | null>
}

/** FileExtensionsInfo */
export interface CAPBAKFileExtensionsInfo {
    file_extensions_info: CAPBAKImageTypeAndTimeRange[]
}

/** FileGroupType */
export enum CAPBAKFileGroupType {
    Live = 'live',
    SamsungMotion = 'samsung_motion',
    Burst = 'burst',
    GenericSuffixControlled = 'generic_suffix_controlled',
}

/** FileLifetimeStatus */
export enum CAPBAKFileLifetimeStatus {
    Exists = 'exists',
    Deleted = 'deleted',
    Trashed = 'trashed',
}

/** FileObjectResponse */
export interface CAPBAKFileObjectResponse {
    id: string
    path: string
    size: number
    checksum: string
    mtime: number
    file_type: CAPBAKFileTypeName
    ctime?: number
    width?: number
    height?: number
    duration?: number
    timestamp?: number
    group_type?: string
    master?: string
    user_uuid?: string
    revisions?: string[]
    aspect_ratio?: string
    group_id?: string
}

/** FilePropResponse */
export interface CAPBAKFilePropResponse {
    group_id?: string
    master: boolean
    group_type: CAPBAKFileGroupType
}

/** FileRecoveryStatus */
export interface CAPBAKFileRecoveryStatus {
    ref_id: string
    starting_time: number
    ending_time: number
    is_completed: boolean
    completed_at: number
    recovered_files_count: number
}

/** FileRefID */
export interface CAPBAKFileRefID {
    ref_id: string
}

/** FileTypeName */
export enum CAPBAKFileTypeName {
    Unclassified = 'unclassified',
    Video = 'video',
    Picture = 'picture',
    Document = 'document',
    Screenshot = 'screenshot',
}

/** FileUUIDList */
export interface CAPBAKFileUUIDList {
    file_uuids: string[]
}

/** FileUUIDRollbackList */
export interface CAPBAKFileUUIDRollbackList {
    file_uuids: string[]
}

/** FixedAppleSub */
export interface CAPBAKFixedAppleSub {
    old_size: number
    new_size: number
    transaction_id: string
}

/** FixedAppleSubsResponse */
export interface CAPBAKFixedAppleSubsResponse {
    result: CAPBAKFixedAppleSub[]
}

/** FrameworkPingResponse */
export interface CAPBAKFrameworkPingResponse {
    hostname: string
    framework: 'Falcon' | 'FastAPI'
}

/** FullScreenCard */
export interface CAPBAKFullScreenCard {
    card_id: string
    user_uuid: string
    template: CAPBAKFullScreenTemplate
    values: object
    create_timestamp: string
    update_timestamp?: string
    remove_at?: string
    priority: number
    language: CAPBAKLanguage
    dismissed_at?: string
}

/** FullScreenCardProperties */
export interface CAPBAKFullScreenCardProperties {
    title: string
    body?: string
    primary_cta: CAPBAKCTAction
    secondary_cta?: CAPBAKCTAction
    illustration?: CAPBAKFullscreenCardIllustration
    close_label?: string
}

/** FullScreenCardsResponse */
export interface CAPBAKFullScreenCardsResponse {
    data: CAPBAKFullScreenCard[]
}

/** FullScreenCardsResponseV2 */
export interface CAPBAKFullScreenCardsResponseV2 {
    client_controlled_cards: CAPBAKFullScreenCard[]
    backend_controlled_cards: CAPBAKBackendControlledFullScreenCard[]
}

/** FullScreenTemplate */
export enum CAPBAKFullScreenTemplate {
    UserSunset = 'user_sunset',
    FacialGroupingMigration = 'facial_grouping_migration',
    GenericMessage1 = 'generic_message_1',
    SunsetReadOnly = 'sunset_read_only',
}

/** FullscreenCardIllustration */
export enum CAPBAKFullscreenCardIllustration {
    Info = 'info',
    Warning = 'warning',
    Backup = 'backup',
    Photos = 'photos',
    Subscriptions = 'subscriptions',
}

/** GetAgentResponse */
export interface CAPBAKGetAgentResponse {
    result: CAPBAKCustomerServiceAgent[]
}

/** GetConnectIdsResponse */
export interface CAPBAKGetConnectIdsResponse {
    content: Record<string, string[]>
}

/** GetDevicesResponse */
export interface CAPBAKGetDevicesResponse {
    device_info: CAPBAKBasicDeviceInfo[]
}

/** GetFileEventsResponse */
export interface CAPBAKGetFileEventsResponse {
    result: CAPBAKSessionMonthActivity[]
}

/** GetFileExtensionsResponse */
export interface CAPBAKGetFileExtensionsResponse {
    result: CAPBAKFileExtensionsInfo
}

/** GetFileInfoResponse */
export interface CAPBAKGetFileInfoResponse {
    files: CAPBAKCustomerServiceFileInfo[]
}

/** GetFileRecoveryStatusResponse */
export interface CAPBAKGetFileRecoveryStatusResponse {
    result: CAPBAKFileRecoveryStatus[]
}

/** GetJobInfoResponse */
export interface CAPBAKGetJobInfoResponse {
    result: CAPBAKJobClientInfo[]
}

/** GetPermissionsResponse */
export interface CAPBAKGetPermissionsResponse {
    result: CAPBAKCustomerServicePermission[]
}

/** GetPersonalInfoResponse */
export interface CAPBAKGetPersonalInfoResponse {
    result: CAPBAKPersonalInfo
}

/** GetRoleResponse */
export interface CAPBAKGetRoleResponse {
    result: CAPBAKCustomerServiceRole[]
}

/** GetSmsOtpResponse */
export interface CAPBAKGetSmsOtpResponse {
    result: CAPBAKSmsOtp
}

/** GetSubsResponse */
export interface CAPBAKGetSubsResponse {
    result: CAPBAKGroupedSubscriptions
}

/** GetUserActivityStatsResponse */
export interface CAPBAKGetUserActivityStatsResponse {
    content: CAPBAKUserActivityStats
}

/** GetUserEventsResponse */
export interface CAPBAKGetUserEventsResponse {
    events: CAPBAKUserEvent[]
}

/** GrantInfo */
export interface CAPBAKGrantInfo {
    created_grant_for_product: string
    grant_id: string
}

/** GrantInfoResponse */
export interface CAPBAKGrantInfoResponse {
    result: CAPBAKGrants
}

/** Grants */
export interface CAPBAKGrants {
    grants: (
        | CAPBAKStripeClientRight
        | CAPBAKAppleClientRight
        | CAPBAKAndroidClientRight
        | CAPBAKClientRight
    )[]
}

/** GroupedSubscriptions */
export interface CAPBAKGroupedSubscriptions {
    active: (
        | CAPBAKAndroidSubscription
        | CAPBAKAppleSubscription
        | CAPBAKNbsStorageCustomerServiceStripeSubscription
    )[]
    deactivated: (
        | CAPBAKAndroidSubscription
        | CAPBAKAppleSubscription
        | CAPBAKNbsStorageCustomerServiceStripeSubscription
    )[]
}

/** HTTPValidationError */
export interface CAPBAKHTTPValidationError {
    detail?: CAPBAKValidationError[]
}

/** ImageTypeAndTimeRange */
export interface CAPBAKImageTypeAndTimeRange {
    file_extension: string
    from_time: number
    to_time: number
    count: number
}

/** Instant */
export interface CAPBAKInstant {
    title: string
    subtitle: string
    photos: string[]
    seen_photos?: string[]
    url_path: string
    job_id: string
    start: string
    end: string
    date_range: string
}

/** JobClientInfo */
export interface CAPBAKJobClientInfo {
    id: number
    uuid: string
    job_type: CAPBAKJobClientType
    active_file_count: number
    active_media_file_count: number
    deleted_file_count: number
    title: string
}

/** JobClientType */
export enum CAPBAKJobClientType {
    Timeline = 'Timeline',
    Share = 'Share',
    Story = 'Story',
    Deleted = 'Deleted',
    Blocked = 'Blocked',
    Unknown = 'Unknown',
    Purged = 'Purged',
}

/** JobListLight */
export interface CAPBAKJobListLight {
    feature_flag_hash: string
    max_space: string
    used_space: string
    jobs?: CAPBAKLightJob[]
}

/** JobOwner */
export interface CAPBAKJobOwner {
    uuid: string
    name?: string
    email?: string
    profile_picture_url?: string
}

/** JobPermissionsExtended */
export interface CAPBAKJobPermissionsExtended {
    allow_comments?: 0 | 1
    allow_uploads?: 0 | 1
    allow_reactions?: 0 | 1
    allow_sharing?: 0 | 1
    allow_anonymous_access?: 0 | 1
    allow_discovery?: 0 | 1
    allow_all_recipients?: 0 | 1
}

/** JobPermissionsResponse */
export interface CAPBAKJobPermissionsResponse {
    permissions: CAPBAKJobPermissionsExtended
}

/** JobPrivacyModeResponse */
export interface CAPBAKJobPrivacyModeResponse {
    privacy_mode: CAPBAKPrivacyMode
}

/**
 * JobResponse
 * This is used both in the job list and job info endpoints
 */
export interface CAPBAKJobResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
}

/** JobServiceResp */
export interface CAPBAKJobServiceResp {
    service: CAPBAKServiceInfo
}

/** JobSetPermissionsResponse */
export interface CAPBAKJobSetPermissionsResponse {
    permissions: CAPBAKJobPermissionsExtended
    result: 'changed' | 'unchanged'
}

/** JobUUIDList */
export interface CAPBAKJobUUIDList {
    job_uuids: string[]
}

/** KeepFilesQuotaExceeded */
export interface CAPBAKKeepFilesQuotaExceeded {
    message: string
    space_required: number
    space_remaining: number
}

/** Language */
export enum CAPBAKLanguage {
    No = 'no',
    En = 'en',
    Sv = 'sv',
}

/** LightJob */
export interface CAPBAKLightJob {
    id: string
    last_update: number
    last_user_update: number
}

/** LocaleResp */
export interface CAPBAKLocaleResp {
    locale: string
}

/** LoginIdentifierResp */
export interface CAPBAKLoginIdentifierResp {
    login_identifier: string
}

/** LoginTypeResp */
export interface CAPBAKLoginTypeResp {
    login_type: string
}

/** LogoutResponse */
export interface CAPBAKLogoutResponse {
    message: string
    result: CAPBAKLogoutResult
}

/** LogoutResult */
export enum CAPBAKLogoutResult {
    Success = 'success',
    ConnectLogoutFailed = 'connect_logout_failed',
}

/** MSISDNEntry */
export interface CAPBAKMSISDNEntry {
    msisdn: string
    timestamp: number
}

/** MediaCounters */
export interface CAPBAKMediaCounters {
    picture_count: number
    video_count: number
    screenshot_count: number
}

/**
 * MergeStatus
 * The different permitted statuses of a merge-ticket
 *
 * The status of a ticket can be changed. However, there are rules, in order
 * to prevent invalid status changes (like COMPLETED to CANCELLED, or others).
 * These rules are summarized by the following diagram:
 *
 *         { start }
 *             |
 *         <PENDING>
 *             |
 *             |(reject?)---[REJECTED]
 *             |
 *         <APPROVED>
 *             |
 *             |(cancel?)---[CANCELLED]
 *             |
 *         <STARTED>
 *             |
 *             |(fails?)----[FAILED]
 *             |
 *             |(error?)----[COMPLETED_WITH_ERRORS]
 *             |
 *         [COMPLETED]
 *
 * (Lines going DOWN or RIGHT are legal status changes)
 * (Angle-bracket statuses can be changed; Square-brackets are final)
 */
export enum CAPBAKMergeStatus {
    PendingApproval = 'pending_approval',
    Rejected = 'rejected',
    Approved = 'approved',
    Cancelled = 'cancelled',
    Started = 'started',
    Failed = 'failed',
    CompletedWithErrors = 'completed_with_errors',
    Completed = 'completed',
}

/** MergeTicket */
export interface CAPBAKMergeTicket {
    source: string
    target: string
    emails: string[]
    description: string
    id: number
    status: CAPBAKMergeStatus
    events: CAPBAKMergeTicketEvent[]
    created_event: CAPBAKMergeTicketEvent
    resolved_event?: CAPBAKMergeTicketEvent
}

/** MergeTicketEvent */
export interface CAPBAKMergeTicketEvent {
    status: CAPBAKMergeStatus
    agent_id?: number
    timestamp: number
}

/** MergeTicketId */
export interface CAPBAKMergeTicketId {
    ticket_id: number
}

/** MergeUserDryRunStatus */
export interface CAPBAKMergeUserDryRunStatus {
    source_user: CAPBAKMergeUserInfo
    target_user: CAPBAKMergeUserInfo
    contact_emails: string[]
    have_common_device: boolean
    have_common_advertising_id: boolean
    triggered: boolean
    strategy: string
}

/** MergeUserInfo */
export interface CAPBAKMergeUserInfo {
    name: string
    email?: string
    device_ids: string[]
    advertising_ids: string[]
}

/** MergeUserStatus */
export interface CAPBAKMergeUserStatus {
    source_user: CAPBAKMergeUserInfo
    target_user: CAPBAKMergeUserInfo
    contact_emails: string[]
    have_common_device: boolean
    have_common_advertising_id: boolean
    description: string
}

/** MessageAndResultResponse */
export interface CAPBAKMessageAndResultResponse {
    message: string
    result: CAPBAKResult
}

/** MessageResponse */
export interface CAPBAKMessageResponse {
    message: string
}

/** NewCustomerServiceAgent */
export interface CAPBAKNewCustomerServiceAgent {
    username: string
    name: string
    comment: string
}

/** NewCustomerServiceRole */
export interface CAPBAKNewCustomerServiceRole {
    title: string
    permissions: CAPBAKCustomerServicePermission[]
}

/** NewMergeTicket */
export interface CAPBAKNewMergeTicket {
    source: string
    target: string
    emails: string[]
    description: string
}

/** NotificationAttrs */
export interface CAPBAKNotificationAttrs {
    processing_is_done?: boolean
}

/** NotificationModel */
export interface CAPBAKNotificationModel {
    attrs: CAPBAKNotificationAttrs
}

/** OptionResponse */
export interface CAPBAKOptionResponse {
    value?: string
}

/**
 * PartialMetadataResponse
 * The metadata returned to the client if full=True is not provided
 */
export interface CAPBAKPartialMetadataResponse {
    Make?: string
    Model?: string
    ApertureValue?: string
    FNumber?: string
    ExposureTime?: string
    FocalLength?: string
    ISOSpeedRatings?: string
    'Capture.GpsPosition'?: number[]
    'Capture.OrigHeight'?: number
    'Capture.OrigWidth'?: number
}

/** PaymentInfoResponse */
export interface CAPBAKPaymentInfoResponse {
    account_balance: number
    currency?: CAPBAKCurrency
    card: string
    email?: string
    exp_month: number
    exp_year: number
    card_id: string
    invoice_payment_method: boolean
    card_brand: CAPBAKCardBrand
}

/** PermittedServices */
export interface CAPBAKPermittedServices {
    analytics: boolean
}

/** PersonalInfo */
export interface CAPBAKPersonalInfo {
    capture: CAPBAKPersonalInfoCapture
    telenorid: CAPBAKPersonalInfoTelenorIdResponse
}

/** PersonalInfoCapture */
export interface CAPBAKPersonalInfoCapture {
    name?: string
    email?: string
    email_history?: CAPBAKEmailEntry[]
    msisdn?: string
    msisdn_history?: CAPBAKMSISDNEntry[]
}

/** PersonalInfoTelenorID */
export interface CAPBAKPersonalInfoTelenorID {
    name?: string
    email?: string
    email_secondary?: string[]
    msisdn?: string
    msisdn_secondary?: string[]
}

/** PersonalInfoTelenorIdResponse */
export interface CAPBAKPersonalInfoTelenorIdResponse {
    result?: CAPBAKPersonalInfoTelenorID
    error?: number
}

/** PictureJobResponse */
export interface CAPBAKPictureJobResponse {
    id: string
    name: string
    mtime: number
    last_update?: number
}

/** PoliceReportCancelledResponse */
export interface CAPBAKPoliceReportCancelledResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
    cancelled_at: number
}

/** PoliceReportCompletedResponse */
export interface CAPBAKPoliceReportCompletedResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
    completed_at: number
    report_info: CAPBAKPoliceReportInfo
}

/** PoliceReportInfo */
export interface CAPBAKPoliceReportInfo {
    frozen_username: string
    share_job_uuid: string
    report_size: number
    duplication_started_at: number
    duplication_finished_at: number
}

/** PoliceReportParameters */
export interface CAPBAKPoliceReportParameters {
    metadata_only: boolean
    report_recipients: string[]
    since?: number
    until?: number
}

/** PoliceReportScheduledResponse */
export interface CAPBAKPoliceReportScheduledResponse {
    status: string
    scheduled_at: number
    report_params: CAPBAKPoliceReportParameters
}

/** PostAgentResponse */
export interface CAPBAKPostAgentResponse {
    result: CAPBAKAgentId
}

/** PostCumulusEventsResponse */
export interface CAPBAKPostCumulusEventsResponse {
    data: CAPBAKCumulusEventsData
}

/** PostFlightErrorResponse */
export interface CAPBAKPostFlightErrorResponse {
    status: 'success' | 'error' | 'failed'
    reason: string
    from_telenor_id?: boolean
    error_description?: string
}

/** PostFlightFailedResponse */
export interface CAPBAKPostFlightFailedResponse {
    status: 'success' | 'error' | 'failed'
    reason: string
    undelete_url?: string
    show_url?: string
}

/** PostFlightSuccessResponse */
export interface CAPBAKPostFlightSuccessResponse {
    status: 'success' | 'error' | 'failed'
    data: CAPBAKClientLoginOrRegistrationDict
}

/** PostRoleResponse */
export interface CAPBAKPostRoleResponse {
    result: CAPBAKRoleId
}

/** PreFlightAuthParams */
export interface CAPBAKPreFlightAuthParams {
    response_type: string
    redirect_uri: string
    client_id: string
    state: string
    scope: string
    claims?: string
    ui_locales: string
    acr_values: string
    log_session_id: string
    max_age?: string
    prompt?: string
    code_challenge?: string
    code_challenge_method?: string
}

/** PreFlightOauthState */
export interface CAPBAKPreFlightOauthState {
    authorization_url: string
    authorization_params: CAPBAKPreFlightAuthParams | object
    use_staging_authorization_server: boolean
}

/** PrivacyMode */
export enum CAPBAKPrivacyMode {
    Shared = 'shared',
    Protected = 'protected',
    Private = 'private',
    Public = 'public',
}

/** PrivateShareResp */
export interface CAPBAKPrivateShareResp {
    job_uuid: string
}

/** ProductListResponse */
export interface CAPBAKProductListResponse {
    result: any[]
}

/** ProfilePictureUploadResponse */
export interface CAPBAKProfilePictureUploadResponse {
    uuid: string
    used_space: number
    timestamp: number
    width?: number
    height?: number
    result?: string
    status?: CAPBAKFileLifetimeStatus
    profile_picture_url?: string
}

/** PublishShareResponse */
export interface CAPBAKPublishShareResponse {
    url: string
}

/** PurchaseStripeSubscriptionResponse */
export interface CAPBAKPurchaseStripeSubscriptionResponse {
    status: CAPBAKStripeSubscriptionStatus
    message: string
}

/** PushStatisticsResponse */
export interface CAPBAKPushStatisticsResponse {
    last_sent_time?: number
    last_received_time?: number
}

/** PushTokenResponse */
export interface CAPBAKPushTokenResponse {
    message: string
    status: string
}

/** ReactionResponse */
export interface CAPBAKReactionResponse {
    reaction_uuid: string
    file_uuid: string
    reaction: CAPBAKReactionType
    submitted: number
}

/** ReactionType */
export enum CAPBAKReactionType {
    Love = 'love',
    Favorite = 'favorite',
}

/** ReactionsResponse */
export interface CAPBAKReactionsResponse {
    content: CAPBAKReactionResponse[]
}

/** RecipientList */
export interface CAPBAKRecipientList {
    recipients: string[]
}

/** RelatedAccountInfo */
export interface CAPBAKRelatedAccountInfo {
    username: string
    user_uuid: string
    creation_time: string
    disabled?: string
    deletion_time?: string
    email: string
    msisdn: string
}

/** RelatedAccountsResponse */
export interface CAPBAKRelatedAccountsResponse {
    accounts: CAPBAKRelatedAccountInfo[]
}

/** RemoteVar */
export interface CAPBAKRemoteVar {
    name: string
    value: string
}

/** RemoteVarData */
export interface CAPBAKRemoteVarData {
    variables: CAPBAKRemoteVar[]
}

/** RemoteVarDefinition */
export interface CAPBAKRemoteVarDefinition {
    name: string
    required_version: string
    api_key: string
    default_value: string
}

/** RemoteVarDefinitionData */
export interface CAPBAKRemoteVarDefinitionData {
    variables: CAPBAKRemoteVarDefinition[]
}

/** RestoreAlbumsResponse */
export interface CAPBAKRestoreAlbumsResponse {
    success: CAPBAKAlbumMessageResponse[]
    errors: CAPBAKAlbumMessageResponse[]
}

/** Result */
export enum CAPBAKResult {
    OK = 'OK',
}

/** Right */
export interface CAPBAKRight {
    right_id: string
    state: string
    grantor_id: string
    grantor_context: string
    time_interval: string
    sku: string
    used: number
    active: boolean
}

/** RightLinks */
export interface CAPBAKRightLinks {
    cancellation?: string
    reactivation?: string
}

/** RightPeriod */
export enum CAPBAKRightPeriod {
    Monthly = 'monthly',
    Yearly = 'yearly',
    Unknown = 'unknown',
}

/** RightSpec */
export interface CAPBAKRightSpec {
    sku: string
    grantor_context?: string
}

/** RoleId */
export interface CAPBAKRoleId {
    role_id: number
}

/** RollbackErrorReason */
export enum CAPBAKRollbackErrorReason {
    ReadOnlyUserError = 'read_only_user_error',
    QuotaExceededError = 'quota_exceeded_error',
    FileNotFoundError = 'file_not_found_error',
    ForbiddenError = 'forbidden_error',
    FileIsTrashedError = 'file_is_trashed_error',
    IsDirectoryError = 'is_directory_error',
    UnableToRollbackError = 'unable_to_rollback_error',
}

/** RollbackMultiResponse */
export interface CAPBAKRollbackMultiResponse {
    results: CAPBAKRollbackResult[]
    quota?: CAPBAKUsageAndQuotaResponse
}

/** RollbackResult */
export interface CAPBAKRollbackResult {
    file_uuid: string
    status: CAPBAKRollbackResultStatus
    error_reason?: CAPBAKRollbackErrorReason
    error_text?: string
}

/** RollbackResultStatus */
export enum CAPBAKRollbackResultStatus {
    Ok = 'ok',
    Error = 'error',
}

/** RunTimeIntervalModel */
export interface CAPBAKRunTimeIntervalModel {
    start_time: number
    end_time: number
    start_battery_level: number
    end_battery_level: number
    with_ac: boolean
    with_wifi: boolean
    with_background_boost?: boolean
}

/** RunTimeStatsModel */
export interface CAPBAKRunTimeStatsModel {
    run_time_intervals: CAPBAKRunTimeIntervalModel[]
}

/** SaveTemplateResp */
export interface CAPBAKSaveTemplateResp {
    result: 'overwritten' | 'created'
}

/** ScheduleForcedThumbnailRegenResponse */
export interface CAPBAKScheduleForcedThumbnailRegenResponse {
    result: CAPBAKThumbnailCount
}

/** ScheduleRestoreDeletedFilesResponse */
export interface CAPBAKScheduleRestoreDeletedFilesResponse {
    result: CAPBAKFileRefID
}

/** SearchStatus */
export interface CAPBAKSearchStatus {
    job_uuid: string
    serial: number
    status: 'complete' | 'not complete'
}

/** SearchUserAttrs */
export interface CAPBAKSearchUserAttrs {
    user_uuid: string
    attrs: CAPBAKNbsStorageSearchIntegrationSearchPermissions
}

/** SendCsamResp */
export interface CAPBAKSendCsamResp {
    request_status: 'OK' | 'ERROR'
    error?: string
}

/** SendEmailOtpResponse */
export interface CAPBAKSendEmailOtpResponse {
    otp: string
}

/** ServerInfo */
export interface CAPBAKServerInfo {
    current_time: number
    server_name: string
    version: CAPBAKVersion
    python_version: (number | string)[]
    commit_hash: string
}

/** ServiceInfo */
export interface CAPBAKServiceInfo {
    'app-host': string
    'thumb-host': string
    'poll-host': string
    'video-host': string
    'search-host': string
    'download-host': string
}

/** ServiceStatus */
export interface CAPBAKServiceStatus {
    status?: string
    message?: string
}

/** SessionMonthActivity */
export interface CAPBAKSessionMonthActivity {
    yyyy_mm: string
    uploads: number
    downloads: number
    zip_downs: number
    deletions: number
    rollbacks: number
    auth_id?: string
    platform: string
}

/** SetJobDataResponse */
export interface CAPBAKSetJobDataResponse {
    id: string
    name: string
    mtime: number
    size: number
}

/** SetJobRecipientResponse */
export interface CAPBAKSetJobRecipientResponse {
    user_uuids: string[]
}

/** SetReactionResponse */
export interface CAPBAKSetReactionResponse {
    message: string
    serial?: number
    uuid?: string
    result?: 'OK'
}

/** ShareExpiration */
export interface CAPBAKShareExpiration {
    expiration_date: string
}

/** ShouldSendUserStatsEventsResponse */
export interface CAPBAKShouldSendUserStatsEventsResponse {
    should_send_user_statistics_events: boolean
}

/** SmsOtp */
export interface CAPBAKSmsOtp {
    smsotp: string
}

/** SpaceInfo */
export interface CAPBAKSpaceInfo {
    used_space: number
    max_space: number
}

/** StoryInfoResponse */
export interface CAPBAKStoryInfoResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    owner: CAPBAKJobOwner
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: CAPBAKJobPermissionsExtended
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
}

/** StoryResponse */
export interface CAPBAKStoryResponse {
    id: string
    name: string
    mtime: number
    ctime?: number
    last_update: number
    size?: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    last_user_update: number
    service: CAPBAKServiceInfo
    privacy_mode: CAPBAKPrivacyMode
    permissions: number
    participant_count: number
    cover_id?: string
    password_protected: boolean
    stored_password?: string
    owner?: string
}

/** StripeClientRight */
export interface CAPBAKStripeClientRight {
    source: CAPBAKClientGrantor
    used: number
    size: number
    _links?: CAPBAKRightLinks
    expires_at?: number
    period: CAPBAKRightPeriod
    renews_at?: number
    stripe_order_id: string
    stripe_product_id: string
    status?: CAPBAKStripeSubscriptionStatus
    cancelled_at?: number
}

/** StripeDeleteCCResponse */
export interface CAPBAKStripeDeleteCCResponse {
    result: CAPBAKStripeDeleteCCResult
}

/** StripeDeleteCCResult */
export interface CAPBAKStripeDeleteCCResult {
    is_deleted: boolean
    is_the_last_card: boolean
    failed_reason?: string
}

/** StripeEnv */
export enum CAPBAKStripeEnv {
    Production = 'production',
    Test = 'test',
}

/** StripeIdInfo */
export interface CAPBAKStripeIdInfo {
    customer_id: string
    username: string
}

/** StripeProduct */
export interface CAPBAKStripeProduct {
    id: string
    period: CAPBAKRightPeriod
    size: number
    trial?: boolean
    price?: number
    currency: CAPBAKCurrency
    env: CAPBAKStripeEnv
}

/** StripeProductExcludeTrial */
export interface CAPBAKStripeProductExcludeTrial {
    id: string
    period: CAPBAKRightPeriod
    size: number
    price?: number
    currency: CAPBAKCurrency
    env: CAPBAKStripeEnv
}

/** StripeProductListResponse */
export interface CAPBAKStripeProductListResponse {
    result: CAPBAKStripeProduct[]
    trial_period_months: number
    unavailable_product_ids?: string[]
}

/** StripePurchase */
export interface CAPBAKStripePurchase {
    is_sandbox: boolean
    subscription_id: string
    plan_id: string
    is_active: boolean
}

/** StripeSetupIntentCreateResponse */
export interface CAPBAKStripeSetupIntentCreateResponse {
    client_secret: string
    setup_intent_id: string
}

/** StripeSubscriptionActionRequiredResponse */
export interface CAPBAKStripeSubscriptionActionRequiredResponse {
    status: CAPBAKStripeSubscriptionStatus
    message: string
    client_secret: string
    subscription_id: string
}

/** StripeSubscriptionStatus */
export enum CAPBAKStripeSubscriptionStatus {
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    Trialing = 'trialing',
    Active = 'active',
    PastDue = 'past_due',
    Canceled = 'canceled',
    Unpaid = 'unpaid',
    Paused = 'paused',
    SubNotFoundInStripe = 'SubNotFoundInStripe',
}

/** SubFileStatus */
export enum CAPBAKSubFileStatus {
    Queued = 'queued',
    Ready = 'ready',
    Error = 'error',
}

/** SubFileStatusResponse */
export interface CAPBAKSubFileStatusResponse {
    result: CAPBAKSubFileStatus
}

/** Subscription */
export interface CAPBAKSubscription {
    subscription_id: string
    state: string
    grantor_id: string
    grantor_context: string
    orig_time_spec: string
    effective_time_spec?: string
    right_specs: CAPBAKRightSpec[]
}

/** SubscriptionService */
export enum CAPBAKSubscriptionService {
    GooglePlayStore = 'Google Play Store',
    AppleStore = 'Apple Store',
    Stripe = 'Stripe',
}

/** SunsetData */
export interface CAPBAKSunsetData {
    hard_deletion_time?: string
    flow?: CAPBAKSunsetFlow
    STEP_1_date?: string
    STEP_2_date?: string
    STEP_3_date?: string
    STEP_4_date?: string
    STEP_5_date?: string
    STEP_6_date?: string
    user_location?: 'NOR' | 'SWE' | 'GLOBAL'
    temp_excluded?: 'album' | 'no_telenor_id'
}

/** SunsetFlow */
export enum CAPBAKSunsetFlow {
    TNSE_STANDARD = 'TNSE_STANDARD',
    TNSE_ALBUM = 'TNSE_ALBUM',
    FREEMIUM_STANDARD = 'FREEMIUM_STANDARD',
    FREEMIUM_ALBUM = 'FREEMIUM_ALBUM',
    TNN_CHURNER_STANDARD = 'TNN_CHURNER_STANDARD',
    TNN_CHURNER_ALBUM = 'TNN_CHURNER_ALBUM',
    B2CCHURNERSTANDARD = 'B2C_CHURNER_STANDARD',
    B2CCHURNERALBUM = 'B2C_CHURNER_ALBUM',
}

/** SupportedAuds */
export enum CAPBAKSupportedAuds {
    PoliceIt = 'police.it',
    KriposIt = 'kripos.it',
}

/** SupportedExtensionsResponse */
export interface CAPBAKSupportedExtensionsResponse {
    media: string[]
    documents: string[]
    video: string[]
    image: string[]
}

/** SyncUploadExistsResponse */
export interface CAPBAKSyncUploadExistsResponse {
    content: CAPBAKSyncUploadExistsResponseContent
}

/** SyncUploadExistsResponseContent */
export interface CAPBAKSyncUploadExistsResponseContent {
    uuid: string
    status: CAPBAKFileLifetimeStatus
    width?: number
    height?: number
    max_space?: number
    used_space?: number
    estimated_used_space?: number
}

/** SyncUploadResponse */
export interface CAPBAKSyncUploadResponse {
    content: CAPBAKSyncUploadResponseContent
}

/** SyncUploadResponseContent */
export interface CAPBAKSyncUploadResponseContent {
    status: CAPBAKAsyncUploadState
    bytes_received: number
    uuid: string
    max_space?: number
    used_space?: number
    estimated_used_space?: number
}

/** ThrowawayAccountModel */
export interface CAPBAKThrowawayAccountModel {
    devices?: CAPBAKDeviceModel[]
    msisdn?: string
    email?: string
}

/** ThrowawayAccountResp */
export interface CAPBAKThrowawayAccountResp {
    result?: 'created'
    default_job: string
    username: string
    auth_token: string
    service: CAPBAKServiceInfo
    devices: CAPBAKDeviceResp[]
}

/** ThumbnailCount */
export interface CAPBAKThumbnailCount {
    thumbnails_count: number
}

/** TimelineDay */
export interface CAPBAKTimelineDay {
    year: number
    month: number
    picture_count: number
    video_count: number
    screenshot_count: number
    document_count: number
    other_count: number
    day: number
    count: number
}

/** TimelineDays */
export interface CAPBAKTimelineDays {
    days: CAPBAKTimelineDay[]
}

/** TimelineMonth */
export interface CAPBAKTimelineMonth {
    year: number
    month: number
    picture_count: number
    video_count: number
    screenshot_count: number
    document_count: number
    other_count: number
    count: number
}

/** TimelineMonths */
export interface CAPBAKTimelineMonths {
    months: CAPBAKTimelineMonth[]
}

/** TrashcanAlbumItem */
export interface CAPBAKTrashcanAlbumItem {
    id: string
    name: string
    mtime: number
    ctime: number
    dtime: number
    last_update: number
    last_user_update?: number
    size: number
    type?: string
    attributes?: Record<string, string>
    message?: string | object
    privacy_mode: CAPBAKPrivacyMode
    participant_count: number
    cover_id?: string
    password_protected: boolean
    media_count: number
    comment_count: number
    reaction_count: number
    has_heic: boolean
}

/** TrashcanFileItem */
export interface CAPBAKTrashcanFileItem {
    id: string
    path: string
    dtime: number
    mtime: number
    size: number
    job: string
    restore_size: number
    group_id?: string
    group_type?: string
    master?: string
    width?: number
    height?: number
    duration?: number
}

/** UndoDeletionRequestResp */
export interface CAPBAKUndoDeletionRequestResp {
    account_reset: boolean
}

/** UpdateStripePurchaseResponse */
export interface CAPBAKUpdateStripePurchaseResponse {
    message: string
    amount_due?: number
}

/** UploadErrorLogResult */
export interface CAPBAKUploadErrorLogResult {
    result: 'upload' | 'no_upload' | 'ok'
    url?: string
}

/** UploadPolicy */
export enum CAPBAKUploadPolicy {
    AllowDuplicates = 'allow_duplicates',
    NoDuplicates = 'no_duplicates',
    OnceOnly = 'once_only',
}

/** UploadResponse */
export interface CAPBAKUploadResponse {
    uuid: string
    used_space: number
    timestamp: number
    width?: number
    height?: number
    result?: string
    status?: CAPBAKFileLifetimeStatus
}

/** UsageAndQuotaResponse */
export interface CAPBAKUsageAndQuotaResponse {
    used_space: number
    max_space: number
}

/** UserActivityStats */
export interface CAPBAKUserActivityStats {
    engaged_users: number
    foreground_users: number
}

/** UserEvent */
export interface CAPBAKUserEvent {
    operation: string
    timestamp: number
    size: number
    id: number
    file_uuid: string
    file_id: number
    ctime: number
    computer_name: string
    filename: string
}

/** UserGroupCreationResponse */
export interface CAPBAKUserGroupCreationResponse {
    result: CAPBAKUserGroupCreationResult
}

/** UserGroupCreationResult */
export enum CAPBAKUserGroupCreationResult {
    Created = 'created',
    AlreadyCreated = 'already_created',
}

/** UserGroupDefinition */
export interface CAPBAKUserGroupDefinition {
    name: string
}

/** UserGroupEnrollmentResponse */
export interface CAPBAKUserGroupEnrollmentResponse {
    result: CAPBAKUserGroupEnrollmentResult
}

/** UserGroupEnrollmentResult */
export enum CAPBAKUserGroupEnrollmentResult {
    UserAdded = 'user_added',
    AlreadyMember = 'already_member',
}

/** UserGroupMembership */
export interface CAPBAKUserGroupMembership {
    name: string
    is_member: boolean
}

/** UserGroupWithdrawalResponse */
export interface CAPBAKUserGroupWithdrawalResponse {
    result: CAPBAKUserGroupWithdrawalResult
}

/** UserGroupWithdrawalResult */
export enum CAPBAKUserGroupWithdrawalResult {
    UserRemoved = 'user_removed',
    NotMember = 'not_member',
    GroupDoesNotExist = 'group_does_not_exist',
}

/** UserGroups */
export interface CAPBAKUserGroups {
    user_groups: CAPBAKUserGroupMembership[]
}

/** UserHealth */
export enum CAPBAKUserHealth {
    GOOD = 'GOOD',
    WARNING = 'WARNING',
    NEUTRAL = 'NEUTRAL',
}

/** UserLogoutResponse */
export interface CAPBAKUserLogoutResponse {
    user_logged_out: boolean
}

/** UserResponse */
export interface CAPBAKUserResponse {
    name: string
    subscribed: boolean
    self?: boolean
    profile_picture?: string
    profile_picture_url?: string
}

/** UserStatsEventModel */
export interface CAPBAKUserStatsEventModel {
    event_data: object
    event_name: string
    created_at: string
    user_uuid?: string
    session_id?: string
    device_id?: string
}

/** UserStatsEventsModel */
export interface CAPBAKUserStatsEventsModel {
    events: CAPBAKUserStatsEventModel[]
}

/** UserWithDeviceInfo */
export interface CAPBAKUserWithDeviceInfo {
    userinfo: CAPBAKNbsStorageCustomerServiceCustomerServiceUserInfo
    devices: CAPBAKDeviceInfo[]
}

/** UsersResponse */
export interface CAPBAKUsersResponse {
    last_user_update: number
    users: Record<string, CAPBAKUserResponse>
}

/** ValidationError */
export interface CAPBAKValidationError {
    loc: (string | number)[]
    msg: string
    type: string
}

/** Version */
export interface CAPBAKVersion {
    'capture-backend': string
}

/** VideoSub */
export enum CAPBAKVideoSub {
    VHigh = 'v-high',
    VLow = 'v-low',
    VCheck = 'v-check',
}

/** WebAction */
export interface CAPBAKWebAction {
    url: string
    action_type: CAPBAKWebActionType
}

/** WebActionType */
export enum CAPBAKWebActionType {
    Redirect = 'redirect',
    NewTab = 'new_tab',
    InternalNavigation = 'internal_navigation',
}

/** StripeSubscription */
export interface CAPBAKNbsStorageCustomerServiceStripeSubscription {
    service?: CAPBAKSubscriptionService
    username: string
    subscription_id: string
    right_id: string
    product_id: string
    created_time: string
    renewal_time: string
    cancelled_time?: string
    is_active: boolean
    is_sandbox: boolean
}

/** SearchPermissions */
export interface CAPBAKNbsStorageCustomerServiceCustomerServiceSearchPermissions {
    block_location_timestamp?: number
    block_face_timestamp?: number
    location_allowed: boolean
    face_allowed: boolean
}

/** UserInfo */
export interface CAPBAKNbsStorageCustomerServiceCustomerServiceUserInfo {
    uploadcount_android?: number
    uploadcount_ios?: number
    uploadcount_web?: number
    uploadcount_win?: number
    uploadcount_mac?: number
    uploadcount_linux?: number
    uploadcount_unknown?: number
    downloadcount_android?: number
    downloadcount_ios?: number
    downloadcount_web?: number
    downloadcount_win?: number
    downloadcount_mac?: number
    downloadcount_linux?: number
    downloadcount_unknown?: number
    deletecount_android?: number
    deletecount_ios?: number
    deletecount_web?: number
    deletecount_win?: number
    deletecount_mac?: number
    deletecount_linux?: number
    deletecount_unknown?: number
    rollbackcount?: number
    trashcan_visitcount?: number
    last_upload_android?: number | boolean
    last_upload_ios?: number | boolean
    last_upload_web?: number | boolean
    last_upload_win?: number | boolean
    last_upload_mac?: number | boolean
    last_upload_linux?: number | boolean
    last_upload_unknown?: number | boolean
    last_download_android?: number | boolean
    last_download_ios?: number | boolean
    last_download_web?: number | boolean
    last_download_win?: number | boolean
    last_download_mac?: number | boolean
    last_download_linux?: number | boolean
    last_download_unknown?: number | boolean
    last_delete_android?: number | boolean
    last_delete_ios?: number | boolean
    last_delete_web?: number | boolean
    last_delete_win?: number | boolean
    last_delete_mac?: number | boolean
    last_delete_linux?: number | boolean
    last_delete_unknown?: number | boolean
    last_rollback?: number | boolean
    last_trashcan_visit?: number | boolean
    usedspace: number
    username: string
    maxspace: number
    datacenter: string
    shard: number
    is_read_only_user: boolean
    is_beta_user: boolean
    subscription?: CAPBAKSubscription[]
    rights?: CAPBAKRight[]
    consents?: CAPBAKConsent[]
    creationtime: string
    first_upload?: string
    sunset_data?: CAPBAKSunsetData
    deletion_history: any[][]
    media_count_by_month: any[][]
    disabled?: string
    apple_purchases?: CAPBAKApplePurchase[]
    android_purchases?: CAPBAKAndroidPurchase[]
    stripe_purchases?: CAPBAKStripePurchase[]
    search_status?: CAPBAKSearchStatus[]
    search_permissions?: CAPBAKNbsStorageCustomerServiceCustomerServiceSearchPermissions
    albums_count: number
    shares_count: number
    subscribed_albums_count: number
}

/** UserInfo */
export interface CAPBAKNbsStorageMittTelenorUserInfo {
    user_health: CAPBAKUserHealth
    user_has_unlimited_quota: boolean
    background: string
    user_health_text: string
    button_text: string
    button_action: string
    connect_id?: string
    used_space_gb?: string
    quota_gb?: number
    out_of_quota?: boolean
    last_connection_time?: string
    last_upload_date?: string
    last_upload_file_name?: string
}

/** SearchPermissions */
export interface CAPBAKNbsStorageSearchIntegrationSearchPermissions {
    'search_backend.should_process_locations': boolean
    'search_backend.should_process_faces': boolean
    'search_backend.should_classify_images': boolean
    'search_backend.should_group_faces': boolean
}

/** StripeSubscription */
export interface CAPBAKNbsStorageStoreStripeSubscription {
    subscription: CAPBAKStripeProductExcludeTrial
    start_at?: string
    end_at?: string
}

export interface CAPBAKLocalePostParams {
    locale: string
}

/** Model */
export type CAPBAKThrowawayUserPostPayload = CAPBAKThrowawayAccountModel

export interface CAPBAKThrowawayUserPostParams {
    key: CAPBAKAPIKey
    template?: string
    validity?: number
    is_read_only_user?: boolean
    'test-auth'?: string
    'super-auth'?: string
}

export interface CAPBAKThrowawayUserTemplatePostByJobUuidParams {
    template_name: string
    overwrite?: boolean
    'test-auth'?: string
    'super-auth'?: string
    jobUuid: string
}

export interface CAPBAKThrowawayUserTemplateDeleteParams {
    template_name: string
    'test-auth'?: string
    'super-auth'?: string
}

export interface CAPBAKSearchRolloutPostByUsernameParams {
    'test-auth'?: string
    'super-auth'?: string
    username: string
}

/**
 * Permissions
 * @uniqueItems true
 */
export type CAPBAKCustomerServiceToolAuthPostPayload =
    CAPBAKCustomerServicePermission[]

/** Message */
export type CAPBAKSendPushNotificationPostPayload = Record<string, string>

export interface CAPBAKSendPushNotificationPostParams {
    push_type?: CAPBAKClientFacingPush
    app_lang?: string
    abc_test_group_id?: string
}

/** Message */
export type CAPBAKSendCollectionPushNotificationPostPayload = Record<
    string,
    string
>

export interface CAPBAKSendCollectionPushNotificationPostParams {
    collection_type?: CAPBAKCollectionType
    app_lang?: string
}

export interface CAPBAKEraseStoreSubDeleteParams {
    store_type: 'APPLE' | 'STRIPE' | 'ANDROID'
}

export interface CAPBAKAddGrantPostParams {
    grant_size: 1 | 10 | 100 | 1000 | 5000
    grantor_id: string
}

export interface CAPBAKLoginIdentifierGetParams {
    auth: string
}

export interface CAPBAKCreateDummySignedJwtGetParams {
    aud: CAPBAKSupportedAuds
}

export interface CAPBAKDeleteStripeDataPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFullScreenCardPostParams {
    scenario: string
    card_type?: CAPBAKFullScreenTemplate
}

export interface CAPBAKSunsetComsPostParams {
    sunset_flow: CAPBAKSunsetFlow
    loc_lang?: 'NOR' | 'SWE' | 'GLOBAL'
}

export interface CAPBAKSunsetDeletePostParams {
    sunset_flow: CAPBAKSunsetFlow
}

export interface CAPBAKDeleteUserWithoutTelenorIdComsPostParams {
    delete_user_email_lang?: 'no' | 'bg' | 'en' | 'se'
}

export interface CAPBAKResetTempFakeStipeCountryPostParams {
    stripe_country: 'NOK' | 'SEK' | 'DKK' | 'BGN'
}

export interface CAPBAKDigitalLegacyComsPostParams {
    name: string
    phone_number: string
    address: string
}

export interface CAPBAKDelaySunsetUserHardDeletionUsernamePostByUsernameParams {
    delay_days: number
    username: string
}

export interface CAPBAKUsernameFilesInfoDetailParams {
    count?: number
    start_id?: number
    active_files?: boolean
    deleted?: boolean
    unrefed?: boolean
    display_name?: string
    upload_time_min?: number
    upload_time_max?: number
    creation_time_min?: number
    creation_time_max?: number
    deletion_time_min?: number
    deletion_time_max?: number
    username: string
}

export interface CAPBAKStatisticsUserActivityGetParams {
    cutoff: '1' | '2' | '7' | '30'
}

export interface CAPBAKUsernameFileRecoveriesPostParams {
    starting_time: number
    ending_time: number
    username: string
}

export interface CAPBAKScheduleForcedThumbnailRegenerationPostByUsernameParams {
    image_type: string
    starting_time: number
    ending_time: number
    username: string
}

export interface CAPBAKEmailOtpPostByUsernameAndEmailParams {
    language: 'en' | 'no'
    username: string
    email: string
}

export interface CAPBAKDisablePostByUsernameParams {
    reason: string
    username: string
}

export interface CAPBAKUsernameLastEventsDetailParams {
    count?: number
    username: string
}

export interface CAPBAKUsernameEventsDetailParams {
    count?: number
    start_id?: number
    username: string
}

export interface CAPBAKCaptureRightPostByUsernameParams {
    duration: 1 | 3 | 6 | 12
    quota_size: 25 | 50 | 100 | 5000
    username: string
}

export interface CAPBAKDelayCsamUserNotificationsAndDeletionOfShadowAccountPostByUsernameParams {
    delay_in_seconds: number
    username: string
}

/**
 * Receipt
 * Base64 encoded apple receipt
 */
export type CAPBAKAppStoreSubscriptionsGetPayload = string

export interface CAPBAKCaptureUpdateDeleteTimeForAccountPostByUsernameParams {
    delete_time: string
    username: string
}

export interface CAPBAKMergeIntoPostBySrcUsernameAndTgtUsernameParams {
    contact_email: string
    srcUsername: string
    tgtUsername: string
}

export interface CAPBAKMergeIntoDetailBySrcUsernameAndTgtUsernameParams {
    contact_email: string
    srcUsername: string
    tgtUsername: string
}

export interface CAPBAKUsernameRelatedAccountsInfoDetailByUsernameParams {
    email?: string
    msisdn?: string
    username: string
}

export interface CAPBAKMergeTicketPostByTicketIdParams {
    action: 'approve' | 'cancel'
    ticketId: number
}

export interface CAPBAKGrantPostParams {
    agent_id: number
    role_id: number
}

export interface CAPBAKGrantDeleteParams {
    agent_id: number
    role_id: number
}

export interface CAPBAKTelenoridLogoutPostParams {
    auth: string
    key: string
}

export interface CAPBAKUsernameUnicodeGpdrMetadataGetParams {
    username: string
    unicode: string
}

export interface CAPBAKMsisdnToIdentifierGetParams {
    msisdn: string
}

export interface CAPBAKEmailToIdentifierGetParams {
    email: string
}

export interface CAPBAKServerInfoGetParams {
    key: string
}

export interface CAPBAKServiceStatusGetParams {
    key: string
    app_lang: string
}

export interface CAPBAKShouldUploadErrorLogsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKShouldUploadErrorLogsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKErrorLogPostParams {
    device_id: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCardsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDismissPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    cardId: string
}

export interface CAPBAKFullScreenCardsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFullScreenCardsGetParams2 {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKSetTosReviewedPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKJobsPostParams {
    name?: string
    password?: string
    message?: string
    ctime?: number
    type?: string
    public?: boolean
    allow_comments?: boolean
    allow_uploads?: boolean
    allow_reactions?: boolean
    allow_sharing?: boolean
    allow_anonymous_access?: boolean
    allow_discovery?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKJobsGetParams {
    public?: boolean
    stories?: boolean
    account_jobs?: boolean
    include_details?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKJobsLightGetParams {
    public?: boolean
    account_jobs?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKInfoDetailParams {
    include_details?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKUsersDetailParams {
    include_profile_url?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKTimelineDaysDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKTimelineMonthsDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKOptionsJobsParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAttributeUpdateByJobUuidAndAttributeParams {
    value: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    attribute: string
}

export interface CAPBAKMetadataDetailByJobUuidAndFileUuidParams {
    full?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesPostPayload {
    file?: File
}

export interface CAPBAKFilesPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    want_json?: boolean
    want_dimensions?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesDetailParams {
    path?: string
    start?: string
    end?: string
    file_type_filters?: string
    uploaded_since?: number
    reaction?: CAPBAKReactionType
    dirs_only?: boolean
    recursive?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKUploadPostPayload {
    file?: File
}

export interface CAPBAKUploadPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesByIdDetailByJobUuidAndFileUuidParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    allow_file_from_trash?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesByIdDeleteByJobUuidAndFileUuidParams {
    recursive?: boolean
    delete_group?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesDedupDetailParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    want_json?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesDedupPostParams {
    path: string
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    checksum?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    want_json?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKAsyncUploadDetailByJobUuidAndChecksumParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum: string
}

export interface CAPBAKAsyncUploadPostByJobUuidAndChecksumPayload {
    file?: File
}

export interface CAPBAKAsyncUploadPostByJobUuidAndChecksumParams {
    path: string
    offset?: number
    policy?: CAPBAKUploadPolicy
    group_type?: CAPBAKFileGroupType
    revision_of?: string
    original_of?: string
    group_id?: string
    mtime?: number
    ctime?: number
    submitter?: string
    dir?: boolean
    auto?: boolean
    master?: boolean
    should_not_transcode?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum?: string
}

export interface CAPBAKChangesAndAsyncUploadStatusDetailParams {
    since?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndNameParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    name: string
}

export interface CAPBAKFilesByIdDetailByJobUuidAndFileUuidAndPathParams {
    sub?: CAPBAKVideoSub
    to_jpeg?: boolean
    'no-wait'?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    path: string
}

export interface CAPBAKFilesAsArchiveDetailParams {
    zip_filename?: string
    heic_to_jpeg?: boolean
    master_only?: boolean
    include_subrevisions?: boolean
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** File Uuids */
export type CAPBAKFilesAsArchivePostPayload = string[]

export interface CAPBAKFilesAsArchivePostParams {
    zip_filename?: string
    heic_to_jpeg?: boolean
    master_only?: boolean
    include_subrevisions?: boolean
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesFromTrashAsArchiveDetailParams {
    zip_filename?: string
    forced_zip64?: boolean
    flattened?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** File Uuids */
export type CAPBAKFilesFromTrashAsArchivePostPayload = string[]

export interface CAPBAKFilesFromTrashAsArchivePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKPublishPostParams {
    to_email: string
    subject?: string
    message?: string
    to_self?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKJobsUpdateByJobUuidParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKJobsDeleteByJobUuidParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKChangesDetailParams {
    since?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKDefaultGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFeatureFlagsGetParams {
    client_v: string
    key: CAPBAKAPIKey
    device_id: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKTrashCanGetParams {
    offset?: number
    limit?: number
    total_item_count?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKTrashCanAlbumsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKEmptyTrashCanGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKEmptyTrashCanPostParams {
    fileid: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDeleteFilesFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKDeleteAlbumFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKDeleteAlbumsFromTrashCanDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPreflightOauth2LoginGetParams {
    device_name: string
    locale: string
    utm_source?: string
    client_session_id?: string
    code_challenge?: string
    state?: string
    advertising_id?: string
    oauth_redirect_uri?: string
    use_telenor_id_pluss?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPostflightOauth2CallbackGetParams {
    state: string
    code: string
    error?: string
    error_description?: string
}

export interface CAPBAKLoginTypePostParams {
    key: string
    platform?: string
    platform_v?: string
    client_v?: string
    device_type?: string
    locale?: string
}

export interface CAPBAKVerifyAuthGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKLogoutPostParams {
    redir?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKLogoutGetParams {
    redir?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAccountInfoGetParams {
    want_name?: boolean
    want_push_token?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKNamePostParams {
    name: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDevicesGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKUserGrantsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDevicesDeleteByDeviceIdParams {
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    deviceId: string
}

export interface CAPBAKDeletePostParams {
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    deviceId: string
}

export interface CAPBAKRegistryGetParams {
    'super-auth': string
    api_key?: string
}

export interface CAPBAKRegistryUpdateParams {
    name: string
    default_value: string
    'super-auth': string
    client_version?: string
    api_key?: string
}

export interface CAPBAKRegistryDeleteParams {
    name: string
    'super-auth': string
    api_key?: string
}

export interface CAPBAKRemoteVarsGetParams {
    api_key: string
    client_v: string
}

export interface CAPBAKRemoteVarsUpdateParams {
    api_key: string
    client_v: string
    name: string
    default_value: string
}

export interface CAPBAKRemoteVarsDeleteParams {
    api_key: string
    client_v: string
    name: string
}

export interface CAPBAKDeletedFilesInfoDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesPropertiesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesPropertiesDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFileExistsByChecksumDetailByJobUuidAndChecksumParams {
    active_files?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    checksum: string
}

export interface CAPBAKFilesExistsByChecksumDetailParams {
    active_files?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** New Consents */
export type CAPBAKConsentValuesPostPayload = Record<string, boolean>

export interface CAPBAKConsentValuesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKConsentValuesGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRequestDataAccessGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRequestDataAccessAvailabilityGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRequestAccountDeletionPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFilesByIdCommentsPostParams {
    comment: string
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesByIdCommentsUpdateByJobUuidAndFileUuidAndCommentUuidParams {
    comment: string
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    commentUuid: string
}

export interface CAPBAKFilesByIdCommentsDeleteByJobUuidAndFileUuidAndCommentUuidParams {
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
    commentUuid: string
}

export interface CAPBAKFilesByIdReactionPostParams {
    reaction: CAPBAKReactionType
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKFilesByIdReactionDeleteParams {
    mtime?: number
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKReactionsDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKNoUserInfoDetailByTelenorIdParams {
    locale?: string
    telenorId: string
}

export interface CAPBAKPrivacyModePostParams {
    mode: CAPBAKPrivacyMode
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKPrivacyModeDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKPermissionsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    allow_comments?: 0 | 1
    allow_uploads?: 0 | 1
    allow_reactions?: 0 | 1
    allow_sharing?: 0 | 1
    allow_anonymous_access?: 0 | 1
    allow_discovery?: 0 | 1
    jobUuid: string
}

export interface CAPBAKPermissionsDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKUpdatePostParams {
    push_token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKUpdatePushTokenPostParams {
    push_token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPushNotificationFeedbackPostParams {
    push_type: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFeedbackPostParams {
    push_type: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKSubscribePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKUnsubscribePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKAppleStoreReceiptPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAndroidValidatePurchasePostParams {
    signature: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAppleStoreProductsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAndroidStoreProductsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKStripeProductsGetParams {
    test?: boolean
    production?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKLogoutBackchannelPostParams {
    logout_token?: string
}

export interface CAPBAKProfileAlbumGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKValidatePurchaseDetailParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export interface CAPBAKStripePaymentMethodPostParams {
    token: string
    card: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKStripePaymentMethodGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCancelPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export interface CAPBAKReactivatePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    subscriptionId: string
}

export interface CAPBAKUpdateStripePurchasePostParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKV1PurchaseUpdateParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKV1PurchasePostParams {
    plan: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKStripePurchasePostParams {
    plan: string
    card: string
    token: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCreateSetupIntentPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKFinishSetupIntentPostParams {
    setup_intent_id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCustomerExistsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCoverAlbumGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPicturePostPayload {
    file?: File
}

export interface CAPBAKPicturePostParams {
    path: string
    checksum?: string
    mtime?: number
    ctime?: number
    auto?: boolean
    want_json?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPictureGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKPictureDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRollbackJobPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKRollbackAlbumsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKMultiRollbackPostParams {
    rollback_group?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKRollbackPostParams {
    id: string
    rollback_group?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKFilesByIdDeletePostParams {
    recursive?: boolean
    delete_group?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    fileUuid: string
}

export interface CAPBAKOptionsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKOptionsDetailByNameParams {
    want_json?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

export interface CAPBAKOptionsDeleteByNameParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

export interface CAPBAKOptionsUpdateByNameParams {
    value?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    name: string
}

export interface CAPBAKClientEventPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKMemoryDeletionDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export type CAPBAKAccountAttributePostPayload = string | object

export interface CAPBAKAccountAttributePostParams {
    account_attribute_key: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAccountAttributeGetParams {
    account_attribute_key?: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKAccountAttributeDeleteParams {
    account_attribute_key?: string
    delete_all_account_attributes?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKStatsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDeletePostParams2 {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKStripeUserCreditCardDeleteParams {
    card_id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKKeepAllFilesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export type CAPBAKKeepFilesPostPayload = string

export interface CAPBAKKeepFilesPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKApproveTosPostParams {
    tos_version: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRecipientsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKExpirationDatePostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKPasswordPostParams {
    password: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Data */
export type CAPBAKClientStatsPostPayload = object

export interface CAPBAKClientStatsPostParams {
    device_id: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKCoverPostParams {
    id: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKCoverDeleteParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKNamePostParams2 {
    name: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKStorePasswordPostParams {
    password: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKMetatagsDetailByJobUuidParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKVerifyDetailByJobUuidParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

export interface CAPBAKVerifyUserAccessGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKClassificationDetailParams {
    category: CAPBAKClassificationCategory
    threshold: number
    jobUuid: string
}

export interface CAPBAKUserStatisticsEventsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKDebugEventsPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKRunTimeStatisticsPostParams {
    device_id: string
    key: CAPBAKAPIKey
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKShouldSendUserStatisticsEventsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKProfilePictureDetailParams {
    area?: number
    userUuid: string
}

export interface CAPBAKGet3Params {
    share: string
    area?: number
    width?: number
    height?: number
    original_size?: boolean
    fileUuid: string
}

export interface CAPBAKTrashDetailParams {
    area?: number
    original_size?: boolean
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    fileUuid: string
    jobUuid: string
}

export interface CAPBAKFilesByUuidPostParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
}

/** Contacts */
export type CAPBAKDigitalLegacyPostPayload = CAPBAKDigitalLegacyContact[]

export interface CAPBAKDigitalLegacyPostParams {
    digital_legacy_choice: CAPBAKDigitalLegacyChoice
    language: CAPBAKLanguage
}

export interface CAPBAKDigitalLegacyGetParams {
    user_uuid: string
}

export interface CAPBAKSubscriptionsGetParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}

export interface CAPBAKInstantsDetailByJobUuidAndDateRangeParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    jobUuid: string
    dateRange: string
}

export interface CAPBAKMemoryCollectionsDetailByCollectionUuidParams1 {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    collectionUuid: string
}

export interface CAPBAKMemoryCollectionsDeleteByCollectionUuidParams {
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
    collectionUuid: string
}

export interface CAPBAKCreateCustomerPostParams {
    name: string
    email: string
    key: CAPBAKAPIKey
    device_id: string
    client_v: string
    app_lang?: 'no' | 'se' | 'en'
}
