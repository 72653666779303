import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface DownloadAllPromptProps {
    isInsideApp?: boolean
    handleConfirm: () => void
}

export const DownloadAllPrompt = ({
    isInsideApp,
    handleConfirm,
}: DownloadAllPromptProps) => {
    return (
        <Prompt.Content
            data-cy="download_all_prompt"
            title={_('photos_trash__download_all_title')}>
            <Typography.Paragraph>
                {isInsideApp
                    ? _('photos_trash__download_all_body_app')
                    : _('photos_trash__download_all_body')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton
                    data-cy="download_all_prompt__cancelBtn"
                    variant="secondary">
                    {_('cancel')}
                </Prompt.ActionButton>
                <Prompt.ActionButton
                    data-cy="download_all_prompt__confirmBtn"
                    variant="primary"
                    onPress={handleConfirm}>
                    {_('download')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
