import { Prompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface RestoreAllPromptProps {
    handleConfirm: () => void
}

export const RestoreAllPrompt = ({ handleConfirm }: RestoreAllPromptProps) => {
    return (
        <Prompt.Content
            data-cy="restore_all_prompt"
            title={_('photos_trash__restore_all_title')}>
            <Typography.Paragraph>
                {_('photos_trash__restore_all_body')}
            </Typography.Paragraph>
            <Prompt.ButtonsSection>
                <Prompt.ActionButton
                    data-cy="restore_all_prompt__cancelBtn"
                    variant="secondary">
                    {_('cancel')}
                </Prompt.ActionButton>
                <Prompt.ActionButton
                    data-cy="restore_all_prompt__confirmBtn"
                    variant="primary"
                    onPress={handleConfirm}>
                    {_('restore')}
                </Prompt.ActionButton>
            </Prompt.ButtonsSection>
        </Prompt.Content>
    )
}
