import { createSelector } from '@reduxjs/toolkit'
import type { UserAccountAttributeKey } from '~/@types/backend-types'
import { inArray } from '~/utilities/arrayUtils'
import type { StateWithJobInfo } from '../jobInfo/reducer'
import {
    getCreatorOfJob,
    getJobInformation,
    getProvidedPassword,
} from '../jobInfo/selectors'
import type { User } from '../users/reducer'
import {
    accountAttributeAdapter,
    type StateWithAccountAttributes,
} from './accountAttributesSlice'
import type { MissingNameModalContext, RequireUserInfoReason } from './actions'
import { LoginProgressStep, type StateWithCurrentUser } from './reducer'

const getCurrentUserAccountInfo = (state: StateWithCurrentUser) =>
    state.currentUser.accountInfo

export const getUserLoginStatus = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus

export const isLoggedIn = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus === LoginProgressStep.AUTHENTICATED
export const isLoggingIn = (state: StateWithCurrentUser) =>
    state.currentUser.loginStatus === LoginProgressStep.FETCHING

export const getUsedStorage = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => {
        if (accountInfo) {
            return accountInfo.used_space
        }
        return 0
    },
)

export const getMaxStorage = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => {
        if (accountInfo) {
            // For users with unlimited storage, the available storage is set to 1PB by the backend
            const UNLIMITED_STORAGE_QUOTA_GB = 1000000
            const hasUnlimited =
                accountInfo.max_space >=
                Math.pow(1024, 3) * UNLIMITED_STORAGE_QUOTA_GB
            return hasUnlimited ? Infinity : accountInfo.max_space
        }
        return 0
    },
)

export const hasCurrentUserUnlimitedStorage = createSelector(
    getMaxStorage,
    (max) => max === Infinity,
)

export const hasUserNotMediaFiles = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.has_files_not_media,
)

export const getUserHasGrants = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => Boolean(accountInfo?.max_space),
)

export const getUnusedStorage = createSelector(
    getMaxStorage,
    getUsedStorage,
    (max, used) => max - used,
)

export const isOutOfStorage = createSelector(
    getCurrentUserAccountInfo,
    getMaxStorage,
    getUsedStorage,
    (accountInfo, max, used) => accountInfo !== undefined && used >= max,
)

export const getUsedStorageRatio = createSelector(
    getUsedStorage,
    getMaxStorage,
    (used, max) => used / max,
)

export const isTNNCustomer = (
    state: StateWithCurrentUser,
): boolean | undefined => {
    const maxStorage = getMaxStorage(state)
    if (maxStorage > 0) {
        return maxStorage === Infinity
    }
}
export const getConnectedDevices = (state: StateWithCurrentUser) =>
    state.currentUser.connectedDevices

export const getCurrentUserUUID = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.uuid,
)

export const getEmailAdressOfLoggedInUser = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.logged_in_as,
)

export const getNameOfLoggedInUser = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.name,
)

export const mustProvideUserInfo = (state: StateWithCurrentUser): boolean => {
    return state.currentUser.userInfoRequired.isRequired && !isLoggedIn(state)
}

export const getProvideUserInfoReason = (
    state: StateWithCurrentUser,
): RequireUserInfoReason | undefined => {
    return state.currentUser.userInfoRequired.reason
}

export const getTOSVerificationInfo = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.tos_to_approve,
)

export const isJobCreatedByCurrentUser = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): boolean | undefined => {
    const info = getJobInformation(state, job)
    if (info && info.type === 'timeline') {
        return true
    }
    const creator = getCreatorOfJob(state, job)
    if (creator !== undefined) {
        return creator === getCurrentUserUUID(state)
    }
}

export const getAuthTokenForJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): string => {
    // If the job required password and the user have provided one: Use that as auth token
    const providedPassword = getProvidedPassword(state, job)
    if (providedPassword !== undefined) {
        return providedPassword
    }

    // Authenticate as logged in user unless we know the job is created by another user
    const currentAuthToken = getCurrentAuthToken(state)
    if (isJobCreatedByCurrentUser(state, job) !== false && currentAuthToken) {
        return currentAuthToken
    }

    return ''
}

export const getCurrentAuthToken = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.auth_token,
)

// ForeignAuth is used when the current user is not the owner of the job
// and is required to perform write-operations on the job
export const getForeignAuthForJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): string | undefined => {
    if (isJobCreatedByCurrentUser(state, job) === false) {
        return getCurrentAuthToken(state)
    }
}

export const getSubscribedJobs = (state: StateWithCurrentUser): JobID[] =>
    state.currentUser.subscribedJobs

export const isAlbumListReady = (state: StateWithCurrentUser): boolean =>
    state.currentUser.haveFetchedAlbumList

export const isCurrentUserSubscribedToJob = (
    state: StateWithJobInfo & StateWithCurrentUser,
    job: JobID,
): boolean => {
    const isOwnJob = isJobCreatedByCurrentUser(state, job)
    const subscribedJobs = getSubscribedJobs(state)

    return isOwnJob || inArray(subscribedJobs, job)
}

export type CurrentUserInfo = User & { isUploadingProfilePhoto: boolean }
export const getCurrentUserInfo = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => {
        if (accountInfo) {
            return {
                userID: accountInfo.uuid,
                name: accountInfo.name,
                profilePicture: accountInfo.profile_picture,
                isUploadingProfilePhoto:
                    accountInfo.isUploadingProfilePhoto === true,
            }
        }
    },
)

export type FileTypeCount = {
    picture_count: number
    video_count: number
    document_count?: number
}
export const getFileCount = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => {
        if (accountInfo) {
            const total_picture_count =
                accountInfo.file_type_counters.picture_count +
                accountInfo.file_type_counters.screenshot_count
            return {
                picture_count: total_picture_count,
                video_count: accountInfo.file_type_counters.video_count,
                document_count: accountInfo.file_type_counters.document_count,
            }
        }
    },
)

export const isCurrentUserBeingShutDown = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => {
        return accountInfo !== undefined && accountInfo.isBeingShutDown === true
    },
)

export type VersionNote = {
    header: string
    notes: string[]
}

export const getMissingNameModalVisibility = (
    state: StateWithCurrentUser,
): boolean => {
    return state.currentUser.missingNameModal.isVisible
}
export const getMissingNameModalContext = (
    state: StateWithCurrentUser,
): MissingNameModalContext => {
    return state.currentUser.missingNameModal.context
}

export const getIsReadOnlyUser = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.is_read_only_user ?? false,
)

const accountAttributeSelectors = accountAttributeAdapter.getSelectors()
export const getAccountAttributes = (state: StateWithAccountAttributes) =>
    accountAttributeSelectors.selectAll(state.accountAttributes.attributes)
export const getAccountAttribute = (
    state: StateWithAccountAttributes,
    id: UserAccountAttributeKey,
) =>
    accountAttributeSelectors.selectById(state.accountAttributes.attributes, id)
export const getAccountAttributesStatus = (state: StateWithAccountAttributes) =>
    state.accountAttributes.status

export const getSunsetDeletionDate = createSelector(
    getCurrentUserAccountInfo,
    (accountInfo) => accountInfo?.sunset_delete_date,
)

export const getIsUserFromInsideApp = (state: StateWithCurrentUser) =>
    state.currentUser.isUserFromInsideApp
